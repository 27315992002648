import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useLocalStorage } from "../../../hooks"
import { InputText } from "./components/InputText"
import { InputPhoneNumber } from "./components/InputPhoneNumber"
import { InputCrmInfos } from "./components/InputCrmInfos"

import LogoSara from "../../../assets/images/logo-sara.svg"
import SaraLogoWhite from "../../../assets/images/sara-logo-white.svg"
import IAlert from "../../../assets/icons/i-alert.svg"


interface IRegisterFormProps {
    name: string;
    setName: (value: string) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    ufCrm: string;
    setUfCrm: (value: string) => void;
    crm: string;
    setCrm: (value: string) => void;
    termsAccepted: boolean;
    setTermsAccepted: (value: boolean) => void;
    representativeId: string;
}

export const RegisterForm: React.FC<IRegisterFormProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate()
    const { setItem, getData, removeData } = useLocalStorage()

    useEffect(() => {
        if (!props.phoneNumber || props.phoneNumber.length < 11) {
            setUserExists(false)
            return
        }
        try {/* todo: check se existe user setUserExists(true) */ } catch { setUserExists(false) }
    }, [props.phoneNumber])

    useEffect(() => {
        setCrmNotRelation(false)
        setCrmHasRegister(false)
    }, [props.ufCrm, props.crm])

    const [crmNotRelation, setCrmNotRelation] = useState(false)
    const [crmHasRegister, setCrmHasRegister] = useState(false)
    const [userExists, setUserExists] = useState(false)
    const [validatedForm, setValidatedForm] = useState(false)

    const handleTerms = ()=>{
        const data = {
            name: props.name,
            phoneNumber: props.phoneNumber,
            crm: props.crm, 
            ufCrm: props.ufCrm,
            email: props.email,
            representativeId: props.representativeId
        }
        setItem('formRegister', data)
        navigate('/termos')
    }

    const handleRegister = () => {
        //envio de evento ao google
        setValidatedForm(true)
        //lógica de check da existencia do user
        if (!!props.crm && props.ufCrm.length > 0){
            //lógica de check relationship
        }
        if (
            props.phoneNumber.length === 11 &&
            props.name.length > 0 &&
            props.termsAccepted &&
            !!props.crm &&
            props.ufCrm.length > 0 &&
            !userExists  &&
            !crmHasRegister &&
            !crmNotRelation
          ) {
            try{
                const params = new URLSearchParams({
                    type: 'signup-doctor',
                    name: props.name,
                    phone: props.phoneNumber,
                    crm: props.crm,
                    ufCrm: props.ufCrm,
                    email: props.email,
                    representativeId: props.representativeId
                })
                navigate(`/token-medico?${params.toString()}`)
            }catch{}
        }
    }

    return (
        <div className="w-full flex items-center justify-center flex-col mt-4">
            <img src={LogoSara} alt="logo sara" className="mb-8 hidden w-[365.76px] min-[1025px]:block" />
            <img src={SaraLogoWhite} alt="logo sara" className="mb-[24px] w-[177.32px] min-[1025px]:hidden" />
            <div className="w-full px-[16px] justify-center items-center flex">
                <div className="py-[36px] px-[16px] bg-white rounded-3xl flex-col justify-center items-center inline-flex max-w-[390px] mb-8">
                    <div className="self-stretch gap-2 mb-[24px]">
                        <p className="grow shrink basis-0 text-center text-[#272727] text-2xl font-bold font-['Nunito'] leading-loose mb-[24px]">
                            Cadastro
                        </p>
                        <p className="text-left text-[#272727] text-lg font-medium font-['Nunito']">
                            Preencha abaixo para realizar o cadastro do médico participante:
                        </p>
                    </div>

                    <div className="w-full">
                        <InputText title="Nome" placeholder="Insira seu nome" validatedForm={validatedForm} value={props.name} setValue={props.setName} type="nome" hasValidation={true}/>
                        <InputPhoneNumber phoneNumber={props.phoneNumber} setPhoneNumber={props.setPhoneNumber} userExists={userExists} validatedForm={validatedForm}/>
                        <InputText title="Email" placeholder="Insira seu email" validatedForm={validatedForm} value={props.email} setValue={props.setEmail} type="email" hasValidation={true}/>
                        <InputCrmInfos crm={props.crm} setCrm={props.setCrm} ufCrm={props.ufCrm} setUfCrm={props.setUfCrm} validatedForm={validatedForm} crmNotRelation={crmNotRelation} crmHasRegister={crmHasRegister}/>
                    </div>

                    <div className="justify-start items-start gap-2 inline-flex mb-[24px]">
                        <div className="w-6 h-6 relative">
                            <input
                                type="checkbox"
                                className={`w-6 h-6 left-0 top-0 absolute bg-zinc-300 ${validatedForm && !props.termsAccepted ? 'invalid' : ''}`}
                                onChange={(e) => props.setTermsAccepted(e.target.checked)}
                            />
                        </div>
                        <div>
                            <span
                                className="text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">Eu li e aceito os
                            </span>
                            <span
                                className="text-teal-400 text-base font-normal font-['Nunito'] underline leading-tight cursor-pointer"
                                onClick={() => handleTerms()}
                            > Termos de Uso e Condições
                            </span>
                        </div>
                    </div>
                    {validatedForm && !props.termsAccepted && (
                        <div className="rounded-[20px] bg-[#12455C] opacity-70 pt-[11px] pb-[11px] pl-[13px] pr-[13px] text-white flex mb-[24px]">
                            <div>
                                <div className="rounded-full bg-white mr-[16px] h-9 w-9 flex justify-center items-center">
                                    <img src={IAlert} alt="Ícone de alertas" />
                                </div>
                            </div>
                            <div>
                                É necessário aceitar os Termos de Uso e Condições. Por favor, revise e aceite os termos antes de prosseguir.
                            </div>
                        </div>
                    )}

                    <button onClick={() => handleRegister()}
                        className={`w-[220px] h-12 px-6 py-3.5 ${props.name &&
                            props.phoneNumber.length === 11 &&
                            props.termsAccepted &&
                            !!props.crm &&
                            props.ufCrm.length > 0 &&
                            userExists === false
                            ? 'bg-yellow-500'
                            : 'bg-[#9E9E9E] !cursor-default'
                            } rounded-[96px] shadow-xl justify-center items-center gap-2 inline-flex`}
                    >
                        <span
                            className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight"
                        >
                            Cadastrar
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}