
import axios from 'axios';
import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const api = 'products'

export interface ITopic {
  title: string;
  content: string;
}
export interface ILeaflet {
  isTopicsEnabled: boolean;
  isDisabled: boolean;
  topics?: ITopic[];
}
export interface ICompany {
  _id: string; 
  uniqueId: string;
  sacContent: string;
  corporateName: string;
  tradeName: string;
}
export interface IProduct {
  _id: string;
  productName: string;
  productPresentation: string;
  anvisaCode: string;
  ean1: string;
  hasLeafletVideo: boolean;
  hasLeaflet: boolean;
  description?: string;
  leafletId?: ILeaflet;
  companyId: ICompany;
  hasBanner?: boolean;
  hasAdVideo?: boolean;
}



const getPdfUrl = (anvisaCode: string) =>
  `${cdnUrl}/leafletMed/${anvisaCode.substring(0, 9)}.pdf`;

const getVideoUrl = (ean1: string) =>
  `${cdnUrl}/videoLeafletMed/${ean1}.mp4`;

const getVideoAdUrl = (ean1: string) =>
  `${cdnUrl}/videoAd/${ean1}.mp4`;

const getBannerUrl = (ean1: string) =>
  `${cdnUrl}/banner/${ean1}.webp?w=1920&q=75&d=1360x659`;

const getProductImageUrl = (uniqueId?: string) => 
  `${cdnUrl}/package/companies/${uniqueId?.replace(/\D/g,'')}.webp`;

const getBrandImageUrl = (uniqueId?: string) => 
  `${cdnUrl}/companyLogo/logo-${uniqueId?.replace(/\D/g,'')}.webp`;


const createBlobPdfLink = (pdfUrl: string, callback: (urlBlob: string) => void) => {
  console.log("Fetching PDF from URL:", pdfUrl); // Confirma a URL sendo acessada
  fetch(pdfUrl, {
    method: 'GET',
    headers: new Headers({
      'Accept': 'application/pdf'
    }),
    redirect: 'follow'
  })
  .then(response => {
    if (response.ok) return response.blob().then(blob => new Blob([blob], {type: 'application/pdf'}));
    throw new Error('Network response was not ok.');
  })
  .then(blob => {
    console.log("Received blob size:", blob.size); // Log do tamanho do blob
    if (blob.size > 0) {
      const urlBlob = URL.createObjectURL(blob);
      callback(urlBlob);
    } else {
      throw new Error('Received empty blob.');
    }
  })
  .catch(error => {
    console.error("Error downloading the file:", error);
  });
};


const findOneByEan = async (ean1: string) => {
  try {
    const response = await Api().get(`${api}?filters={"ean1":"${ean1}"}`)
    return response.data?.data?.result[0] as IProduct;

  } catch (error) {
    console.error(error);
  }
};
const findHtmlLeaflet = async (anvisaCode: string): Promise<string> => {
  var fullLeaflet = "";

  try {
    const response = await axios
      .create({
        baseURL: cdnUrl, headers:{
           Authorization: null           
        }
      })
      .get(`/leafletMedHtml/${anvisaCode.substring(0, 9)}.html`, {
        responseType: "text",
      });

    if (response.status === 200) fullLeaflet = response.data;
  } catch (error) {
    console.warn(`Erro ao buscar o leaflet:${anvisaCode} - ${error}`);
  }
  return fullLeaflet;
};

const findeAll = async (filter:string = '') => {
  try{
    const {data} = await Api().get(`${api}${filter}`) 
    return data.data.result
  }catch(error : any){
    return ApiException.fromAxiosError(error)
  }
}


const list = (filter: string = '') => {
  return Api().get(`${api}/listing${filter}`);
};



const findById = async (id: string) => {
  try{
    const { data } = await Api().get(`${api}/${id}`)
    return data
  } catch (error: any) {
    return ApiException.fromAxiosError(error)
  }
}

export const ProductService = {
  getPdfUrl,
  getVideoUrl,
  createBlobPdfLink,
  findHtmlLeaflet,
  findOneByEan,
  getVideoAdUrl,
  getBannerUrl,
  getProductImageUrl,
  getBrandImageUrl,
  findeAll,
  findById,
  list
};
