import { LeafletPresentationForm } from "./LeafletPresentationForm";
import { ProductMarketingForm } from "./ProductMarketingForm";
import { SacInformationForm } from "./SacInformationForm";
import ProductReminderForm from "./ProductReminderForm";
import ProductRecall from "./ProductRecallFrom";
import { useLoaderData } from "react-router-dom";
import { LocalStorageService, useProduct } from "../../hooks";
import {
  FavoriteService,
  ICompany,
  IProduct,
  ProductService,
} from "../../services";

import "./styles-product.css";
import ProductPresentation from "../../components/ProductPresentation";
import { allowedEANs } from "../../utils";
import { ScrollToTopButton } from "../../components/ScrollToTopButton";
import { DocumentTitle } from "../../components";
import { ProductDescription } from "./ProductDescription";

interface ProductViewModel extends IProduct {
  fullLeaflet: string;
  hasHtmlLeaflet: boolean;
  isFavourite: boolean;
  favouriteId: string;
  showSAC: boolean;
}

async function getProduct(productEan: string) {
  let showSAC = false;
  try {
    const isLoggedIn = !!LocalStorageService().getData("token");
    const resProduct = await ProductService.findOneByEan(productEan);

    if (!resProduct) {
      console.log("Produto não encontrado");
      throw new Error("Produto não encontrado");
    }

    const resFavourite = isLoggedIn
      ? await FavoriteService.findByProductId(resProduct?._id)
      : null;

    let product = {
      ...resProduct,
      isFavourite: !!resFavourite,
      favouriteId: resFavourite ? resFavourite._id : null,
    } as ProductViewModel;

    if (allowedEANs.has(product?.ean1)) {
      showSAC = true;
    }

    return { ...product, showSAC: showSAC };
  } catch (err) {
    return null; // Retorna null para indicar falha
  }
}

interface LoaderParams {
  params: any;
  request: Request;
}
export async function LoaderProduct(props: LoaderParams) {
  const { produtoId } = props.params;
  const ean = produtoId.split("-").pop(); // Garantir que isso realmente pega o EAN corretamente
  console.log("Loaded product ID:", produtoId);

  try {
    const productDetails = await getProduct(ean);
    if (!productDetails) {
      console.error(
        "Product not found, redirecting to /produto-nao-encontrado"
      );
      return (window.location.href = `${process.env.REACT_APP_SITE_URL}/produto-nao-encontrado`);
    }
    return productDetails;
  } catch (error) {
    return (window.location.href = `${process.env.REACT_APP_SITE_URL}/produto-nao-encontrado`);
  }
}

export function Product() {
  const product = useLoaderData() as ProductViewModel;
  const hookProduct = useProduct();
  hookProduct.setProduct(product);

  const shouldDisplayRecall = (companyId: ICompany) => {
    return true;
    //return hasGroup(companyId);
  };

  const productDataForPresentation = {
    id: product._id,
    productName: product.productName,
    productPresentation: product.productPresentation,
    companyName:
      product.companyId?.tradeName || product.companyId?.corporateName,
    ean1: product.ean1,
    companyId: {
      uniqueId: product.companyId?.uniqueId,
    },
  };

  const productTitle = `${productDataForPresentation.productName} ${productDataForPresentation.productPresentation} ${productDataForPresentation.companyName}`;

  return (
    <DocumentTitle
      title={productTitle}
      description={`Confira na Sara informações completas e precisas sobre a bula de ${productTitle}`}
    >
      <div className="w-full lg:flex-1 pt-[80px]  flex gap-[24px] container-produto-page">
        <div className="flex-col w-[100%]">
          <ProductPresentation
            products={[productDataForPresentation]}
            onProductClick={() => {}}
            productClassName="card-presentation"
          />

          <ProductDescription description={product?.description} />

          <div className="">
            {product && <LeafletPresentationForm product={product} />}
          </div>

          <div className="">
            {(product?.hasBanner || product?.hasAdVideo) && (
              <ProductMarketingForm
                ean1={product?.ean1}
                hasBanner={product?.hasBanner ?? false}
                hasAdVideo={product?.hasAdVideo ?? false}
              />
            )}
            <ProductMarketingForm
              ean1={product.ean1}
              hasBanner={false}
              hasAdVideo={false}
            />
            <ProductMarketingForm
              ean1={product.ean1}
              hasBanner={false}
              hasAdVideo={false}
            />
          </div>
        </div>

        <div className="gap-[24px] mb-[24px] flex-col right-cards">
          <div className="">
            <ProductReminderForm productId={product._id} />
          </div>
          <ProductRecall ean1={product?.ean1} className="desktop" />
          {shouldDisplayRecall(product?.companyId) && (
            <ProductRecall
              className="flex min-[1024px]:hidden my-4 px-4"
              ean1={product?.ean1}
            />
          )}

          <SacInformationForm sac={product.companyId?.sacContent} />

          <ScrollToTopButton />
        </div>
      </div>
    </DocumentTitle>
  );
}
