import NotFoundIMG from "../../assets/images/404.webp"
import { NotFound } from "../../components"
import { DocumentTitle } from "../../components"


export const PageNotFound = () => {

    return (
        <DocumentTitle title='Página Não Encontrada' description='Tenha acesso as diversas funcionalidades oferecidas na Sara. Bulas, modo de usar medicamentos e muito mais.'>
            <NotFound notFoundIMG={NotFoundIMG} notFoundText={"Não foi possível encontrar essa página."} />
        </DocumentTitle>
    )
}