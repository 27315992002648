import { useEffect, useState } from "react";
import { list } from "../../../services/api/product/product-service";

interface TimePresentationProps {
    timesTampList: number[];
    today: number;
}

export const TimePresentation: React.FC<TimePresentationProps> = ({ timesTampList, today }) => {
    const options: any = { day: 'numeric', month: 'long', timeZone: 'America/Sao_Paulo' }
    const [date, setDate] = useState<string>();
    const [reminders, setReminders] = useState<string[]>();
    useEffect(() => {
        if ((timesTampList[0] * 1000) < today) {
            setDate(new Date(today).toLocaleString('pt-BR', options))
        } else {
            setDate(new Date(timesTampList[0] * 1000).toLocaleDateString('pt-BR', options))
        }
        setReminders(timesTampList
            .map((reminder: number) => {
                return new Date(reminder * 1000).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', timeZone: 'America/Sao_Paulo' })
            }))
    }, [])
    return (
        <>
            {date} - {reminders?.map((data, index) => (
                <span>
                    {data} &nbsp;
                    {index !== (timesTampList.length - 1) && (
                        <span>| </span>
                    )}
                </span>
            ))}
        </>
    )
}