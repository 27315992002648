
import { Helmet } from "react-helmet"

interface IDocumentTitleProps {
    title?: string
    description?: string
    children?: React.ReactNode
}

export const DocumentTitle: React.FC<IDocumentTitleProps> = (props) => (
    <>
        <Helmet>
            <title>{`${props.title ? `${props.title} | Sara` : 'Sara | Bula de Remédio e Medicamento'}`}</title>
            {props.description && (
                <meta name="description" content={props.description} />
            )}            
        </Helmet>
        {props.children}
    </>
);