
import { useEffect, useState } from 'react';
import ICloseGray from '../../../assets/icons/i-close-gray.svg'
import IError from '../../../assets/icons/i-error.svg'
import './index.css'
import { ISendTokenModal } from '../../../types';
import { useGA } from '../../../hooks';
import { ApiException, PasswordlessService } from '../../../services';
import { useNavigate } from 'react-router-dom';

interface ISendTokenModalProps {
    sendTokenModal?: ISendTokenModal | null;
    setSendTokenModal: (sendTokenModal: ISendTokenModal | null) => void;
    setSuccessDeleteAccount: (successDeleteAccount: boolean) => void;
}

const tokenTime = 60;

export const SendTokenModal: React.FC<ISendTokenModalProps> = (props) => {
    const navigate = useNavigate()
    const { sendGAEvent } = useGA();
    const [disabledTokenInput, setDisabledTokenInput] = useState(false)
    const [disabledNewTokenRequest, setDisabledNewTokenRequest] = useState(true)
    const [verificationCode, setVerificationCode] = useState('')
    const [invalidToken, setInvalidToken] = useState(false)
    const [timerCurrent, setTimerCurrent] = useState(tokenTime);
    const [phoneNumber, setPhoneNumber] = useState(props.sendTokenModal?.phoneNumber || '')
    const [origin, setOrigin] = useState(props.sendTokenModal?.origin || '')
    const [tokenJwt, setTokenJwt] = useState('')

    useEffect(() => {
        if (props.sendTokenModal) {
            setPhoneNumber(props.sendTokenModal.phoneNumber);
            setOrigin(props.sendTokenModal.origin);
            handleGetToken();
        }
    }, [props.sendTokenModal])

    useEffect(() => {
        if (timerCurrent > 0 && props.sendTokenModal) {
            const id = setInterval(() => {
                setTimerCurrent((timerCurrent) => timerCurrent - 1);
            }, 1000);

            return () => clearInterval(id);
        }
        setDisabledNewTokenRequest(false)
    }, [timerCurrent])

    useEffect(() => {
        setInvalidToken(false);
        if (verificationCode.length !== 6) return;
        setDisabledTokenInput(true);
        switch (origin) {
            case 'delete':
                handleDeleteUser()
                break;
            case 'update':
                handleUpdateUser();
                break
        }
    }, [verificationCode])

    const handleGetToken = () => {
        PasswordlessService.sendVerification(props.sendTokenModal?.phoneNumber || '', props.sendTokenModal?.origin || '')
            .then((res) => {
                if (res instanceof ApiException) {
                    console.error('error', res)
                    if (res.message === 'User not found') navigate(`/cadastro?phoneNumber=${phoneNumber}`)
                } else {
                    setTokenJwt(res.data)
                }
            })
    }

    const handleCodeRequest = () => {
        sendGAEvent('reenviar_token')

        if (!disabledNewTokenRequest) {
            setDisabledNewTokenRequest(true)
            setTimerCurrent(tokenTime)

            PasswordlessService.sendVerification(phoneNumber, 'resend')
                .then((res) => {
                    if (res instanceof ApiException) {
                        console.log("error", res)
                    } else {
                        setTokenJwt(res.data)
                    }
                })
        }
    }

    const handleUpdateUser = () => {
        PasswordlessService.update(phoneNumber, verificationCode)
    }

    const handleDeleteUser = () => {
        PasswordlessService.deleteAccount(verificationCode, tokenJwt)
            .then((res) => {
                if (res instanceof ApiException){
                    console.log("error", res)
                    setInvalidToken(true)
                    setDisabledTokenInput(false)
                } else {
                    if (res.status != 200) props.setSuccessDeleteAccount(false);
                    sendGAEvent('conta_desativada')
                    closeModal()
                    props.setSuccessDeleteAccount(true)
                }
            })
    }
    
    const closeModal = () => {
        props.setSendTokenModal(null);
        setPhoneNumber('');
        setTokenJwt('');
        setVerificationCode('');
        setOrigin('');
        setInvalidToken(false);
        setDisabledTokenInput(false);
        setDisabledNewTokenRequest(false);
        setTimerCurrent(tokenTime)
    }

    return (
        <>
            {props.sendTokenModal && (
                <div className="modal-container-send-token">
                    <div
                        className="w-full max-w-[530px] h-auto p-[36px] m-[36px] rounded-3xl bg-white overflow-auto"
                    >
                        <button className="block ml-auto" onClick={closeModal}>
                            <img
                                src={ICloseGray}
                                alt="ícone de fechar modal"
                            />
                        </button>
                        <div className="flex flex-col justify-start items-center mb-[24px]">
                            <p className="font-['Nunito'] text-2xl font-bold text-[#272727] mb-[24px]">
                                Insira o Token
                            </p>

                            <span className="font-['Nunito'] text-base font-normal text-[#272727]">
                                Enviamos um <strong>TOKEN</strong> via <strong>SMS</strong>
                                no telefone cadastrado para confirmação dos dados.
                            </span>
                        </div>
                        <div className='form-base-send-token w-full mb-[24px]'>
                            <div>
                                <input
                                    className="text-content token-content text-center font-bold"
                                    disabled={disabledTokenInput}
                                    type="text"
                                    required
                                    placeholder="XXXXXX"
                                    value={verificationCode}
                                    maxLength={6}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                />
                            </div>
                            {invalidToken && (
                                <div
                                    className="text-xs font-normal text-[#9E9E9E] mt-2 flex"
                                >
                                    <div>
                                        <img
                                            src={IError}
                                            alt="Ícone de erro"
                                            className="float-left pr-3"
                                        />
                                    </div>
                                    <div>
                                        O token inserido é inválido. Por favor, verifique o token e
                                        tente novamente.
                                    </div>

                                </div >)}
                        </div>

                        <div
                            className="self-stretch flex-col justify-center items-center gap-4 flex"
                        >
                            <div
                                className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight"
                            >
                                Não recebeu ainda? Tente:
                            </div>

                            <div
                                className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight"
                            >
                                {disabledNewTokenRequest && (
                                    <span>Aguarde {timerCurrent} segundos para solicitar um novotoken.</span>
                                )}
                                {!disabledNewTokenRequest && (
                                    <span>Solicite um novo Token para ser enviado para o seu número de
                                        celular.</span>
                                )}
                            </div >

                            <button
                                onClick={handleCodeRequest}
                                className={`w-[220px] h-12 px-6 py-3.5 ${disabledNewTokenRequest ? 'bg-[#9E9E9E]' : 'bg-white'
                                    } rounded - [96px] border border - teal - 400 justify - center items - center gap - 2 inline - flex`}
                            >
                                <div
                                    className={`${disabledNewTokenRequest ? 'text-black' : 'text-teal-400'
                                        } text-sm font-bold font-['Nunito'] leading-tight`}
                                >
                                    Reenviar TOKEN
                                </div>
                            </button >
                        </div >
                    </div >
                </div >

            )}

        </>
    )
}