import { LocalStorageService } from "../../hooks";

export class ApiException extends Error{
    public readonly message: string = '';
    public readonly status : number | null = null; 

    constructor(message: string, status: number | null = null){
        super() ;
        this.message = message;
        this.status = status;
    }

    public static fromAxiosError(error: any): ApiException {
        if (error.response && error.response.status) {
            const status = error.response.status;
            let message = error.message;

            switch (status) {
                case 401:
                    message = 'Usuário não encontrado';
                    LocalStorageService().removeData('token')
                    LocalStorageService().removeData('userData')
                    window.location.reload()
                    break;
                default:
                    message = error.message || 'Erro ao consultar a API';
            }

            return new ApiException(message, status);
        }

        return new ApiException(error.message || 'Erro ao consultar a API');
    }
}