import { DocumentTitle, News, Solution } from "../../components";
import { ScrollToTopButton } from "../../components/ScrollToTopButton";
import { DailyReminder } from "./DailyReminder";
import "./index.css";

export const Dashboard = () => {
    return (
        <DocumentTitle
            title="Dashboard"
            description="Olá, Sou a Sara! Fui desenvolvida para facilitar o acesso democrático à informações confiáveis de saúde, medicamentos e bulas. Acesse e saiba mais!"
        >
            <div className="application">
                <DailyReminder />
                <Solution />
                <News dashboardTemplate={true}   />
                <ScrollToTopButton />
            </div>
        </DocumentTitle>
    );
};
