import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga'
import { useGA } from "../hooks"
interface IGoogleAnalytics{
    children: React.ReactNode
}

export const GoogleAnalytics: React.FC<IGoogleAnalytics> = ( props ) => {
    const location = useLocation()
    const { sendGAEvent } = useGA()

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
        sendGAEvent("screen_view")    
    },[location])

    return (
        <>
            {props.children}
        </>
    )
}