import styled from "styled-components"

export const FormBase = styled.div.attrs({
  className: "w-full"
})`
  margin-top: 8px 0;

  & p {
    display: inline;
    position: relative;
    bottom: -10px;
    margin-left: 20px;
    padding: 0 11px;
    background-color: white;

    font-family: "Nunito-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6a6f75;
  }

  & input,
  select {
    width: 100%;
    height: 60px;
    display: block;
    padding: 16px 8px;
    border-radius: 8px;
    border: 2px solid #f0f5f6;
    color: #272727;
    background-color: white;
  }
`

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  input {
    width: 24px;
    height: 24px;
    appearance: none;
    border: 2px solid #f0f5f6;
    border-radius: 4px;
    background-color: #f0f5f6;
    cursor: pointer;

    &:checked {
      background-color: #32dac3;
      border-color: #32dac3;
      &:after {
        content: '✔';
        color: white;
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;


