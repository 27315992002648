import ReactGA from 'react-ga'
import { useLocalStorage } from './UseLocalStorage'

export const useGA = () => {
  const { getData } = useLocalStorage()
  const sendGAEvent = (eventName: string, customProps: Record<string, string | number | boolean | void> = {}) => {
    const userData = getData('userData')
    const userId = userData ? userData._id ?? null : null
    if (userId) customProps = {
      ...customProps,
      user_id: userId
    }
    ReactGA.event({
      category: "event",
      action: eventName,
      ...customProps
    })
  }
  return { sendGAEvent }
}