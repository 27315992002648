import { useCallback, useState } from "react";
import { DocumentTitle, News } from "../../components";
import { ScrollToTopButton } from "../../components/ScrollToTopButton";

export function NewsPage() {
    const [threeGroupedNewsQtd, setThreeGroupedNewsQtd] = useState([1, 2, 3]);
    const [newsListLength, setNewsListLength] = useState(0);


    const handleLoadMoreNews = useCallback(() => {
        setThreeGroupedNewsQtd([...threeGroupedNewsQtd, threeGroupedNewsQtd[threeGroupedNewsQtd.length - 1] + 1]);
    }, [threeGroupedNewsQtd]);

    return (
        <DocumentTitle
            title="Blog de Notícias"
        >
            <div className="flex flex-col">
                <div
                    className="islogged w-full text-white text-lg font-medium font-['Nunito'] tracking-[.36px]"

                > Aqui, você encontra o ambiente ideal para se manter informado sobre tudo relacionado à saúde, bem-estar e muito mais.</div >
                <News
                    threeGroupedNewsQtd={threeGroupedNewsQtd}
                    setNewsListLength={(number) => {
                        setNewsListLength(number)
                    }}
                    dashboardTemplate={false}
                />
                <div className="flex justify-center items-center flex-col">
                    {(newsListLength + 3 > (threeGroupedNewsQtd.length + 1) * 3) && (
                        <div
                            className="w-[220px] bg-white rounded-[96px] border-[1px] border-black p-[24px] pt-[14px] pb-[14px] text-center font-bold mb-6 cursor-pointer"
                            onClick={handleLoadMoreNews}
                            data-cy="load-more-news"
                        >
                            Carregar mais
                        </div>
                    )}
                    <ScrollToTopButton />
                </div>
            </div>
        </DocumentTitle>
    );
}
