import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../hooks";
import { InputName } from "./components/InputName";
import { InputPhoneNumber } from "./components/InputPhoneNumber";
import { CheckboxWrapper } from './style';
import { PeopleService, ApiException } from "../../../services/";

export const RegisterForm = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryPhoneNumber = queryParams.get('phoneNumber') || undefined;
    const favourite = queryParams.get('favourite') ? true : false;

    const { setItem, getData, removeData } = useLocalStorage();
    const [name, setName] = useState('');
    const [nameValidated, setNameValidated] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberValidated, setPhoneNumberValidated] = useState(false);

    const [userExists, setUserExists] = useState<boolean>();

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsAcceptedValidated, setTermsAcceptedValidated] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const data = getData('formRegister');
        if (queryPhoneNumber) {
            setPhoneNumber(queryPhoneNumber);
        }
        if (data) {
            setName(data?.name || '');
            setPhoneNumber(data?.phoneNumber || '');
            removeData('formRegister');
        }
        if (getData('token')) removeData('token');
        if (getData('userData')) removeData('userData');
    }, []);

    useEffect(() => {
        if (phoneNumber.length === 11) {
            checkUserExistence();
        }
    }, [phoneNumber]);

    const handleTerms = () => {
        setItem('formRegister', { name: name, phoneNumber: phoneNumber });
        navigate('/termos');
    };

    const handleRegister = async () => {
        const validation = phoneNumber.length === 11 && name.length > 1 && termsAccepted;
        if (!validation) return;
        setNameValidated(true);
        setPhoneNumberValidated(true);
        setTermsAcceptedValidated(true);
        if (validation && !userExists) {
            const params = new URLSearchParams({
                type: 'signup',
                name: name,
                phoneNumber: phoneNumber,
                favourite: favourite.toString()
            });
            navigate(`/token?${params.toString()}`);
        }
    };

    const checkUserExistence = async () => {
        if (!phoneNumber || phoneNumber.length !== 11) {
            setUserExists(false);
            return;
        }
        await PeopleService.checkUserExistence(phoneNumber)
            .then((res) => {
                if (res instanceof ApiException) {
                    setUserExists(false);
                } else {
                    setUserExists(true);
                }
            });
    };

    return (
        <div className="w-full justify-center items-center flex">
            <div className="py-6 px-2 bg-white rounded-3xl flex-col justify-center items-center inline-flex gap-y-6 w-[350px] mb-8">
                <div className="self-stretch flex-col justify-start items-end gap-2 flex">
                    <div className="self-stretch justify-start items-center gap-4 inline-flex">
                        <p className="grow shrink basis-0 text-center text-neutral-800 text-2xl font-bold font-['Nunito'] leading-loose">
                            Cadastre-se
                        </p>
                    </div>
                    <span className="self-stretch text-center text-neutral-800 text-lg font-medium font-['Nunito'] leading-7">
                        Junte-se à comunidade e aproveite as vantagens de cuidar da sua saúde com a Sara
                    </span>
                    <span className="self-stretch text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                        Preencha abaixo para realizar seu cadastro:
                    </span>
                </div>

                <div className="w-full">
                    <InputName name={name} setName={setName} nameValidated={nameValidated} setNameValidated={setNameValidated} />
                    <InputPhoneNumber name={name} nameValidated={nameValidated} phoneNumberValidated={phoneNumberValidated} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} userExists={userExists} setPhoneNumberValidated={setPhoneNumberValidated} />
                </div>

                <div className="justify-start items-start gap-2 inline-flex">
                    <CheckboxWrapper>
                        <input
                            type="checkbox"
                            className={`w-6 h-6 left-0 top-0 absolute ${termsAcceptedValidated && !termsAccepted ? 'invalid' : ''}`}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                    </CheckboxWrapper>
                    <div>
                        <span className="text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">Eu li e aceito os </span>
                        <span className="text-teal-400 text-base font-normal font-['Nunito'] underline leading-tight cursor-pointer" onClick={() => handleTerms()}>Termos de Uso e Condições</span>
                    </div>
                </div>
                <button onClick={handleRegister}
                    className={`w-[220px] h-12 px-6 py-3.5 ${name && phoneNumber.length === 11 && termsAccepted && userExists === false ? 'bg-yellow-500' : 'bg-[#9E9E9E] !cursor-default'} rounded-[96px] shadow-xl justify-center items-center gap-2 inline-flex`}>
                    {/* Terminar Classe Button */}
                    <div className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight">Cadastrar</div>
                </button>
                <Link className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight" to="/login">
                    Já Possuí conta?
                </Link>
            </div>
        </div>
    );
};

export default RegisterForm;
