import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException'

const api = 'people'

const checkUserExistence = async (phoneNumber: string) => {
    try {
        const { } = (await Api().get(`/${api}/phone-number/${phoneNumber}`))
    } catch (error: any) {
        return new ApiException(error.message || 'Erro ao consultar a API')
    }
}

const save = async (body: any) => {
    try {
        const { data } = (await Api().patch(`/${api}/${body._id}`, (body)))
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const find = async () => {
    try {
        const { data } = (await Api().get(`/${api}/user`))
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const findAll = async (filter: string) => {
    try {
        const { data } = (await Api().get(`/${api}/user${filter}`))
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const findById = async (id: string) => {
    try {
        const { data } = (await Api().get(`/${api}/user/${id}`))
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

export const PeopleService = {
    checkUserExistence,
    save,
    find,
    findAll,
    findById,
}