import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException'

const api = 'passwordless'

const sendVerification = async (phoneNumber: string, origin: string, token?: string) => {
    try {
        const { data } = await Api()
            .get(
                `/${api}/send-verification?phone-number=${phoneNumber}&origin=${origin}`, 
                { 
                    headers: {
                        Authorization: ''           
                }})
        return data
    } catch (error: any) {
        return new ApiException(error.message || 'Erro ao consultar a API')
    }
}

const register = async (phoneNumber: string, name: string, verificationCode: string, token: string) => {
    try {
        const body = {
            phoneNumber: phoneNumber,
            verificationCode: verificationCode,
            name: name,
            receivesWhatsapp: true
        }
        const { data } = (await Api().post(`/${api}/signup`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                charset: 'utf-8'
            }
        }))
        return data;
    } catch (error: any) {
        return new ApiException(error.message || 'Erro ao consultar a API')
    }
}

const login = async (phoneNumber: string, verificationCode: string, token: string) => {
    try {
        const { data } = await Api().get(`/${api}/login?phone-number=${phoneNumber}&verification-code=${verificationCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        return data
    } catch (error: any) {
        return new ApiException(error.message || 'Erro ao consultar a API')
    }
}

const deleteAccount = async (verificationCode: string, token: string) => {
    try {
        const { data, status } = await Api().delete(`/${api}/delete-account?verification_code=${verificationCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        return {data, status}
    } catch (error: any) { return new ApiException(error.message || 'Erro ao consultar a API') }
}

const update = async (phoneNumber: string, verificationCode: string) => {
    const body = {phoneNumber: phoneNumber, verificationCode: verificationCode}
    try {
        const { data } = await Api().put(`/${api}/update-user`, body)
        return data
    } catch (error: any) { 
        return ApiException.fromAxiosError(error)
    }
}

export const PasswordlessService = {
    sendVerification,
    login,
    register,
    deleteAccount,
    update
}