import React from "react";
import GenericBox from '../../assets/images/generic-box.webp'

interface ProductImageProps {
    id: string,
    productName: string,    
    objectFit?: 'cover' | 'contain';
}

export const ProductImage: React.FC<ProductImageProps> = (
    props
  ) => {

    return (
        <img
            src={`https://cdn.sara.com.br/package/companies/${props.id}.webp`}
            alt={`imagem de ${props.productName}`}
            onError={(e) => {
                e.currentTarget.onerror = null; // Impede looping de erro
                e.currentTarget.src = GenericBox; // Imagem fallback
            }}
            className="box-product"
            style={{ position: 'relative', width: '50px', objectFit: props.objectFit || 'cover', margin: 'auto', zIndex: 1 }}
        />
    );
}