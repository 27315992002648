import ImgInfoIcon from "../../../assets/icons/i-info.svg";
import ImgEmailIcon from "../../../assets/icons/i-mail-gray.svg";
import ImgTelIcon from "../../../assets/icons/i-tel-gray.svg";

interface ISacInformationForm {
    sac: string;
}
export function SacInformationForm(props: ISacInformationForm) {
    return (
        <section className="w-full">
            {props.sac && (<div className="faq-menu">
                <div className="faq-conteudo gap-[8px] lg:gap-[24px] ">
                    <div className="faq-content-mocked">
                        <div className="title-sac justify-center lg:justify-start">
                            <img src={ImgInfoIcon} alt="" />
                            <span>Fale com o SAC</span>
                        </div>

                        <div
                            className="self-stretch text-zinc-500 text-base font-normal font-['Nunito'] leading-tight"
                            dangerouslySetInnerHTML={{ __html: props.sac }}
                        ></div>
                    </div>
                </div>
            </div>)}
        </section>
    )
}
