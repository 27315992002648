import { useState } from "react"
import { TokenForm } from "./TokenForm"
import { RegisterSuccess } from "./RegisterSuccess"
import { BackgroundForm, FormContainer } from "../../components"
import { TokenContainer as Container } from "../../styles/styled"
import { DocumentTitle } from "../../components"
const tokenTime = 60

export const MedicalToken = () => {
    const [pageSuccess, setPageSuccess] = useState(false)
    
    return (
        <DocumentTitle title='Token' description='Descubra os mais diversos medicamentos, salve os seus preferidos, defina alarmes e lembretes, leia a bula, utilização e mais. Insira o token fornecido!'>
            <Container>
                <div className="w-full min-h-screen flex justify-between min-[1025px]:bg-white">
                    <div className="relative w-full mx-auto px-[24px] min-[1025px]:w-auto min-[1025px]:px-0 min-[1025px]:min-w-[540px] min-[1025px]:pl-[24px]">
                        <FormContainer>
                            {!pageSuccess && (
                                <>
                                    <TokenForm tokenTime={tokenTime} pageSuccess={pageSuccess} setPageSuccess={setPageSuccess} />
                                </>
                            )}
                            {pageSuccess && (
                                <>
                                    <RegisterSuccess />
                                </>
                            )}
                        </FormContainer>
                    </div>
                    <BackgroundForm />
                </div >
            </Container >
        </DocumentTitle>
    )
}