import { useGA } from "../../../hooks"

import IConfigProfile from "../../../assets/icons/i-config-profile.svg"

interface IAccountDisableProps{
    setDisableAccountModal: (disableAccountModal: boolean) => void;
}

export const AccountDisable: React.FC<IAccountDisableProps> = (props) => {
    const { sendGAEvent } = useGA();    
    const handleDisableAccount = () => {
        props.setDisableAccountModal(true)
        sendGAEvent('clique_desativar_conta')
    }

    return (
        <div className="cards-actions rounded-[24px] px-2 py-6 bg-white">
            <div className="flex justify-start items-center gap-2">
                <img
                    src={IConfigProfile}
                    alt="imagem de homem sorrindo ouvindo música"
                />
                <span className="title-perfil text-2xl font-bold">Gerenciar Conta</span>
            </div>
            <div className="flex flex-col justify-center items-center">
                <p className="text-content-perfil my-6">
                    Estamos aqui para apoiá-lo em sua jornada de saúde, e sentiremos muito
                    a sua falta se optar por desativar sua conta.
                </p>
                <button
                    onClick={() => handleDisableAccount()}
                    className="btn-perfil border border-black mb-6"
                    data-cy="btn-desativar-conta-perfil"
                >
                    Desativar Conta
                </button>
            </div>
        </div>
    )
}