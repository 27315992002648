import { FormBase } from "../../../../styles/styled";
import InputMask from "react-input-mask"
import IError from "../../../../assets/icons/i-error.svg"
import ISucess from "../../../../assets/icons/i-success.svg"

interface IInputPhoneNumberProps{
    phoneNumber: string
    validatedForm: boolean
    userExists: boolean

    setPhoneNumber: (value: string) => void
}

export const InputPhoneNumber: React.FC<IInputPhoneNumberProps> = (props) => {
    return (
        <FormBase>
            <div className="relative">
                <p>Telefone</p>
                <InputMask
                    type="text"
                    className={`text-content ${(props.validatedForm && props.phoneNumber.length < 11) || props.userExists ? 'invalid' : ''}`}
                    required
                    placeholder="(00) 00000-0000"
                    value={props.phoneNumber}
                    onChange={(e) => { props.setPhoneNumber(e.target.value.replace(/\D/g, '')) }}
                    mask="(99) 99999-9999"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pt-4">
                    {(props.validatedForm && !props.phoneNumber) || props.userExists && (
                        <img src={IError} alt="Ícone de erro" />
                    )}
                    {props.phoneNumber && !props.userExists && (
                        <img src={ISucess} alt="Ícone de sucesso" />
                    )}
                </div>
            </div>
            {props.validatedForm && props.phoneNumber.length < 11 && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IError} alt="Ícone de erro" className="float-left" />&nbsp; É necessário preencher o seu número para continuar
                </div>
            )}
            {props.phoneNumber && props.userExists && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2 flex">
                    <div className="pr-2">
                        <img src={IError} alt="Ícone de erro" className="w-11" />
                    </div>
                    <div>
                        Este número de telefone já está em uso. Por favor, insira um
                        número de telefone válido que ainda não tenha sido
                        registrado em nossa plataforma. Ou Acesse sua conta.
                    </div>
                </div>
            )}
        </FormBase>
    )
}