import { ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useCallback, useMemo, useState } from "react";
import { SaraTheme } from "../styles/themes";

interface IThemeSelectorContextData {
    themeName: 'sara'
    toggleTheme: () => void;
}

export const ThemeSelectorContext = createContext({} as IThemeSelectorContextData);

interface IThemeSelectorProps{
    children: React.ReactNode;
}

export const ThemeSelectorProvider: React.FC<IThemeSelectorProps> = ( { children } ) => {
    const [themeName, setThemeName] = useState<'sara'>('sara')

    const toggleTheme = useCallback(() => {
        setThemeName(oldThemeName => oldThemeName === 'sara' ? 'sara' : 'sara')
    }, [])

    const theme = useMemo(() => {
        return SaraTheme
    }, [themeName])

    return (
        <ThemeSelectorContext.Provider value={{ themeName, toggleTheme }}>
            <ThemeProvider theme={ theme }>
                <Box bgcolor={ theme.palette.background.default }>
                    {children}
                </Box>
            </ThemeProvider>
        </ThemeSelectorContext.Provider>
    )
}
