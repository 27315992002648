import { Link } from "react-router-dom"

import { Title, TitleSecondary } from "./styled"
import IBulaE from "../../assets/icons/i-bula-e.svg"
import ILembrete from "../../assets/icons/i-lembrete.svg"
import ISininho from "../../assets/icons/i-sininho.svg"

import "./index.css"

export const Solution = () => {

    const sendGAService = () => {
        // (click)="
        //   gaService.sendEvent('nossas_solucoes', {
        //     selected_solution: 'Bula Eletrônica'
        //   })

        // (click)="
        //             gaService.sendEvent('nossas_solucoes', {
        //                 selected_solution: 'Lembretes de Remédios'
        //   })


        // (click)="
        //             gaService.sendEvent('nossas_solucoes', {
        //                 selected_solution: 'Recall de medicamentos'
        //   })
    }
    return (
        <div className="w-[100%] px-0 top-menu mt-[24px]">
            <TitleSecondary className="pt-[8px] pl-[16px] m-0 title-solutions-home">
                <h3>Nossas soluções </h3></TitleSecondary>
            <div className="cards-wrapper">
                <div className="cards-solutions mx-[16px]">
                    <Link
                        className="card-solution"
                        to="/busca-de-produtos"
                        onClick={sendGAService}
                        data-cy="card-bula-eletronica"
                    >
                        <div className="header-card">
                            <div className="header-link">
                                <img
                                    src={IBulaE}
                                    alt="icone bula eletrônica"
                                />
                                <h3>Bula Eletrônica</h3>
                            </div>
                        </div>
                        <span>
                            Encontre todas as informações relevantes sobre seus medicamentos
                            de forma simples e facilitada.
                        </span>
                    </Link>
                    <Link
                        className="card-solution"
                        to="/lembretes"
                        onClick={sendGAService}
                        data-cy="card-solutions-lembretes"
                    >
                        <div className="header-card">
                            <div className="header-link">
                                <img
                                    src={ILembrete}
                                    alt="icone lembrete"
                                />
                                <h3>Lembretes de Medicamentos</h3>
                            </div>
                        </div>
                        <span>
                            Crie lembretes para seus medicamentos e tratamentos, garantindo
                            que você nunca deixe de tomar sua dose.
                        </span>
                    </Link>
                    <Link
                        className="card-solution"
                        to="/busca-de-produtos"
                        onClick={sendGAService}
                        data-cy="card-recall-medicamentos"
                    >
                        <div className="header-card">
                            <div className="header-link">
                                <img
                                    src={ISininho}
                                    alt="icone sininho notificação recall"
                                />
                                <h3>Recall de Medicamentos</h3>
                            </div>
                        </div>
                        <span>
                            Informe o lote do seu medicamento e receba um aviso caso haja
                            algum problema com esse lote que ofereça riscos à sua saúde e
                            segurança.
                        </span>
                    </Link>
                </div>
            </div >
        </div >

    )
}