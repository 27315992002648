import { useEffect, useRef, useState } from "react";
import iArrowTop from "../../assets/icons/i-arrow-top.svg";
import ICalendar from "../../assets/icons/i-calendar-gray.svg";
import { ApiException, News, NewsService } from "../../services";
import "./index.css";
import { LoaderParams } from "../../types/LoaderParams";
import { Navigate, useLoaderData } from "react-router-dom";

// async function getNewsById(newsId: string) {
//     const response = await NewsService.findById(newsId);
//     return response.data as News;
// }

interface NewsLoader {
    news?: News,
    htmlText?: string
    externalUrl?: string
}

export async function LoaderNewsDetails(
    props: LoaderParams
): Promise<NewsLoader> {
    const simplexLink = `https://i4c-sara.simplex.live/${props.params.id}/${props.params.file}`
    const externalUrl = simplexLink;
    return { externalUrl: externalUrl }
    // console.warn(props.params);F
    // const externalUrl = simplexLink;
    // return { externalUrl: externalUrl }

    // const { id } = props.params;
    // const news = await getNewsById(id)
    // return news;
}

export function NewsDetails() {
    const newsBodyRef = useRef<HTMLDivElement>(null);
    const data = useLoaderData() as NewsLoader
    // const [currentNews, setCurrentNews] = useState<News>(data.news ? data.news : {} as News);
    // if (data.news) setCurrentNews(data.news)
        
    if (data.externalUrl) {
        window.location.assign(data.externalUrl!)
        return null
    }

    const scrollToTop = () => {
        newsBodyRef.current?.scrollTo({ top: 0, behavior: "smooth" });
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <Navigate to='/home' />
    )
    // return (
    //     <DocumentTitle
    //         title={`${currentNews.title} - ${defaultTitle}`}
    //         description={currentNews?.brief}
    //     >
    //         <div className="container">
    //             <div className="row">
    //                 {currentNews && (
    //                     <div className="col p-0">
    //                         <div className="news-image h-[357px] md:h-[638px]">
    //                             <img
    //                                 src={currentNews.image}
    //                                 alt={currentNews.imageAlt}
    //                                 className="object-cover"
    //                             />
    //                         </div>
    //                         <div className="col-10 mx-auto">
    //                             <div className="content-news md:-mt-64 -mt-20 md:p-12 p-3.5">
    //                                 <div className="pb-2 pt-3 text-base flex text-[#6A6F75]">
    //                                     <img src={ICalendar} alt="icone calendario" />
    //                                     &nbsp;
    //                                     <p>
    //                                         {
    //                                             new Date(currentNews._updatedAt).toLocaleDateString()
    //                                         }
    //                                     </p>
    //                                 </div>
    //                                 <h1
    //                                     className="text-3xl font-bold pb-4"
    //                                     id="titleNews"
    //                                 >
    //                                     {currentNews.title}
    //                                 </h1>
    //                                 <div
    //                                     ref={newsBodyRef}
    //                                     className="news-content"
    //                                     dangerouslySetInnerHTML={{
    //                                         __html: currentNews.content,
    //                                     }}
    //                                 ></div>
    //                             </div>
    //                             <a
    //                                 onClick={scrollToTop}
    //                                 id="scrollTop"
    //                             >
    //                                 <img src={iArrowTop} alt="" /> Voltar ao
    //                                 topo
    //                             </a>
    //                         </div>
    //                         <br />
    //                     </div>
    //                 )}
    //             </div>
    //         </div>
    //     </DocumentTitle>
    // );
}
