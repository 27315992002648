import { useLocation } from "react-router-dom"
import { useLocalStorage } from "./UseLocalStorage"


export const useIsAuthProtected = () =>{
    const local = useLocation()
    const { getData } = useLocalStorage()
    const isLoggedIn = getData('token') && getData('userData')
    if (local.pathname === '/' && isLoggedIn) return false
    if (local.pathname === '/dashboard' && !isLoggedIn) return false
    return true
}