import { Navigate, createBrowserRouter } from "react-router-dom"
import { Token, Login, Register, Terms, MedicalRegister, MedicalToken, PageNotFound, ProductNotFound, Main, Home, Dashboard, Product, BuscaProdutoPage, LoaderProduct, Perfil, Lembretes, LoaderReminder } from '../pages'
import { ProtectedRoute } from "./PrivateRoute"
import { GoogleAnalytics } from "./GoogleAnalytics"
import { LoaderNewsDetails, NewsDetails } from "../pages/news-details";
import { NewsPage } from "../pages/news-list";
import { LoaderRecall, Recall } from "../pages/recall";
import { SitemapViewer, SitemapViewerLoader } from "../pages/sitemap-viewer";
import Favorito from "../pages/favorito";


export const router = createBrowserRouter([
    {
        path: "/",
        errorElement: <PageNotFound />,
        element: <Main />,
        children: [
            {
                path: "/",
                element: (
                    <ProtectedRoute>
                        <GoogleAnalytics>
                            <Home />
                        </GoogleAnalytics>
                    </ProtectedRoute>
                ),
            },
            {
                path: "/dashboard",
                element: (
                    <ProtectedRoute>
                        <GoogleAnalytics>
                            <Dashboard />
                        </GoogleAnalytics>
                    </ProtectedRoute>
                ),
            },
            {
                path: "/busca-de-produtos",
                element: (
                    <GoogleAnalytics>
                        <BuscaProdutoPage />
                    </GoogleAnalytics>
                ),
            },
            {
                path: "/produto/:produtoId",
                loader: LoaderProduct,
                element: (
                    <GoogleAnalytics>
                        <Product />
                    </GoogleAnalytics>),
            },
            {
                path: "/buscar/:produtoId",
                loader: LoaderProduct,
                element: (
                    <GoogleAnalytics>
                        <Product />
                    </GoogleAnalytics>),
            },
            {
                path: "/catalogo-de-produtos/:page?",
                loader: SitemapViewerLoader,
                element: <SitemapViewer />
            },
            {
                path: "/favoritos",
                element: <Favorito />,
            },
            {
                path: "/noticias",
                element: <NewsPage />,
            },  
            // {
            //     path: "/noticias/:id",
            //     loader: LoaderNewsDetails,
            //     element: <NewsDetails />,
            // },       
            {
                path: "/lembretes",
                loader: LoaderReminder,
                element: <GoogleAnalytics><Lembretes /></GoogleAnalytics>,
            },
            {
                path: 'recall-de-medicamento/:id',
                loader: LoaderRecall,
                element: <Recall />,
            },
            {
                path: "/perfil",
                element: <Perfil />,
            },
        ],
    },
    {
        path: "/token",
        element: <Token />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/cadastro",
        element: <Register />,
    },
    {
        path: "/termos",
        element: <Terms />,
    },
    {
        path: "/cadastro-medico",
        element: <MedicalRegister />,
    },
    {
        path: "/token-medico",
        element: <MedicalToken />,
    },
    {
        path: "/pagina-nao-encontrada",
        element: <PageNotFound />,
    },
    {
        path: "/produto-nao-encontrado",
        element: <ProductNotFound />,
    },
    {
        path: "/noticias/:id/:file",
        loader: LoaderNewsDetails,
        element: <NewsDetails />,
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
]);
