import axios, { AxiosError } from "axios";
import { LocalStorageService } from "../../hooks";

const baseURL = process.env.REACT_APP_BASE_URL

export const Api = () => {
    const token =  LocalStorageService().getData('token');
    
    if(token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Accept'] = 'application/json';

    return axios.create({
        baseURL: baseURL
    })
}