const BASE_URL = process.env.REACT_APP_BASE_URL
const API = 'products';

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

interface ApiResponse {
    products: Product[];
  }
  
  interface Product {
    id: string;
    name: string;
    ean: string;
  }

// Em product-service.ts
export const fetchData = async (url: RequestInfo, options: RequestInit = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorBody = await response.json();
      return
    }
    return await response.json();
  } catch (error: any) {
    throw error;
  }
};

  

export const list = (filter = '', token?: string) => {
  const headers: HeadersInit = token ? { Authorization: `Bearer ${token}` } : {};
  return fetchData(`${BASE_URL}/${API}/listing${filter}`, { headers });
};

export const findAll = (filter = '', token?: string) => {
  const headers: HeadersInit = {
    'Access-Control-Allow-Origin': '*',
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  return fetchData(`${BASE_URL}/${API}${filter}`, { headers });
};

export const findById = async (id: string, token?: string) => {
  const headers: HeadersInit = {
    'Access-Control-Allow-Origin': '*',
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  const response = await fetchData(`${BASE_URL}/${API}/${id}`, { headers });
  
  // Verifica se há dados e se há uma chave 'data'
  if (!response || !response.data) return null;

  // Aqui você pode verificar se 'ean1' está presente antes de fazer uma chamada adicional
  if (!response.data.ean1) {
    return { data: response.data };
  }

  // Continua para buscar mais informações baseado no ean1, se necessário
  const findAllResponse = await findAll(`?filters={"ean1":"${response.data.ean1}"}`, token);
  if (!findAllResponse.data || !findAllResponse.data.result.length) return null;

  const product = findAllResponse.data.result[0];

  // Retornar os dados do produto incluindo a empresa
  return {
    data: {
      ...response.data,
      companyId: product.companyId,
    },
  };
};


export const findByEan = async (ean: string, token?: string) => {
  const headers = getHeaders(token);
  const response = await fetchData(`${BASE_URL}/${API}?filters={"ean":"${ean}"}`, { headers });
  return response.products.length > 0 ? response.products[0] : null;
};


const getHeaders = (token?: string): HeadersInit => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  ...(token ? { 'Authorization': `Bearer ${token}` } : {})
});

export const ProductService = {
  list,
  findAll,
  findById,
  findByEan,
}

