import { FormBase } from "../../../../styles/styled";
import IError from "../../../../assets/icons/i-error.svg"
import ISucess from "../../../../assets/icons/i-success.svg"

interface IInputNameProps {
    validatedForm: boolean
    title: string
    placeholder: string
    value: string
    type?: string
    hasValidation?: boolean
    setValue: (value: string) => void
}

export const InputText: React.FC<IInputNameProps> = (props) => {
    return (
        <FormBase>
            <div className="relative">
                <p>{props.title}</p>
                <input
                    className={`text-content ${props.validatedForm && !props.value ? 'invalid' : ''}`}
                    type="text"
                    placeholder={props.placeholder}
                    required
                    value={props.value}
                    onChange={e => props.setValue(e.target.value)}
                />
                {props.hasValidation && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {props.validatedForm && !props.value && (
                            <img src={IError} alt="Ícone de erro" />
                        )}
                        {props.value && (
                            <img src={ISucess} alt="Ícone de acerto" />
                        )}
                    </div>
                )}
            </div>
            {props.hasValidation && props.validatedForm && !props.value && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IError} alt="Ícone de erro" className="float-left" />&nbsp; É necessário preencher o {props.type} para continuar.
                </div>
            )}
        </FormBase>
    )
}