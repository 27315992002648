import { Api } from "../ApiConfig";
import { ApiException } from "../ErrorException";
import { IProduct } from "../product/ProductService";

const api = "favourites";

export interface IFavorite {
  _id: string;
  userId: string;
  productId: IProduct;
  _deletedAt: number;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API = 'favourites';

const createFavourite = async (body: any) => {
  try{
    const { data } = await Api().post(`/${API}`, body)
  } catch (error: any) {
    return ApiException.fromAxiosError(error)
  }
}

export const deleteFavourite = async (id: string) => {
  try{
    const { data } = await Api().delete(`${API}/${id}`);
    return data;
  } catch (error: any) {
    return ApiException.fromAxiosError(error)
  }
}

const findByProductId = async (productId: string) => {
  try {
    const { data } = await Api().get(
      `/${api}/?filters={"productId":"${productId}"}`
    );

    return (data.data.result[0]);
  } catch (error: any) {
    return ApiException.fromAxiosError(error)
  }
};

const deleteOne = async (id: string) => {
  try{
    const { data } = await Api().delete(`/${API}/${id}`);
    return data;
  }catch(error: any){
    return ApiException.fromAxiosError(error)
  }
}

export const FavoriteService = {
  findByProductId,
  createFavourite,
  deleteOne,
};

// export const fetchFavourites = async () => {
//   const token = localStorage.getItem('token');
//   const response = await fetch(`${BASE_URL}/${API}`, {
//     method: 'GET',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//       'Access-Control-Allow-Origin': '*'
//     }
//   });
//   if (!response.ok) throw new Error('Failed to fetch favourites');
//   return response.json();
// };

export const getFavourites = async () => {
  try{
    const { data } = await Api().get(`/${API}`)
    return data
  } catch(error: any){
    return ApiException.fromAxiosError(error)
  }
}
