import { useNavigate } from 'react-router-dom'

import BackButtonIcon from '../../assets/icons/i-back-button-icon.svg'
import { VoltarOption } from './style'

export const BackButton: React.FC<{text:String}> = (props) =>{
    const navigate = useNavigate()
    return (
        <div className="my-4 min-[1025px]:!mt-[60px] min-[1025px]:!mb-[80px]">
            <VoltarOption onClick={() => navigate(-1)}>
                <img
                    src={BackButtonIcon}
                    alt="Botão de voltar"
                />
                <p className="min-[1025px]:hidden text-white">{props.text}</p>
            </VoltarOption>
        </div>
    )
}