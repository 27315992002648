import LogoSara from "../../assets/images/logo-sara.svg"
import LogoSaraWhite from "../../assets/images/sara-logo-white.svg"

export const FormContainer: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <div className="w-full flex items-center justify-center flex-col">
            <img src={LogoSara} alt="logo sara" className="mb-8 hidden w-[365.76px] min-[1025px]:block" />
            <img src={LogoSaraWhite} alt="logo sara" className="mb-[55px] w-[177.32px] min-[1025px]:hidden" />
            {children}
        </div>
    )
}