import React, { useState, useEffect } from 'react';
import { ProductService } from '../../../services/api/product/product-service';
import { isValidArray } from "../../../utils";
import './style.css'; // Ensure your styles are being imported correctly

interface LeafletTopicListFormProps {
  productId: string; // Explicitly typing the productId as a string
}

const LeafletTopicListForm: React.FC<LeafletTopicListFormProps> = ({ productId }) => {
  const [product, setProduct] = useState<any>(null);
  const [faqs, setFaqs] = useState<Array<{ question: string; answer: string; isOpen: boolean }>>([]);
  const [faqWithoutLeaflet, setFaqWithoutLeaflet] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      const productData = await ProductService.findById(productId);
      if (productData) {
        setProduct(productData.data);
        initializeFaqs(productData.data);
      }
    };

    fetchProduct();
  }, [productId]);

  const initializeFaqs = (product: any) => {
    let newFaqs = [];
    const hasTopicLeaflet = isValidArray(product?.leafletId?.topics);
  

    if (hasTopicLeaflet) {
      newFaqs = product.leafletId.topics.map((topic: any) => ({
        question: topic.title,
        answer: topic.content,
        isOpen: false
      }));
    }

    if (product && product.companyId.sacContent) {
      newFaqs.push({
        question: 'Fale com o SAC',
        answer: `mailto:${product.companyId.sacContent}`,
        isOpen: false
      });
    }

    // Filter out the "Fale com o SAC" entry
    newFaqs = newFaqs.filter((faq: { question: string; }) => faq.question !== "Fale com o SAC");

    setFaqs(newFaqs);
    setFaqWithoutLeaflet(!hasTopicLeaflet);
  };

  const toggleFaq = (index: number) => {
    const updatedFaqs = faqs.map((faq, i) => ({
      ...faq,
      isOpen: i === index ? !faq.isOpen : faq.isOpen
    }));
    setFaqs(updatedFaqs);
  };

  return (
    <section>
      <div className="faq-perguntas">
        <div className="faq-menu">
          <div className="faq-conteudo gap-[8px] lg:gap-[8px] ">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`pergunta-conteudo ${faqWithoutLeaflet && index === 0 ? 'faqWithoutLeaflet' : ''}`}
                style={faqWithoutLeaflet && index === 0 ? { padding: '4% 0%', boxShadow: 'none' } : {}}
                onClick={() => toggleFaq(index)}
              >
                <div className="duvidas">
                  <h2>{faq.question}</h2>
                  <svg className={`min-w-[24px] ${faq.isOpen ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 15l-4-4h8l-4 4z"
                      fill="#272727" />
                  </svg>
                </div>
                {faq.isOpen && (
                  <div className="resposta-curso">
                    <ul>
                      <li dangerouslySetInnerHTML={{ __html: faq.answer }}></li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeafletTopicListForm;
