import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './app';
import { MenuProvider } from './app/contexts/Menu.context'; // Importa o MenuProvider que você criou

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <MenuProvider> {/* Envolve o App com o MenuProvider */}
    <App />
  </MenuProvider>
);
