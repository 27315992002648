import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Button, DivButton } from "./style"
import { PasswordlessService, ApiException } from "../../../services"
import { FormBase } from "../../../styles/styled"
import IErrorIcon from "../../../assets/icons/i-error.svg"
import { useLocalStorage, useProduct } from "../../../hooks";

interface ITokenFormProps {
    type?: string;
    name?: string;
    phoneNumber?: string;
    token?: any;
    setToken: (token: string) => void;
    favourite?: boolean;
}

const tokenTime = 60

export const TokenForm: React.FC<ITokenFormProps> = (props) => {
    const {product, getProductURL} = useProduct();
    const { setItem } = useLocalStorage()
    const navigate = useNavigate()
    const [invalidToken, setInvalidToken] = useState(false)
    const [disabledTokenInput, setDisabledTokenInput] = useState(false)
    const [disabledNewTokenRequest, setDisabledNewTokenRequest] = useState(true)
    const [verificationCode, setVerificationCode] = useState('')
    const [timer, setTimer] = useState(tokenTime)

    useEffect(() => {
        if (verificationCode.length == 6) {
            handleLogin()
        }
    }, [verificationCode])

    useEffect(() => {
        if (timer > 0) {
            const id = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);

            return () => clearInterval(id);
        }
        setDisabledNewTokenRequest(false)
    }, [timer])

    const handleLogin = () => {
        if (!props.phoneNumber || !verificationCode || !props.token) return
        setInvalidToken(false)
        if (verificationCode.length != 6) return
        setDisabledTokenInput(true)
        if (props.type === 'login'){
            PasswordlessService.login(props.phoneNumber, verificationCode, props.token)
                    .then((res: any) => {
                        if (res instanceof ApiException) {
                            setInvalidToken(true)
                            setDisabledTokenInput(false)
                            return
                        } else {
                            setItem ('token', res.data.authToken)
                            setItem ('userData', res.data.userData)
                            if (props.favourite && product) {
                                navigate(getProductURL(product))
                                return
                            }
                            navigate('/')
                        }
                    })
        }
        if (props.type === 'signup' && props.name){
            PasswordlessService.register(props.phoneNumber, props.name, verificationCode, props.token)
                    .then((res) => {
                        if (res instanceof ApiException) {
                            setInvalidToken(true)
                            setDisabledTokenInput(false)
                            return
                        } else {
                            setItem ('token', res.data.authToken)
                            setItem ('userData', res.data.userData)
                            if (props.favourite && product){
                                navigate(getProductURL(product))
                                return
                            }
                            navigate ('/')
                        }
                    })
            // enviar ao google cadastro concluído
        }
    }

    const handleCodeRequest = () => {
        // Envio evento ao google
        if (!disabledNewTokenRequest && props.phoneNumber) {
            setDisabledNewTokenRequest(true)
            setTimer(tokenTime) //solicitar novo timer

            PasswordlessService.sendVerification(props.phoneNumber, 'resend')
                .then((res) => {props.setToken(res.data)})
        }
    }

    return (
        <div className="py-4 px-2 bg-white rounded-3xl flex-col justify-center items-center inline-flex gap-y-6 w-[350px] mb-8">
            <div className="self-stretch text-center text-neutral-800 text-2x1 font-bold font-['Nunito']">
                Insira o Token
            </div>

            <div className="self-stretch text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                Enviamos um <strong>TOKEN</strong> via <strong>SMS</strong> no
                telefone cadastrado para confirmação dos dados.
            </div>

            <FormBase>
                <div>
                    <input
                        className="text-content token-content text-center font-bold"
                        type="text"
                        disabled={disabledTokenInput}
                        value={verificationCode}
                        required
                        autoFocus
                        placeholder="XXXXXX"
                        maxLength={6}
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                </div>

                {invalidToken && (
                    <div className="text-xs font-normal text-[#9E9E9E] mt-2 flex">
                        <div>
                            <img src={IErrorIcon} alt="ícone de erro" className="float-left pr-3" />
                        </div>
                        <div>
                            O token inserido é inválido. Por favor, verifique o token e
                            tente novamente
                        </div>
                    </div>
                )}
            </FormBase>

            <div className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                Não Recebeu ainda? Tente:
            </div>
            <div className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                <span>
                    {disabledNewTokenRequest ? ("Aguarde " + timer + " segundos para solicitar um novo token.") : "Solicite um novo token para ser enviado para o seu número de celular."}
                </span>
            </div>
            <Button onClick={handleCodeRequest} isDisabled={disabledNewTokenRequest} >
                <DivButton isDisabled={disabledNewTokenRequest}>
                    Reenviar TOKEN
                </DivButton>
            </Button>
        </div>
    )
} 