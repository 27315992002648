import { Link } from 'react-router-dom';
import './style.css'

interface IFooterProps {
    logoSara: string;
    siteUrl: string;
    showSideNav: boolean;
    navigate: (path: string) => void;
}

export const Footer: React.FC<IFooterProps> = (props) => {
    return (
        <>
        {/* footer desktop */}
           {props.showSideNav && (<footer className="footer-container mt-auto hidden md:flex" >
                <section id="footer">
                    <div className="content-footer">
                        <div className="hidden md:flex min-w-normal w-normal dynamic-width"></div>
                        <div className="description-footer">
                            <img src={props.logoSara} alt="logo sara" className="logo-footer" />
                            <p>
                                Somos uma plataforma que te ajuda a cuidar da sua saúde e de quem você ama, através de acesso à informação sobre seus medicamentos e auxílio na adesão ao tratamento com a qualidade e leveza que um bom cuidador oferece.
                            </p>
                            <p className="copy-footer">copyright 2023 - todos os direitos reservados</p>
                        </div>

                        <div className="min-w-[400px] grid grid-cols-2 gap-[36px] lg:min-w-[452px] min-[1025px]:grid-cols-4 min-[1025px]:min-w-[720px]">
                            <div className="col-lg-2 m-0 links-footer border-ice">
                                <div className="status-page-bar"></div>
                                <span className="header-footer-link">Produtos </span>
                                <Link to='/busca-de-produtos' data-cy="link-buscar-produtos">Buscar Produtos</Link>
                                <Link to='/busca-de-produtos' data-cy="link-verificar-recall">Verificar Recall</Link>
                                <Link to='/catalogo-de-produtos' data-cy="link-catalogo-produtos">Catálogo de Produtos</Link>
                                <a href={`${props.siteUrl}/noticias/med/mapa-do-blog.sdex`} data-cy="link-mapa-blog">Mapa do Blog</a>
                            </div>
                            <div className="col-lg-2 m-0 links-footer border-ice">
                                <div className="status-page-bar"></div>
                                <span className="header-footer-link">Lembretes </span>

                                <Link to='/lembretes' data-cy="link-meus-lembretes">Meus Lembretes</Link>
                                <Link to='/lembretes?action=createReminder' data-cy="link-criar-novo-lembrete">Criar novo Lembrete</Link>
                            </div>
                            <div className="col-lg-2 m-0 links-footer border-ice">
                                <div className="status-page-bar"></div>
                                <span className="header-footer-link">Favoritos </span>
                                <Link to='/favoritos' data-cy="link-meus-favoritos">Meus Favoritos</Link>
                            </div>
                            <div className="col-lg-2 m-0 links-footer border-ice">
                                <div className="status-page-bar"></div>
                                <span className="header-footer-link">Perfil </span>

                                <Link to='/perfil' data-cy="link-meus-dados">Meus Dados</Link>
                                <Link to='/perfil' data-cy="link-configuracoes-conta">Configurações da Conta</Link>
                                <Link to='/termos' data-cy="link-termos-condicoes">Termos e Condições de Uso</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>)}
            
            {/* footer mobile */}
            
            {props.showSideNav && (
                <footer className="w-full px-2 py-6 bg-white shadow-xl flex-col justify-start items-center inline-flex md:hidden">
                    <div className="self-stretch py-2 flex-col justify-start items-start gap-2 flex">
                        <img src={props.logoSara} alt="logo sara" />

                        <div className="text-teal-400 text-sm font-normal font-['Nunito'] leading-normal">
                            copyright 2023 - todos os direitos reservados
                        </div>
                    </div>

                    <div className="self-stretch flex-col justify-start items-center gap-6 flex">
                        <div className="self-stretch justify-start items-start gap-4 inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                            <div className="breakline"></div>
                                <div className="self-stretch h-7 flex-col justify-start items-start gap-2 flex">
                                    <Link to="/produto" className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight" data-cy="mobile-produtos-header">
                                        Produtos
                                    </Link>
                                </div>
                                <Link to="/busca-de-produtos" className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-buscar-produtos">
                                    Buscar Produtos
                                </Link>
                                <Link to="/busca-de-produtos" className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-verificar-recall">
                                    Verificar Recall
                                </Link>
                                <Link to="/catalogo-de-produtos" className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-catalogo-produtos">
                                    Catálogo de Produtos
                                </Link>
                                <a href={`${props.siteUrl}/noticias/med/mapa-do-blog.sdex`} className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-mapa-blog">
                                    Mapa do Blog
                                </a>
                            </div>
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                            <div className="breakline"></div>                                <div className="self-stretch h-7 flex-col justify-start items-start gap-2 flex">
                                    <Link to='/lembretes' className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight" data-cy="mobile-lembretes-header">
                                        Lembretes
                                    </Link>
                                </div>
                                <Link to='/lembretes' className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-meus-lembretes">
                                    Meus Lembretes
                                </Link>
                                <Link to='/lembretes?action=createReminder' className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-criar-novo-lembrete">
                                    Criar novo Lembrete
                                </Link>
                            </div>
                        </div>
                        <div className="self-stretch justify-start items-start gap-4 inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                                <div className="self-stretch h-7 flex-col justify-start items-start gap-2 flex">
                                <div className="breakline"></div>                                    
                                <Link to='/perfil' className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight" data-cy="mobile-favoritos-header">
                                        Favoritos
                                    </Link>
                                </div>
                                <Link to="/favoritos" className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-meus-favoritos">
                                    Meus Favoritos
                                </Link>
                            </div>
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                                <div className="self-stretch h-7 flex-col justify-start items-start gap-2 flex">
                                <div className="breakline"></div>                                    
                                <Link to='/perfil' className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight" data-cy="mobile-perfil-header">
                                        Perfil
                                    </Link>
                                </div>
                                <Link to='/perfil' className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-meus-dados">
                                    Meus Dados
                                </Link>
                                <Link to='/perfil' className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-configuracoes-conta">
                                    Configurações da Conta
                                </Link>
                                <Link to="/termos" className="text-neutral-800 text-xs font-normal font-['Nunito'] leading-none" data-cy="mobile-termos-condicoes">
                                    Termos e Condições de Uso
                                </Link>
                            </div>
                        </div>
                    </div>
                </footer >
            )}
        </>
    )
}