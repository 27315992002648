import { Link, useLocation, useNavigate } from "react-router-dom"
import { FormBase } from "./style"
import InputMask from "react-input-mask"
import { useEffect, useState } from "react"
import { useLocalStorage, useProduct } from "../../../hooks"
import { PasswordlessService, ApiException } from "../../../services"


export const LoginForm = () => {
    const { setItem, removeData, getData } = useLocalStorage()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const favourite = queryParams.get('favourite') ? true : false
    const [phoneNumber, setPhoneNumber] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (getData('token')) removeData('token')
        if (getData('userData')) removeData('userData')
    }, [])

    const handleLogin = () => {
        if (!phoneNumber || phoneNumber.length !== 11) return
        PasswordlessService.sendVerification(phoneNumber, 'login', '')
            .then((res) => {
                if (res instanceof ApiException) {
                    if (favourite){
                        navigate(`/cadastro?phoneNumber=${phoneNumber}&favourite=true`);
                        return
                    }
                    navigate(`/cadastro?phoneNumber=${phoneNumber}`)
                } else {
                    setItem('token', res.data)
                    const params = new URLSearchParams({
                        phoneNumber: phoneNumber,
                        type: 'login',
                        favourite: 'true'
                    })
                    navigate(`/token?${params.toString()}`)
                }
            })
    };

    return (
        <div className="w-full justify-center items-center flex">
            <div className="py-4 px-2 bg-white rounded-3xl flex-col justify-center items-center inline-flex gap-y-6 w-[350px] mb-8">
                <div className="self-stretch text-center min-[1025px]:!text-left">
                    <div className="text-neutral-800 text-2xl font-bold font-['Nunito'] mb-2">
                        Bem-Vindo de volta!
                    </div>
                    <div className="text-neutral-800 text-lg font-medium font-['Nunito'] leading-7">
                        Sua saúde, Nossa Prioridade
                    </div>
                </div>

                <div className="self-stretch text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                    Informe seu telefone para entrar na nossa plataforma:
                </div>

                <FormBase>
                    <div>
                        <p>Telefone cadastrado</p>
                        <InputMask
                            type="text"
                            className="text-content"
                            required
                            placeholder="(xx) xxxxx-xxxx"
                            value={phoneNumber}
                            onChange={(e) => { setPhoneNumber(e.target.value.replace(/\D/g, '').toString()) }}
                            mask="(99) 99999-9999"
                        />
                    </div>
                </FormBase>

                <button
                    onClick={handleLogin}
                    disabled={phoneNumber.length < 11}
                    className="w-56 h-12 px-6 py-3.5 bg-yellow-500 rounded-full shadow-xl justify-center items-center gap-2 inline-flex cursor-pointer"
                >
                    <div className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight">
                        Entrar
                    </div>
                </button>

                <div className="self-stretch text-center text-neutral-800 text-base font-normal font-['Nunito'] leading-tight">
                    Ainda não tem uma conta?
                </div>

                <Link
                    to="/cadastro"
                    className="w-56 h-12 px-6 py-3.5 rounded-full border-[1px] border-black justify-center items-center gap-2 inline-flex"
                >
                    <div
                        className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight"
                    >
                        Cadastre-se
                    </div>
                </Link>

            </div>
        </div>
    )
}