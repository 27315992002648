import { useCallback } from "react"
import { ScrollTop } from "./style"
import IArrowTop from "../../../assets/icons/i-arrow-top.svg"

export const TermsText = () => {
    const scroll = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <div className="w-full bg-white shadow-xl p-[36px] rounded-[24px]" >
            <h1
                className="font-[Nunito] text-center font-bold text-xl m-2 text=[#272727] sm:text-4xl"
            >
                Termos e Condições de Uso
            </h1>
            <span className="block font-[Nunito] text-center font-medium leading-relaxed text-lg text-[#272727] mb-2" >Criação de Conta na Plataforma Sara </span
            >
            <p
                className="font-[Nunito] font-medium leading-relaxed text-lg text-[#272727] mb-2 break-words"
            >
                É política da Sara respeitar a sua privacidade em relação a qualquer
                informação sua que possamos coletar e tratar no site Sara.
                <br />
                <br />
                Solicitamos informações pessoais apenas quando realmente precisamos
                delas para lhe fornecer um serviço. Faze-lo por meios justos e legais,
                com o seu conhecimento e consentimento. Também informamos por que
                estamos coletando e como será usado, bem como acesso, divulgação,
                cópia, uso ou modificação não autorizados.
                <br />
                <br />
                Não compartilhamos informações de identificação pessoal publicamente
                ou com terceiros, exceto quando exigido por lei. Nosso site pode ter
                links para sites externos que não são operados por nós. Esteja ciente
                de que não temos controle sobre o conteúdo e práticas desses sites e
                não podemos aceitar responsabilidade por suas respectivas políticas de
                privacidade. Você é livre para recusar a nossa solicitação de
                informações pessoais, entendendo que talvez não possamos fornecer
                alguns dos serviços desejados.
                <br />
                <br />
                A aceitação/validação deste termo implica na aceitação de tratamento
                de dados e informações pessoais. Se você tiver alguma dúvida sobre
                como lidamos com dados do usuário e informações pessoais, entre em
                contato pelo email privacidade.dados&#64;gruponc.net.br.
                <br />
                <br />
                <u>
                    <b>Coleta e Processamento de Dados</b>
                </u>
                <br />
                <br />
                <b>Criar Lembrete</b>
                <br />
                Solicitamos ao usuário o preenchimento dos campos "Telefone para
                contato" e "Nome completo" para enviar uma mensagem via SMS ou
                WhatsApp lembrando o mesmo do horário de tomada do medicamento
                cadastrado.
                <br />
                <br />
                <b>Rastreabilidade</b>
                <br />
                Solicitamos ao usuário o preenchimento dos campos "Lote do
                medicamento", "Telefone para contato" e "Nome completo"para a
                rastreabilidade do medicamento em caso de recall.
                <br />
                <br />
                <b>Cadastre-se aqui</b>
                <br />
                Solicitamos ao usuário o preenchimento dos campos "Telefone para
                contato" e "Nome completo" para envio da chave token obrigatória para
                o login na aplicação.
                <br />
                <br />
                <u id="politica" className="pt-7">
                    <b>Política de Cookies Sara</b>
                </u>
                <br />
                <br />
                <b>O que são cookies?</b>
                <br />
                Como é prática comum em quase todos os sites profissionais, este site
                usa cookies, que são pequenos arquivos baixados no seu computador,
                para melhorar sua experiência. Esta página descreve quais informações
                eles coletam, como as usamos e por que às vezes precisamos armazenar
                esses cookies. Também compartilharemos como você pode impedir que
                esses cookies sejam armazenados, no entanto, isso pode fazer o
                downgrade ou 'quebrar' certos elementos da funcionalidade do site.
                <br />
                <br />
                <b>Como usamos os cookies?</b>
                <br />
                Utilizamos cookies por vários motivos, detalhados abaixo. A maioria
                dos cookies são utilizados para possibilitar a funcionalidade de
                diversos recursos da aplicação, não podendo ser desativados . É
                recomendável que você deixe todos os cookies se não tiver certeza se
                precisa ou não deles, caso sejam usados para fornecer um serviço que
                você usa.
                <br />
                <br />
                <b>Desativar cookies</b>
                <br />
                Você pode impedir a configuração de cookies ajustando as configurações
                do seu navegador (consulte a Ajuda do navegador para saber como fazer
                isso). Esteja ciente de que a desativação de cookies afetará a
                funcionalidade deste e de muitos outros sites que você visita. A
                desativação de cookies geralmente resultará na desativação de
                determinadas funcionalidades e recursos deste site. Portanto, é
                recomendável que você não desative os cookies.
                <br />
                <br />
                <b>Cookies que definimos:</b>
                <br />
                <br />
                <b>&#9679; Cookies relacionados à conta</b>
                <br />
                Se você criar uma conta conosco, usaremos cookies para o gerenciamento
                do processo de inscrição e administração geral. Esses cookies
                geralmente serão excluídos quando você sair do sistema, porém, em
                alguns casos, eles poderão permanecer posteriormente para lembrar as
                preferências do seu site ao sair.
                <br />
                <br />
                <b>&#9679; Cookies relacionados ao login</b>
                <br />
                Utilizamos cookies quando você está logado, para que possamos lembrar
                dessa ação. Isso evita que você precise fazer login sempre que visitar
                uma nova página. Esses cookies são normalmente removidos ou limpos
                quando você efetua logout para garantir que você possa acessar apenas
                a recursos e áreas restritas ao efetuar login.
                <br />
                <br />
                <b>&#9679; Cookies relacionados a boletins por e-mail</b>
                <br />
                Este site oferece serviços de assinatura de boletim informativo ou
                e-mail e os cookies podem ser usados para lembrar se você já está
                registrado e se deve mostrar determinadas notificações válidas apenas
                para usuários inscritos/não inscritos.
                <br />
                <br />
                <b>&#9679; Pedidos processando cookies relacionados</b>
                <br />
                Este site oferece facilidades de comércio eletrônico ou pagamento e
                alguns cookies são essenciais para garantir que seu pedido seja
                lembrado entre as páginas, para que possamos processá-lo
                adequadamente.
                <br />
                <br />
                <b>&#9679; Cookies relacionados a pesquisas</b>
                <br />
                Periodicamente, oferecemos pesquisas e questionários para fornecer
                informações interessantes, ferramentas úteis ou para entender nossa
                base de usuários com mais precisão. Essas pesquisas podem usar cookies
                para lembrar quem já participou numa pesquisa ou para fornecer
                resultados precisos após a alteração das páginas.
                <br />
                <br />
                <b>&#9679; Cookies relacionados a formulários</b>
                <br />
                Quando você envia dados por meio de um formulário como os encontrados
                nas páginas de contato ou nos formulários de comentários, os cookies
                podem ser configurados para lembrar os detalhes do usuário para
                correspondência futura.
                <br />
                <br />
                <b>&#9679; Cookies de preferências do site</b>
                <br />
                Para proporcionar uma ótima experiência neste site, fornecemos a
                funcionalidade para definir suas preferências de como esse site é
                executado quando você o usa. Para lembrar suas preferências,
                precisamos definir cookies para que essas informações possam ser
                chamadas sempre que você interagir com uma página for afetada por suas
                preferências.
                <br />
                <br />
                <b>Cookies de Terceiros</b>
                <br />
                Em alguns casos especiais, também usamos cookies fornecidos por
                terceiros confiáveis. A seção a seguir detalha quais cookies de
                terceiros você pode encontrar através deste site.
                <br />
                <br />
                &#9679; Este site usa o Google Analytics, que é uma das soluções de
                análise mais difundidas e confiáveis da Web, para nos ajudar a
                entender como você usa o site e como podemos melhorar sua experiência.
                Esses cookies podem rastrear itens como quanto tempo você gasta no
                site e as páginas visitadas, para que possamos continuar produzindo
                conteúdo atraente.
                <br />
                Para mais informações sobre cookies do Google Analytics, consulte a
                página oficial do Google Analytics.
                <br />
                <br />
                &#9679; As análises de terceiros são usadas para rastrear e medir o
                uso deste site, para que possamos continuar produzindo conteúdo
                atrativo. Esses cookies podem rastrear itens como o tempo que você
                passa no site ou as páginas visitadas, o que nos ajuda a entender como
                podemos melhorar o site para você.
                <br />
                <br />
                &#9679; Periodicamente, testamos novos recursos e fazemos alterações
                sutis na maneira como o site se apresenta. Quando ainda estamos
                testando novos recursos, esses cookies podem ser usados para garantir
                que você receba uma experiência consistente enquanto estiver no site,
                enquanto entendemos quais otimizações os nossos usuários mais
                apreciam.
                <br />
                <br />
                &#9679; À medida que vendemos produtos, é importante entendermos as
                estatísticas sobre quantos visitantes de nosso site realmente compram
                e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso
                é importante para você, pois significa que podemos fazer previsões de
                negócios com precisão que nos permitem analisar nossos custos de
                publicidade e produtos para garantir o melhor preço possível.
                <br />
                <br />
                &#9679; O serviço Google AdSense que usamos para veicular publicidade
                usa um cookie DoubleClick para veicular anúncios mais relevantes em
                toda a Web e limitar o número de vezes que um determinado anúncio é
                exibido para você.
                <br />
                Para mais informações sobre o Google AdSense, consulte as FAQs
                oficiais sobre privacidade do Google AdSense.
                <br />
                <br />
                &#9679; Utilizamos anúncios para compensar os custos de funcionamento
                deste site e fornecer financiamento para futuros desenvolvimentos. Os
                cookies de publicidade comportamental usados por este site foram
                projetados para garantir que você forneça os anúncios mais relevantes
                sempre que possível, rastreando anonimamente seus interesses e
                apresentando coisas semelhantes que possam ser do seu interesse.
                <br />
                <br />
                &#9679; Vários parceiros anunciam em nosso nome e os cookies de
                rastreamento de afiliados simplesmente nos permitem ver se nossos
                clientes acessaram o site através de um dos sites de nossos parceiros,
                para que possamos creditá-los adequadamente e, quando aplicável,
                permitir que nossos parceiros afiliados ofereçam qualquer promoção que
                pode fornecê-lo para fazer uma compra.
                <br />
                <br />
                <u>
                    <b>Compromisso do Usuário</b>
                </u>
                <br />
                O usuário se compromete a fazer uso adequado dos conteúdos e da
                informação que o Sara oferece no site e com caráter enunciativo, mas
                não limitativo:
                <br />
                <br />
                <b>A&#41;</b>
                Não se envolver em atividades que sejam ilegais ou contrárias à boa fé
                a à ordem pública;
                <br />
                <b>B&#41;</b>
                Não difundir propaganda ou conteúdo de natureza racista, xenofóbica,
                ou azar, qualquer tipo de pornografia ilegal, de apologia ao
                terrorismo ou contra os direitos humanos;
                <br />
                <b>C&#41;</b>
                Não causar danos aos sistemas físicos (hardwares) e lógicos
                (softwares) do Sara, de seus fornecedores ou terceiros, para
                introduzir ou disseminar vírus informáticos ou quaisquer outros
                sistemas de hardware ou software que sejam capazes de causar danos
                anteriormente mencionados.
                <br />
                <br />
                <b>Mais informações</b>
                <br />
                Esperemos que esteja esclarecido e, como mencionado anteriormente, se
                houver algo que você não tem certeza se precisa ou não, geralmente é
                mais seguro deixar os cookies ativados, caso interaja com um dos
                recursos que você usa em nosso site.
                <br />
                Esta política é efetiva a partir de <b>Abril/2022</b> <br />
                <br />
                <u>
                    <b>1. Termos</b>
                </u>
                <br />
                Ao acessar o site Sara, concorda em cumprir estes termos de serviço,
                todas as leis e regulamentos aplicáveis e concorda que é responsável
                pelo cumprimento de todas as leis locais aplicáveis. Se você não
                concordar com algum desses termos, está proibido de usar ou acessar
                este site. Os materiais contidos neste site são protegidos pelas leis
                de direitos autorais e marcas comerciais aplicáveis.
                <br />
                <br />
                <u>
                    <b>2. Uso de Licença</b>
                </u>
                <br />
                É concedida permissão para baixar temporariamente uma cópia dos
                materiais (informações ou software) no site Sara, apenas para
                visualização transitória pessoal e não comercial. Esta é a concessão
                de uma licença, não uma transferência de título e, sob esta licença,
                você não pode:
                <br />
                <br />
                <b>1&#41;</b> modificar ou copiar os materiais;
                <br />
                <b>2&#41;</b> usar os materiais para qualquer finalidade comercial ou
                para exibição pública (comercial ou não comercial);
                <br />
                <b>3&#41;</b> tentar descompilar ou fazer engenharia reversa de
                qualquer software contido no site Sara;
                <br />
                <b>4&#41;</b> remover quaisquer direitos autorais ou outras notações
                de propriedade dos materiais; ou
                <br />
                <b>5&#41;</b> transferir os materiais para outra pessoa ou espelhe os
                materiais em qualquer outro servidor. <br />
                <br />
                Esta licença será automaticamente rescindida se você violar alguma
                dessas restrições e poderá ser rescindida por Sara a qualquer momento.
                Ao encerrar a visualização desses materiais ou após o término desta
                licença, você deve apagar todos os materiais baixados em sua posse,
                seja em formato eletrônico ou impresso.
                <br />
                <br />
                <u>
                    <b>3. Isenção de Responsabilidade</b>
                </u>
                <br />
                <br />
                <b>1&#41;</b> Os materiais no site da Sara são fornecidos como estão.
                Sara não oferece garantias, expressas ou implícitas, e, por este meio,
                isenta e nega todas as outras garantias, incluindo, sem limitação,
                garantias implícitas ou condições de comercialização, adequação a um
                fim específico ou não violação de propriedade intelectual ou outra
                violação de direitos.
                <br />
                <b>2&#41;</b> Além disso, a Sara não garante ou faz qualquer
                representação relativa à precisão, aos resultados prováveis ou à
                confiabilidade do uso dos materiais em seu site ou de outra forma
                relacionado a esses materiais ou em sites vinculados a este site.
                <br />
                <br />
                <u>
                    <b>4. Limitações</b>
                </u>
                <br />
                Em nenhum caso a Sara ou seus fornecedores serão responsáveis por
                quaisquer danos (incluindo, sem limitação, danos por perda de dados ou
                lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
                incapacidade de usar os materiais em Sara, mesmo que Sara ou um
                representante autorizado da Sara tenha sido notificado oralmente ou
                por escrito da possibilidade de tais danos. Como algumas jurisdições
                não permitem limitações em garantias implícitas, ou limitações de
                responsabilidade por danos consequentes ou incidentais, essas
                limitações podem não se aplicar a você.
                <br />
                <br />
                <u>
                    <b>5. Precisão dos materiais</b>
                </u>
                <br />
                Os materiais exibidos no site da Sara podem incluir erros técnicos,
                tipográficos ou fotográficos. Sara não garante que qualquer material
                em seu site seja preciso, completo ou atual. Sara pode fazer
                alterações nos materiais contidos em seu site a qualquer momento, sem
                aviso prévio. No entanto, Sara não se compromete a atualizar os
                materiais.
                <br />
                <br />
                <u>
                    <b>6. Links</b>
                </u>
                <br />
                A Sara não analisou todos os sites vinculados ao seu site e não é
                responsável pelo conteúdo de nenhum site vinculado. A inclusão de
                qualquer link não implica endosso por Sara do site. O uso de qualquer
                site vinculado é por conta e risco do usuário.
                <br />
                <br />
                <u>
                    <b>Modificações</b>
                </u>
                <br />
                A Sara pode revisar estes termos de serviço do site a qualquer
                momento, porém qualquer modificação na política de privacidade será
                notificada ao usuário, com a coleta do consentimento do mesmo
                novamente nos termos atualizados. Ao usar este site, você concorda em
                ficar vinculado à versão atualizada desses termos de serviço.
                <br />
                <br />
                Qualquer modificação na política de privacidade é obrigatório a
                notificação ao usuário, e a coleta do consentimento dele novamente nos
                termos atualizados.
            </p>
            <ScrollTop onClick={scroll} id="scrollTop" >
            <img src={IArrowTop} alt="Ícone de seta para cima" /> Voltar ao
            topo</ScrollTop>
        
      </div >
    )
}