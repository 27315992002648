// src/App.tsx
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import {
  ProductProvider,
  ThemeSelectorProvider,
  ScreenContextProvider,
  SearchProvider,
  IsBusyProvider,
} from "./contexts";
import "./assets/fonts/Nunito/font.css";
import { router } from "./routes";
import ReactGA from "react-ga";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";

const trackingCodeGA = process.env.REACT_APP_TRACKING_CODE_GA || "";
const tagManagerCode = process.env.REACT_APP_GTM_CODE || "";

export const App = () => {
  ReactGA.initialize(trackingCodeGA);
  TagManager.initialize({ gtmId: tagManagerCode });

  useEffect(() => {
    console.log("DD_VERSION:", process.env.REACT_APP_DD_VERSION);

    // audima acessibility snippet
    const acessibilityScript = document.createElement("script");
    acessibilityScript.src = "https://menu.audima.co/accessibility.js";
    acessibilityScript.defer = true;
    document.body.appendChild(acessibilityScript);

    const widgetScript = document.createElement("script");
    widgetScript.src = "https://audio7.audima.co/audima-widget.js";
    widgetScript.defer = true;
    document.body.appendChild(widgetScript);
  }, []);

  return (
    <div className="w-full bg-[#f0f5f6]">
      <ThemeSelectorProvider>
        <ScreenContextProvider>
          <ProductProvider>
            <SearchProvider>
              <IsBusyProvider>
                <RouterProvider router={router} />
              </IsBusyProvider>
            </SearchProvider>
          </ProductProvider>
        </ScreenContextProvider>
      </ThemeSelectorProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      {/* Audima */}
      <div id="11y-accessibility"></div>
      <div id="audimaWidget"></div>
    </div>
  );
};
