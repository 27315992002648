import { useState, useEffect } from 'react';
import * as Yup from 'yup'
import { ReminderService } from '../../services/api/reminder/ReminderService';
import { LocalStorageService, useGA, useIsBusy, useLocalStorage } from '../../hooks';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import "./index.css"
import { IAction, IIngestionArray, IProduct, IReminder, IReminderPage } from '../../types';
import { NotLogged } from './components/NotLogged';
import { Logged } from './components/Logged';
import { ModalEditReminder } from './components/ModalEditReminder';
import { CreateReminder } from './components/CreateReminder';
import { ApiException, ProductService } from '../../services';
import useDebounce from '../../hooks/UseDebounce';
import { ToastUtil, differenceInDays } from '../../utils';
import IEditReminderBgBlue from '../../assets/icons/i-edit-reminder-bg-blue.svg';
import ITrashBgRed from '../../assets/icons/i-trash-bg-red.svg'
import { findById } from '../../services/api/product/product-service';
import { DocumentTitle } from '../../components';

const ValidationSchema = Yup.object().shape({
  productName: Yup.string().required('Campo obrigatório'),
  startTimestamps: Yup.date().nonNullable().required('Campo obrigatório'),
  finishTimestamp: Yup.date().nullable(),
  ingestionArray: Yup.array().of(Yup.string().required('Hora é obrigatória')).required('pelo menos um horário obrigatório')
})

interface ReminderViewModel {
  listProducts: any[],
  listReminders: IReminderPage[],
  isLoggedIn: boolean,
  isCreateReminder?: boolean,
  isUpdateReminder?: boolean,
  id: string,
}

interface LoaderParams {
  id: string;
  action: string;
}

function getProduct(reminder: IReminderPage[]) {
  let listProducts: any = [];
  reminder.map((item) => {
    const obj = {
      ...item.productId,
      reminderId: item._id,
      reminder: {
        _id: item._id,
        userId: item.userId,
        startTimestamps: item.startTimestamps,
        ingestionArray: item.ingestionArray,
        finishTimestamp: item.finishTimestamp,
        durationInDays: item.durationInDays,
        hoursToRepeat: item.hoursToRepeat,
        gmt: item.gmt,
        productId: item.productId,
      },
    };
    listProducts.push(obj);
  });
  return listProducts
}

async function getReminder(id: String) {
  const today: number = new Date().setHours(0, 0, 0, 0);
  const FILTERS = ``;
  const { data } = await ReminderService.findAll(FILTERS);
  const sortExpiredReminder = (a: any, b: any) => {
    if (
      a.finishTimestamp * 1000 < today &&
      b.finishTimestamp * 1000 >= today
    ) {
      return 1;
    } else if (
      a.finishTimestamp * 1000 >= today &&
      b.finishTimestamp * 1000 < today
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  const listReminders: IReminderPage[] = data.result
    // .map((reminder: any) => {
    //   let startTimestamps = new Date(
    //     reminder.startTimestamps.sort()[0] * 1000
    //   ).setHours(0, 0, 0, 0);
    //   if (startTimestamps < today) startTimestamps = today;
    //   return {
    //     ...reminder,
    //     startTimestamps,
    //   };
    // })
    .sort((a: any, b: any) => a.startTimestamps - b.startTimestamps)
    .sort(sortExpiredReminder);

  const listProducts = getProduct(listReminders)

  return { listReminders, listProducts }
}

export async function LoaderReminder(props: any) {
  const url = new URL(props.request.url);
  const action = url.searchParams.get('action') ? url.searchParams.get('action') : 'random';
  const id = url.searchParams.get('id')
  const { getData } = LocalStorageService();
  const isLoggedIn = getData('token') ? true : false;

  let response;
  let isCreateReminder: boolean;
  let isUpdateReminder: boolean;
  if (action) {
    isCreateReminder = action === 'createReminder';
    isUpdateReminder = action === 'edit';
    response = {
      isCreateReminder,
      isUpdateReminder
    }

    if (isLoggedIn) {
      const { listProducts, listReminders } = await getReminder(id || '')
      response = {
        ...response,
        listProducts,
        listReminders
      }
    }
  }
  response = {
    ...response,
    isLoggedIn,
    id
  }

  return response;
}

export const Lembretes = () => {
  const loaderReminder = useLoaderData() as ReminderViewModel;
  const [isLoading, setIsLoading] = useState(true); //apagar e definir uso no router
  const { getData } = useLocalStorage();
  const { sendGAEvent } = useGA();
  const navigate = useNavigate();
  const location = useLocation();
  const [productId, setProductId] = useState(loaderReminder.id);
  const [isCreateReminder, setIsCreateReminder] = useState(loaderReminder.isCreateReminder ? true : false);
  const [isUpdateReminder, setIsUpdateReminder] = useState(loaderReminder.isUpdateReminder ? true : false);
  const isLoggedIn = loaderReminder.isLoggedIn;
  const listReminders = loaderReminder.listReminders || [{}];
  const listProducts = loaderReminder.listProducts || null;
  const query = new URLSearchParams(location.search)
  const { setIsBusy } = useIsBusy();

  const [page, setPage] = useState(getData('token') ? 'logged' : 'notLogged');
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDeleteReminder, setOpenModalDeleteReminder] = useState(false);
  const [reminderForm, setReminderForm] = useState({} as IIngestionArray);
  const [minDateInitial, setMinDateInitial] = useState('');
  const [reminderId, setReminderId] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [productName, setProductName] = useState<string>();
  const [openModalSearchProduct, setOpenModalSearchProduct] = useState<any>(null);
  const [searchModalListProducts, setSearchModalListProducts] = useState<IProduct[]>();
  const [openModalExistReminder, setOpenModalExistReminder] = useState<any>(null);
  const [modalExistNextReminder, setModalExistNextReminder] = useState<any>(null);
  const [stepsCreateReminder, setStepsCreateReminder] = useState<string>();
  const [dataReminder, setDataReminder] = useState<any>(null)
  const [productPresentation, setProductPresentation] = useState<string>();
  const [productImageUrl, setProductImageUrl] = useState<string>();
  const [brandImageUrl, setBrandImageUrl] = useState<string>();
  const [ingestionArray, setIngestionArray] = useState<any>();
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedSearchTerms = useDebounce(inputValue, 500)
  const [defaultEditForm, setDefaultEditForm] = useState<any>()

  interface EditPayload {
    reminderId: string;
    productId?: string;
    initialDate: string;
    endDate: string | null;
    ingestion: { time: string }[];
  }

  const [actions, setActions] = useState<IAction[]>([{
    type: 'link',
    title: 'Editar Lembrete',
    icon: IEditReminderBgBlue,
    action: (productHandle: IProduct) => handleOpenModalEdit(productHandle.reminder)
  },
  {
    type: 'link',
    title: 'Excluir lembrete',
    icon: ITrashBgRed,
    action: (product: IProduct) => handleOpenModalDelete(product),
  }])

  useEffect(() => {
    if (isLoggedIn && productId && !isCreateReminder && !isUpdateReminder) {
      changePage('createReminder');
      if (productId != '') {
        ProductService.findById(productId)
          .then((res) => {
            if (res instanceof ApiException) {
              console.log(res)
            } else {
              setProduct(res.data)
            }
          })
      }
      addTime();
    }
    if (isLoggedIn && isCreateReminder && !productId && !isUpdateReminder) {
      changePage('createReminder', { action: 'createReminder' });
      addTime();
    }

    if (isLoggedIn && isUpdateReminder) {
      changePage('logged');
      handleOpenModalEdit(listReminders.find((reminder: any) => reminder._id === productId))
    }


    setIsLoading(false);
  }, []);

  useEffect(() => { debounce(debouncedSearchTerms) }, [debouncedSearchTerms])

  const handleOpenModalEdit = (reminder: any) => {
    const reminderEdit = generateEditPayload(reminder);
    const data = {
      productId: reminder.productId._id,
      productName: reminder.productId.productName,
      startTimestamps: reminderEdit.initialDate,
      finishTimestamp: reminderEdit.endDate,
      minDateFinal: new Date(reminder.startTimestamps),
      ingestion: reminderEdit.ingestion
    }
    setDefaultEditForm(data)
    setOpenModalEdit(true);
    setReminderId(reminder._id);
    setProductId(reminder.productId._id);
  }

  const generateEditPayload = (reminder: any) => {
    const dateI = new Date(reminder.startTimestamps[0] * 1000)
    const initialDate = `${dateI.getFullYear().toString()}-${(dateI.getMonth() + 1).toString().padStart(2, '0')}-${dateI.getDate().toString().padStart(2, '0')}`
    const dateF = reminder.finishTimestamp ? new Date(reminder.finishTimestamp * 1000) : null
    const endDate = dateF ? `${dateF.getFullYear().toString()}-${(dateF.getMonth() + 1).toString().padStart(2, '0')}-${dateF.getDate().toString().padStart(2, '0')}` : null
    return {
      reminderId: reminder._id,
      productId: reminder.productId,
      initialDate: initialDate,
      endDate: endDate,
      ingestion: reminder.startTimestamps.map((timestamp: number) => {
        const date = new Date(timestamp * 1000)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;
        return time
      }),
    };
  };

  const handleOpenModalDelete = (product: any) => {
    setReminderId(product.reminderId);
    setIngestionArray(product.ingestionArray);
    setOpenModalDeleteReminder(true);
    setOpenModalEdit(true);
  }

  const closeModalEdit = () => {
    setOpenModalEdit(false);
    setOpenModalDeleteReminder(false);
    navigate(location.pathname)
  }

  const deleteTime = (index: number) => { }

  const editReminderFormSubmit = (values: any) => {
    const newValues = {
      ...values,
      startTimestamps: [Number(new Date(values.startTimestamps))]
    }
    setIsBusy(true)
    ReminderService.editReminder(generateReminder(newValues), reminderId)
      .then((res) => {
        if (res instanceof ApiException) {
          setIsBusy(false)
          console.log(res)
          closeModalEdit();
        } else {
          setIsBusy(false)
          ToastUtil.success('Lembrete salvo com sucesso.', { position: 'bottom-center' })
          closeModalEdit();
        }
      })
  }

  const deleteReminder = () => {
    setIsBusy(true)
    ReminderService.deleteReminder(reminderId)
      .then((res) => {
        if (res instanceof ApiException) {
          setIsBusy(false)
          console.log(res)
        } else {
          setIsBusy(false)
          ToastUtil.success('Lembrete excluído com sucesso.', { position: 'bottom-center' })
          closeModalEdit()
        }
      })
  }

  useEffect(() => {
    selectedProductModal(product)
  }, [product])

  const selectedProductModal = (product: any) => {
    setIsLoading(true)
    if (product && !product.hasReminder) {
      setProduct(product);
      setProductId(product._id);
      setProductName(product.productName)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }, [product, isLoading])

  const setResume = (data: any) => {
    const initial = new Date(data.startTimestamps).getTime();
    let final = data.finishTimestamp
      ? new Date(data.finishTimestamp).getTime()
      : null;
    const diffInDays = final ? differenceInDays(final, initial) + 1 : null
    if (final === null) final = 0;
    setDataReminder({
      ...data,
      productId: productId,
      finishTimestamp: final,
      startTimestamps: [initial],
      duration: diffInDays
        ? `${diffInDays} ${diffInDays > 1 ? 'dias' : 'dia'}`
        : 'Sem data de término',
    })
    setStepsCreateReminder('resume')
  }

  const navigateToReminderEdit = () => { }

  const reminderFormSubmit = () => {
    setIsBusy(true)
    ReminderService.createReminder(generateReminder(dataReminder))
      .then((res) => {
        if (res instanceof ApiException) {
          setIsBusy(false)
          console.log(res)
          ToastUtil.error('Erro ao criar lembrete. Tente novamente mais tarde!', { position: 'top-center' })
        } else {
          setIsBusy(false)
          setStepsCreateReminder('success')
          sendGAEvent('lembrete_sucesso', {
            ean_medicamento: product.ean1
          })
        }
      })
  }

  const generateReminder = (body: any) => {
    const productId = body.productId;
    const gmt = new Date().getTimezoneOffset() / -60;
    console.log(`body ${JSON.stringify(body)}`)
    const startTimestamps = generateStartTimestamp(body);
    const endDate = new Date(body.finishTimestamp).toISOString();
    const durationInDays = 0;
    const hoursToRepeat = 0;
    const finishTimestamp = endDate
      ? Number(new Date(`${endDate.split('T')[0]}T00:00`).setUTCHours(0)) / 1000
      : 0;
    return {
      productId,
      gmt,
      startTimestamps,
      finishTimestamp,
      durationInDays,
      hoursToRepeat,
    };
  }

  const generateStartTimestamp = (values: any) => {
    if (!values.ingestionArray) return [];
    const date = new Date(values.startTimestamps[0]).toISOString();
    const dateOnly = date.split('T')[0];
    return values.ingestionArray.map((timeStamp: { time: any }) => {
      return toTimestamp(`${dateOnly}T${timeStamp}`);
    });
  }

  const toTimestamp = (date: any): number => {
    return Number(new Date(date).getTime() / 1000);
  }

  const changePage = (page: 'notLogged' | 'logged' | 'createReminder', queryParams?: {}) => {
    setPage(page);
    setProduct(null);
    setStepsCreateReminder('create');
    // this.reminderForm.reset();
    // this.ingestionArray.clear();
    setProductName('');
    setProductPresentation('');
    setIsLoading(true)
    setProductImageUrl('');
    setBrandImageUrl('');
    setSearchModalListProducts([])

    if (page === 'createReminder') sendGAEvent('criar_lembrete')

    navigate(`${location.pathname}`, queryParams)
  }

  const addTime = () => { }

  const debounce = (productName: any) => {
    if (productName && productName.length > 3) {
      const uri = `?filters={"productName":"${productName}"}&limit=20&page=0`
      ProductService.findeAll(uri)
        .then((res) => {
          if (res instanceof ApiException) {
            console.log(res)
          } else {
            setSearchModalListProducts(res)
          }
        })
    }
  }

  const onBlur = () => {
    setTimeout(() => {
      setOpenModalSearchProduct(false)
    }, 200);
  }


  return (
    <DocumentTitle
      title="Lembretes"
      description="Ative o lembrete dos seus medicamentos e não perca mais a hora de tomar. Com a nossa funcionalidade você nunca mais irá esquecer o momento de se medicar."
    >
      {/* não logado */}
      <NotLogged isLoading={isLoading} page={page} />
      {/* Page logged and list reminders */}
      <Logged actions={actions} isLoading={isLoading} page={page} listProducts={listProducts} listReminders={listReminders} changePage={changePage} />
      {/* modal edit reminder */}
      <ModalEditReminder
        ValidationSchema={ValidationSchema}
        openModalEdit={openModalEdit}
        isLoading={isLoading}
        openModalDeleteReminder={openModalDeleteReminder}
        ingestionArray={ingestionArray}
        closeModalEdit={closeModalEdit}
        minDateInitial={minDateInitial}
        editReminderFormSubmit={editReminderFormSubmit}
        setOpenModalDeleteReminder={setOpenModalDeleteReminder}
        deleteReminder={deleteReminder}
        reminderId={reminderId}
        defaultEditForm={defaultEditForm}
      />
      {/* Create reminder */}
      <CreateReminder
        page={page}
        isLoading={isLoading}
        ValidationSchema={ValidationSchema}
        openModalSearchProduct={openModalSearchProduct}
        searchModalListProducts={searchModalListProducts}
        selectedProductModal={selectedProductModal}
        deleteTime={deleteTime}
        setResume={setResume}
        openModalExistReminder={openModalExistReminder}
        setOpenModalExistReminder={setOpenModalExistReminder}
        product={product}
        modalExistNextReminder={modalExistNextReminder}
        navigateToReminderEdit={navigateToReminderEdit}
        stepsCreateReminder={stepsCreateReminder || ''}
        dataReminder={dataReminder}
        setStepsCreateReminder={setStepsCreateReminder}
        reminderFormSubmit={reminderFormSubmit}
        changePage={changePage}
        setOpenModalSearchProduct={setOpenModalSearchProduct}
        setInputValue={setInputValue}
        onBlur={onBlur}
        productName={productName || ''}
      />
    </DocumentTitle>
  );
};

