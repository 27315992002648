import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import iPrescription from "../../../assets/icons/i-prescription.svg";
import LeafletTopicListForm from "../LeafletTopicListForm";
import iPdfDownload from "../../../assets/icons/i-pdf-download.svg";
import { GAService, IProduct, ProductService } from "../../../services";
import { isValidArray } from "../../../utils";
import "./style.css";

interface LeafletPresentationFormProps {
  product: IProduct;
}

export const LeafletPresentationForm: React.FC<LeafletPresentationFormProps> = (
  props
) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [hasTopicsLeaflet, setHasTopicsLeaflet] = useState(false);
  const navigate = useNavigate(); // Usar useNavigate para redirecionar

  useEffect(() => {
    const fetchProductData = async () => {
      if (props.product && props.product._id) {
        if (props.product.anvisaCode) {
          const pdfDirectUrl = ProductService.getPdfUrl(
            props.product.anvisaCode
          );
          ProductService.createBlobPdfLink(pdfDirectUrl, setPdfUrl); // Use a função modificada para criar a URL de Blob
        } else {
          console.log("Produto não possui código ANVISA válido.");
        }

        setShowVideo(
          props.product?.ean1?.length > 0 && props.product?.hasLeafletVideo
        );
        setVideoUrl(ProductService.getVideoUrl(props.product?.ean1));
        setHasTopicsLeaflet(isValidArray(props.product.leafletId?.topics));
      } else {
        console.log("Produto não encontrado ou não pertence ao grupo.");
        return (window.location.href = `${process.env.REACT_APP_SITE_URL}/produto-nao-encontrado`);
      }
    };

    fetchProductData();
  }, [props.product, navigate]);

  return (
    <>
      <div className="head-faq mb-[24px] flex-col">
        <div className="head-left w-full px-[16px] mb-[16px] flex-col lg:flex-row">
          <div className="mr-auto ml-auto lg:ml-0">
            <div className="title-head justify-center lg:justify-start">
              <img src={iPrescription} alt="icone bula rápida" />
              <span>Bula</span>
            </div>
            <p>Consulte a bula de forma mais rápida.</p>
          </div>
          {props.product?.hasLeaflet && (
            <a
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                GAService.sendEvent("baixar_bula", {
                  ean_medicamento: props.product.ean1,
                })
              }
              data-cy="baixar-bula"
              className={
                "w-full max-w-[236.52px] bg-[#56AAB7] lg:bg-transparent lg:w-auto px-[16px]" +
                (hasTopicsLeaflet
                  ? "hidden lg:block"
                  : "block mt-[16px] ml-auto mr-auto lg:ml-auto lg:mr-0 lg:mt-0")
              }
            >
              <img
                src={iPdfDownload}
                alt="icon pdf download"
                className="mx-auto"
              />
            </a>
          )}
        </div>

        {showVideo && (
          <div className="video-container px-[16px]" data-cy="video-bula">
            <video
              key={videoUrl}
              width="100%"
              onPlay={() =>
                GAService.sendEvent("clique_video", {
                  ean_medicamento: props.product.ean1,
                })
              }
              controls
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        )}

        <LeafletTopicListForm
          productId={props.product._id}
        ></LeafletTopicListForm>

        {props.product?.hasLeaflet && hasTopicsLeaflet && (
          <a
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={
              "w-full max-w-[236.52px] bg-[#56AAB7] lg:bg-transparent lg:w-auto px-[16px]" +
              (hasTopicsLeaflet ? "block lg:hidden" : "hidden")
            }
            onClick={() =>
              GAService.sendEvent("baixar_bula", {
                ean_medicamento: props.product.ean1,
              })
            }
            data-cy="baixar-bula"
          >
            <img
              src={iPdfDownload}
              alt="icon pdf download"
              className="mx-auto"
            />
          </a>
        )}
      </div>
    </>
  );
};
