import { useEffect, useState } from "react";
import { PeopleService, ApiException } from "../../../services";
import INotificationWhatsappPerfil from "../../../assets/icons/i-notification-whatsapp-perfil.svg"

interface IWhatsappNotificationProps{
    setOpenNotificationModal: (openNotificationModal: boolean) => void;
    receivesWhatsapp: boolean;
    saveWhatsAppNotification: (check: boolean) => void;
}

export const WhatsappNotification: React.FC<IWhatsappNotificationProps> = (props) => {
    const handleCheck = (check: boolean) => {
        if (check) props.saveWhatsAppNotification(check)

        if (!check){
            props.setOpenNotificationModal(true);
        }
    }

    return (
        <div className="cards-actions rounded-[24px] px-2 py-6 bg-white">
            <div className="flex justify-start items-center gap-2 mb-2">
                <img
                    src={INotificationWhatsappPerfil}
                    alt="imagem de homem sorrindo ouvindo música"
                />
                <span className="title-perfil text-2xl font-bold">Notificações</span>
            </div>
            <div className="checkbox-perfil items-start gap-2">
                <div id="whatsappForm flex">
                    <input
                        id="checkbox-whatsapp"
                        type="checkbox"
                        name="receivesWhatsapp"
                        checked={props.receivesWhatsapp}
                        data-cy="input-whatsapp-perfil"
                        onChange={(e) => handleCheck(e.target.checked)}
                    />
                    <label className="text-content-perfil">
                        Gostaria de Receber lembretes da Sara via WhatsApp?
                    </label>
                </div>
            </div>
        </div>
    )
}