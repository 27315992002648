import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IAction } from '../../types';
import { TimePresentation } from './components/TimePresentation';
import { ProductImage } from '../ProductImage';
import { BrandImage } from '../BrandImage';

// TypeScript interfaces for the component props and the product type
interface Product {
    id: string;
    productName: string;
    productPresentation?: string;
    companyName?: string;
    ean1: string;
    companyId: {
        uniqueId: string;
    };
}

interface ProductPresentationProps {
    products: any[];
    variant?: 'card' | 'list';
    actionCard?: (product: Product) => void;
    onProductClick: (product: Product) => void;
    actions?: IAction[];
    width?: string;
    productClassName?: string;
}

const ProductPresentation: React.FC<ProductPresentationProps> = ({
    products,
    variant = 'card',
    onProductClick,
    actions,
    width = '',
    productClassName = ''
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isNoBoxShadowPage = location.pathname.includes('/lembretes');
    const isFavoritePage = location.pathname.includes('/favoritos');
    const isBuscaMobile = location.pathname.includes('/busca-de-produtos');
    const isProductMobile = location.pathname.includes('/produto/');
    const isRecallPage = location.pathname.startsWith('/recall-de-medicamento');
    const today = new Date().getTime();

    const getDynamicClass = (baseClass: string) => {
        if (isProductMobile) return `${baseClass}-produto`;
        if (isNoBoxShadowPage) return `${baseClass}-lembretes`;
        if (isRecallPage) return `${baseClass}-recall`;
        if (isFavoritePage) return `${baseClass}-favoritos`;
        return baseClass;
    };

    const test = (timestampsList:any) => {
        console.log(`test: ${JSON.stringify(timestampsList)}`)
        return 'hi'
    }

    console.log("isRecallPage:", isRecallPage);

    return (
        <div className='py-[24px] px-[16px] content-product-list'>
            <div id="container-product">
                {products.map((product) => (
                    <div
                    onClick={() => isBuscaMobile && onProductClick(product)}
                        key={product.id}
                        className={`card-presentation ${variant} ${products.length > 1 ? 'multiple' : 'single'} ${isFavoritePage ? 'favorite' : ''} ${productClassName} ${isNoBoxShadowPage ? 'no-box-shadow' : ''} ${isFavoritePage ? 'fix-padding-fav' : ''} ${isProductMobile ? 'product-page-style' : ''} ${isRecallPage ? 'recall-page-mobile-specific' : 'mobile-specific'}`}
                    >
                        <div className={getDynamicClass("grid-item-box")} 
                            onClick={() => !isBuscaMobile && onProductClick(product)}>
                            <div
                                className="bg-box absolute test w-[80px] h-[80px] rounded-full opacity-50"
                                style={{
                                    backgroundColor: product.companyId.basePrimaryColorHex || '#1B1A1A66'
                                }}
                            ></div>
                            <ProductImage productName={product.productName} id={product.companyId.uniqueId} />
                        </div>
                        <div className={getDynamicClass("grid-item-names")} onClick={() => !isBuscaMobile && onProductClick(product)}>
                            <h1>
                                <p className="font-bold">{product.productName}</p>
                                <p className="font-normal text-sm">{product.productPresentation}</p>
                                <p className="font-normal text-sm">{product.companyName}</p>
                            </h1>
                            {product?.reminder && (
                                <span className="block text-sm font-semibold">
                                    {(product?.reminder.finishTimestamp && (product?.reminder.finishTimestamp * 1000) < today) ? (
                                        <span className='text-red-600'>
                                            Expirado
                                        </span>
                                    ) : (
                                        <span>
                                            <TimePresentation timesTampList={product?.reminder.startTimestamps} today={today} />
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>

                        {/* Always show the main logo */}
                        
                        {variant != 'list' && (
                            <div className={`grid-item-logo ${getDynamicClass('grid-item-logo')}`}>
                                <BrandImage id={product.companyId.uniqueId} companyName={product.companyName} />
                            </div>
                        )}


                        {/* Conditional rendering of actions and possibly an additional logo */}
                        {actions && actions.length > 0 && variant === 'list' && (
                            <>
                                <div className="actions-and-logo flex flex-col justify-end items-end gap-[8px]">
                                    <BrandImage id={product.companyId.uniqueId} companyName={product.companyName} objectFit='contain' />

                                    <div className='grid-item-logo ml-auto flex'>
                                        {actions.map((action, index) => (
                                            <>
                                                {/* Optional: Uncomment if you need a logo here as well */}
                                                {((product.hasReminder && action.title === 'Lembrete') || action.title != 'Lembrete') && (
                                                    <button
                                                        className="flex justify-center items-center"
                                                        onClick={() => action.action(product)}
                                                    >
                                                        <img
                                                            src={action.icon}
                                                            alt={action.title}
                                                            className="min-w-[40px]"
                                                        />
                                                    </button>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductPresentation;
