import { FormBase } from "../../../styles/styled";
import IError from "../../../assets/icons/i-error.svg"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

interface ITokenFormProps {
    tokenTime: number;
    pageSuccess: boolean;
    setPageSuccess: (value: boolean) => void;
}

export const TokenForm: React.FC<ITokenFormProps> = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [phoneNumber, setPhoneNumber] = useState(queryParams.get('phoneNumber') || '')
    const [name, setName] = useState(queryParams.get('name') || '')
    const [crm, setCrm] = useState(queryParams.get('crm') || '')
    const [ufCrm, setUfCrm] = useState(queryParams.get('ufCrm') || '')
    const [email, setEmail] = useState(queryParams.get('email') || '')
    const [representativeId, setRepresentativeId] = useState(queryParams.get('representativeId') || '')

    const [timer, setTimer] = useState(props.tokenTime)
    const [verificationCode, setVerificationCode] = useState('')
    const [disabledTokenInput, setDisabledTokenInput] = useState(false)
    const [invalidToken, setInvalidToken] = useState(false)
    const [disabledNewTokenRequest, setDisabledNewTokenRequest] = useState(true)
    
    useEffect(() => {
        if (queryParams.size > 0) {
            handleGetToken()
        }
    }, [])
    
    useEffect(() => {
        if (timer > 0) {
            const id = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);

            return () => clearInterval(id);
        }
        setDisabledNewTokenRequest(false)
    }, [timer])

    useEffect(() => {
        setInvalidToken(false)
        if (verificationCode.length != 6) return
        handleSignIn()
    }, [verificationCode])

    const handleCodeRequest = () => {
        //lógica de reenvio de token
        if (disabledNewTokenRequest) return
        setDisabledNewTokenRequest(true)
        setTimer(props.tokenTime)
    }

    const handleGetToken = () => {
        try{
            // sendVerification
        }catch (err: any){
            if (err.message === 'User not found' && phoneNumber){
                const params = new URLSearchParams({
                    phoneNumber: phoneNumber
                })
                navigate(`/cadastro-medico?${params.toString()}`)
            }
        }
    }

    const handleSignIn = () => {
        try {
            setDisabledTokenInput(true)
            // lógica de signupDoctor
            // Envio de evento ao google
            props.setPageSuccess(true)
        } catch {
            setInvalidToken(true)
            setDisabledTokenInput(false);
        }
    }

    return (
        <>
            <div className="p-[16px] bg-white rounded-3xl flex-col justify-center items-center inline-flex gap-y-6 w-[350px] mb-8">
                <div className="self-stretch text-center text-neutral-800 text-2xl font-bold">
                    Insira o Token
                </div>
                <div className="self-stretch text-neutral-800 text-base font-normal leading-tight">
                    Enviamos um <strong>TOKEN</strong> via <strong>SMS</strong> no
                    telefone cadastrado para confirmação dos dados.
                </div>
                <FormBase>
                    <div>
                        <input
                            className="text-content token-content text-center font-bold"
                            type="text"
                            required
                            autoFocus
                            placeholder="XXXXXX"
                            disabled={disabledTokenInput}
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            maxLength={6}
                        />
                    </div>
                    {invalidToken && (
                        <div className="text-xs font-normal text-[#9E9E9E] mt-2 flex">
                            <div>
                                <img src={IError} alt="Ícone de erro" className="float-left pr-3" />
                            </div>
                            <div>
                                O token inserido é inválido. Por favor, verifique o token e
                                tente novamente.
                            </div>
                        </div>
                    )}
                </FormBase>
                <div className="self-stretch text-center text-neutral-800 text-base font-normal leading-tight">
                    Não recebeu ainda?
                </div>
                <div className="self-stretch text-center text-neutral-800 text-base font-normal leading-tight">
                    {disabledNewTokenRequest && (
                        <span>Aguarde {timer} segundos para solicitar um novo   token.</span>
                    )}
                    {!disabledNewTokenRequest && (
                        <span>Solicite um novo Token para ser enviado para o seu número de  celular.</span>
                    )}
                </div>
                <button
                    onClick={() => handleCodeRequest()}
                    className={`w-[220px] h-12 px-6 py-3.5 ${disabledNewTokenRequest ? 'bg-[#9E9E9E]' : 'bg-white'} rounded-[96px] border-[1px] border-[#56AAB7] justify-center items-center gap-2 inline-flex`}
                >
                    <div className={`${disabledNewTokenRequest ? 'text-black' : 'text-teal-400'} text-sm font-bold leading-tight`}>
                        Reenviar TOKEN
                    </div>
                </button>
            </div>
        </>
    )
} 