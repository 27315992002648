import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { DocumentTitle } from "../../components"
import { useGA, useLocalStorage } from "../../hooks"
import { FormularioPerfil } from "./components/FormularioPerfil"
import { ApiException, PeopleService } from "../../services"
import { IFormularioPerfil, ISendTokenModal } from "../../types"

import ImgPessoaFelizOuvindoMusica from "../../assets/images/img-pessoa-feliz-ouvindo-musica.webp"
import IPerson from "../../assets/icons/i-person.svg"
import "./index.css"
import { WhatsappNotification } from "./components/WhatsappNotification"
import { Terms } from "./components/Terms"
import { AccountDisable } from "./components/AccountDisable"
import { DisableNotificationsModal } from "./disable-notifications-modal"
import { SendTokenModal } from "./send-token-modal"
import { DisableAccountModal } from "./disable-account-modal"


export const Perfil = () => {
    const { getData, removeData } = useLocalStorage();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(getData('userData') ? true : false);
    const [formData, setFormData] = useState<IFormularioPerfil>({} as IFormularioPerfil);
    const [disableAccountModal, setDisableAccountModal] = useState<boolean>(false)
    const [openNotificationModal, setOpenNotificationModal] = useState(false)
    const [receivesWhatsapp, setReceivesWhatsapp] = useState(false)
    const [sendTokenModal, setSendTokenModal] = useState<ISendTokenModal | null>(null)
    const [successDeleteAccount, setSuccessDeleteAccount] = useState(false)
    const { sendGAEvent } = useGA();
    const userId = (getData('userData') ? getData('userData')._id : '')

    useEffect(() => {
        const token = getData('token')
        if (!token) {
            setIsLoading(false);
            return
        }
        PeopleService.find()
            .then((res) => {
                if (res instanceof ApiException) {
                    console.log('Erro', res)
                    setIsLoading(false)
                } else {
                    loadingPage(res.data)
                    setIsLoading(false)
                }
            });

    }, [])

    useEffect(() => {
        if (successDeleteAccount) {
            setIsLoggedIn(false)
            removeData('token')
            removeData('userData')
        }
    }, [successDeleteAccount])
    const loadingPage = (data: any) => {
        setIsLoading(true);

        setFormData({
            ...data,
            birthday: data.birthday,
            phoneNumber: data.userId.phoneNumber
        })
        setReceivesWhatsapp(data.receivesWhatsapp)
    }

    const handleDisableNotification = (disableNotification: boolean) => {
        setOpenNotificationModal(false);
        if (disableNotification) saveWhatsAppNotification(false)
    }

    const saveWhatsAppNotification = (check: boolean) => {
        PeopleService.save({
            receivesWhatsapp: check,
            _id: formData._id
        }).then((res) => {
            if (res instanceof ApiException) {
                console.log('error', res)
            } else {
                setReceivesWhatsapp(check)
            }
        })
    }

    const handleDisableAccount = (value: boolean) => {
        setDisableAccountModal(false);
        if (value)
            setSendTokenModal({
                phoneNumber: formData.phoneNumber,
                origin: 'delete'
            });
    }

    return (
        <DocumentTitle title="Perfil" description='Acesse seu perfil na Sara. Preencha seus dados cadastrais, ative lembretes, descubra nossas funcionalidades e comece a usar!'>
            {isLoading && (
                <div className="flex justify-center items-center h-[560px]">
                    <div
                        className="animate-spin w-[50px] h-[50px] border-[3px] rounded-full border-gray-300 border-l-gray-400"
                    ></div>
                </div>
            )}
            {!isLoading && !isLoggedIn && (
                <div className="general-container-perfil max-w-[1024px]" >
                    <div className="flex justify-center items-center max-w-[360px] -mb-5">
                        <img
                            src={ImgPessoaFelizOuvindoMusica}
                            alt="imagem de homem sorrindo ouvindo música"
                        />
                    </div>

                    <div className="w-full mr-12 ml-12 p-6 rounded-[20px] bg-white">
                        <span className="title text-4xl font-medium">Junte-se à Sara</span>

                        <div
                            className="text-container-perfil flex flex-col justify-center items-center gap-6"
                        >
                            <span className="text-content-perfil text-[#7a7a7a]">
                                Bem-vindo ao nosso aplicativo de cuidados com a saúde! Para aproveitar
                                ao máximo nossa plataforma e desfrutar de recursos exclusivos, como
                                salvar seus medicamentos favoritos, receber lembretes e manter um
                                histórico detalhado, convidamos você a se juntar à nossa comunidade.
                            </span>

                            <span className="text-content-perfil text-[#7a7a7a]">
                                Criar uma conta é rápido e gratuito, e abrirá as portas para uma
                                experiência de saúde personalizada. Vamos começar a cuidar da sua saúde
                                juntos!
                            </span>
                        </div>

                        <div className="button-container-perfil">
                            <Link
                                to="/cadastro"
                                className="btn-create-account-perfil"
                                onClick={() => sendGAEvent('clique_criar_conta')}
                                data-cy="btn-criar-conta-perfil"
                            >
                                Criar uma conta
                            </Link>

                            <Link to="/login" id="btn-login-perfil" className="cursor-pointer" data-cy="btn-entrar-perfil">Entrar</Link>
                        </div>
                    </div>
                </div >
            )}
            {!isLoading && isLoggedIn && (
                <div className="general-container-form-perfil flex justify-center items-start gap-6 p-6">
                    <div className="w-full bg-white p-6 flex flex-col justify-center rounded-[24px] ">
                        <div className="flex flex-col justify-center items-start mb-2">
                            <div className="flex justify-start items-center gap-2 mb-2">
                                <img
                                    src={IPerson}
                                    alt="imagem de homem sorrindo ouvindo música"
                                />
                                <span className="title title-perfil text-2xl font-bold">Dados Cadastrais</span>
                            </div>
                            <span className="text-content-perfil text-black my-2">Altere os dados do seu acesso.</span>
                        </div>
                        <FormularioPerfil perfilData={formData} id={userId} />
                    </div>

                    <div className="cards-actions-profile flex flex-col justify-start items-start gap-6">
                        <WhatsappNotification setOpenNotificationModal={setOpenNotificationModal} receivesWhatsapp={receivesWhatsapp} saveWhatsAppNotification={saveWhatsAppNotification} />

                        <Terms />

                        <AccountDisable setDisableAccountModal={setDisableAccountModal} />
                    </div >
                </div >
            )}
            <DisableAccountModal
                disable={disableAccountModal}
                setDisable={setDisableAccountModal}
                successDeleteAccount={successDeleteAccount}
                setSuccessDeleteAccount={setSuccessDeleteAccount}
                handleDisableAccount={handleDisableAccount}
            />
            <SendTokenModal sendTokenModal={sendTokenModal || null} 
                setSuccessDeleteAccount={setSuccessDeleteAccount} setSendTokenModal={setSendTokenModal}/>
            <DisableNotificationsModal openDisableNotificationsModal={openNotificationModal} handleDisableNotification={handleDisableNotification} setOpenDisableNotificationModal={setOpenNotificationModal} />
        </DocumentTitle >
    )
}