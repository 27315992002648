import ICloseGray from '../../../assets/icons/i-close-gray.svg'
import IArrowLeftColorBlue from '../../../assets/icons/i-arrow-left-color-blue.svg'
import './index.css'

interface IDisableAccountModalProps {
    disable: boolean;
    successDeleteAccount: boolean;
    
    setDisable: (disable: boolean) => void;
    setSuccessDeleteAccount: (successDeleteAccount: boolean) => void;
    handleDisableAccount: (value: boolean) => void;
}

export const DisableAccountModal: React.FC<IDisableAccountModalProps> = (props) => {
    return (
        <>
            {props.disable && (
                <div className="modal-container-disable-account">
                    <div
                        className="w-full max-w-[530px] h-auto p-[36px] m-[36px] rounded-3xl bg-white overflow-auto"
                    >
                        <div className="flex mb-6">
                            <span
                                className="font-[Nunito] text-center font-bold text-2xl text-[#272727] w-full"
                            >
                                Desativar Conta
                            </span>
                            <button className="block ml-auto" onClick={() => props.handleDisableAccount(false)}>
                                <img
                                    src={ICloseGray}
                                    alt="ícone de fechar modal"
                                />
                            </button>
                        </div>
                        <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                            Tem certeza de que deseja continuar com essa ação?
                        </p>

                        <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                            Desativar sua conta resultará na perda permanente de todos os seus dados e
                            informações associadas à sua conta.
                        </p>

                        <div className="flex flex-col gap-3">
                            <div
                                className="flex gap-[3px] justify-center h-[52px] rounded-[96px] p-3.5 pl-6 pr-6 text-center border-[1px] border-[#56AAB7] font-bold text-base text-[#56AAB7] cursor-pointer"
                                onClick={() => props.handleDisableAccount(false)}
                            >
                                <img
                                    className=""
                                    src={IArrowLeftColorBlue}
                                    alt="ícone de fechar modal"
                                />
                                <span>Manter minha Conta</span>
                            </div>

                            <div
                                className="h-[52px] rounded-[96px] p-3.5 pl-6 pr-6 text-center bg-[#FFC7C2] font-bold text-base text-[#F24822] cursor-pointer"
                                onClick={() => props.handleDisableAccount(true)}
                            >
                                <span className="flex justify-center"> Desativar </span>
                            </div >
                        </div >
                    </div >
                </div >
            )}
            {props.successDeleteAccount && (
                <div className="modal-container-disable-account" >
                    <div
                        className="w-full max-w-[530px] h-auto p-[36px] m-[36px] rounded-3xl bg-white overflow-auto"
                    >
                        <div className="flex mb-6">
                            <span
                                className="font-[Nunito] text-center font-bold text-2xl text-[#272727] w-full"
                            >
                                Conta Desativada
                            </span>
                            <button className="block ml-auto" onClick={() => {
                                props.setDisable(false);
                                props.setSuccessDeleteAccount(false);
                                }}>
                            <img
                                src={ICloseGray}
                                alt="ícone de fechar modal"
                            />
                        </button>
                    </div>
                    <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                        Conta desativada com sucesso!
                    </p>

                    <div className="justify-center flex">
                        <a
                            className="h-[52px] w-full max-w-[220px] rounded-[96px] p-3.5 pl-6 pr-6 text-center mr-2 border-[1px] border-[#56AAB7] font-bold text-base text-[#56AAB7] cursor-pointer"
                            href="/"
                        >
                            <span>Voltar para Home</span>
                        </a>
                    </div>
                </div >
              </div >
            )}
        
        </>
    )
}