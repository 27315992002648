import { Api } from "../ApiConfig";
import { ApiException } from "../ErrorException";

const apiRecallAlerts = "recall-alerts";
const apiRecall = "recalls";

interface IRegisterAllotmentData {
    allotment: string;
    productId: string;
}
const registerAllotment = async (body: IRegisterAllotmentData) => {
    try {
        return await Api().post(`${apiRecallAlerts}`, JSON.stringify(body), {
            headers: {
                "Content-Type": "application/json",
                charset: "utf-8",
            },
        });
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
};

const checkAllotment = (query: string) => {
    return Api().get(`/${apiRecall}?filters=${query}`);
};

export const RecallService = {
    registerAllotment,
    checkAllotment,
};
