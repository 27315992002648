import { useEffect, useState } from "react";
import iPillBlue from "../../assets/icons/i-pill-blue.svg";
import "./index.css";
import { IProduct, ProductService } from "../../services";
import { LoaderParams } from "../../types/LoaderParams";
import { Link, Navigate, useLoaderData } from "react-router-dom";
import { kebabCase } from "../../utils";
import { set } from "react-ga";
import { DocumentTitle } from "../../components";

const limit = 2000;

interface ISitemapViewerLoaderData {
    page: number;
}

export async function SitemapViewerLoader(props: LoaderParams) {
    const page = parseInt(props.params["page"]) || 1;

    if (!page || page <= 0 || parseInt(props.params["page"]) <= 0)
        return { page: 0 };

    return { page: page - 1 };
}

const getFriendlyUrlProduct = (product: IProduct) => {
    return `/produto/${kebabCase(product?.productName ?? "")}-${product?.productPresentation
            ? `${kebabCase(product?.productPresentation)}-`
            : ""
        }${product.ean1}`;
};

export function SitemapViewer() {
    const props = useLoaderData() as ISitemapViewerLoaderData;
    const [pages, setPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] =
        useState<[string, (IProduct & { href: string })[]][]>();

    useEffect(() => {
        ProductService.list(
            `?limit=${limit}&${props.page >= 0 ? `page=${props.page}&` : ""
            }filters={}`
        )
            .then((res) => res.data)
            .then((res) => res.data)
            .then((data) => {
                const { products, total } = data;
                setPages(Math.ceil(total / limit));

                //          if (props.page + 1 > pages) return Navigate({ to: "catalogo-de-produtos" });

                let previousLetters: Record<
                    string,
                    (IProduct & { href: string })[]
                > = {};

                products.forEach((product: IProduct) => {
                    if (
                        !product ||
                        !product.productName ||
                        !product.productName[0]
                    )
                        return;

                    if (
                        !product
                    )
                        return;

                    const currentLetter = product.productName[0]?.toUpperCase();
                    const letterAssociated =
                        parseInt(currentLetter) >= 0 ? "0-9" : currentLetter;

                    const newProduct = {
                        ...product,
                        href: getFriendlyUrlProduct(product),
                    };

                    previousLetters[letterAssociated] = [
                        ...(previousLetters[letterAssociated] ?? []),
                        newProduct,
                    ];
                });

                setIsLoading(false);
                setProducts(Object.entries(previousLetters));
            });
    }, [props, props.page]);

    return (
        <DocumentTitle
            title="Catálogo de produtos"
            description="Tenha acesso a lista de produtos na Sara. Bulas, modo de usar medicamentos e muito mais."
        >
            <div className="flex justify-center w-full mt-[36px] mb-[24px]">
                <div className="general-list-container flex flex-col justify-center items-center mb-4">
                    <div className="w-full flex justify-center items-center gap-4 md:justify-start md:pl-[16px] px-[20px]">
                        <div className="justify-start items-center gap-2 flex">
                            <div className="w-10 h-10 p-1.5 bg-cyan-50 rounded-lg justify-center items-center flex">
                                <div className="w-7 h-7 relative flex-col justify-center items-center flex">
                                    <img src={iPillBlue} alt="Icone de pilula" />
                                </div>
                            </div>
                            <div className="page-main-title px-[16px] text-neutral-800 text-3xl font-bold font-['Nunito-Medium'] leading-9">
                                Todos os nossos Produtos
                            </div>
                        </div>
                    </div>

                    <div className="w-full p-4">
                        {/* loading page */}
                        {isLoading && (
                            <div className="flex justify-center items-center h-[400px]">
                                <div className="animate-spin w-[50px] h-[50px] border-[3px] rounded-full border-gray-300 border-l-gray-400"></div>
                            </div>
                        )}

                        {!isLoading && (
                            <div className="flex flex-col max-h-[400px] max-w-[1150px] overflow-x-hidden overflow-y-auto">
                                {products?.map((letterList, i) => {
                                    return (
                                        <div key={"div_" + props.page + "_" + i}>
                                            <div >
                                                <div className="p-2 bg-slate-100 rounded-lg justify-center items-center gap-2 inline-flex my-10">
                                                    <div className="text-neutral-800 text-2xl font-bold font-['Nunito']">
                                                        {letterList[0].toUpperCase()}
                                                    </div>
                                                </div>
                                            </div>
                                            <ul>
                                                {letterList[1].map((product, j) => (
                                                    <li key={`link_${props.page}_${i}_ + ${j}`}>
                                                        <Link className="text-neutral-800 text-base md:text-lg font-bold font-['Nunito'] underline leading-normal mb-[18px]"
                                                            to={product.href}                                            >
                                                            {`${product?.productName} - ${product?.productPresentation} - ${product?.companyId?.tradeName ?? ""}`}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className="w-full px-3 py-4 bg-white rounded-bl-2xl rounded-br-2xl justify-end items-center gap-4 inline-flex">
                            <div className="justify-start items-center gap-6 flex">
                                <div className="w-12 self-stretch justify-start items-center flex">
                                    <div className="text-slate-800 text-base font-normal font-['Nunito'] leading-normal">
                                        Página
                                    </div>
                                </div>
                            </div>

                            <div className="h-8 justify-between items-center flex">
                                {props.page > 0 && (
                                    <div className="w-8 h-8 justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <a
                                                href={`/catalogo-de-produtos/${props.page}`}
                                                className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal"
                                            >
                                                {props.page}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                <div className="w-8 h-8 bg-slate-400 rounded-lg justify-center items-center flex">
                                    <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                        <div className="grow shrink basis-0 text-center text-sky-50 text-lg font-normal font-['Nunito Sans'] leading-normal">
                                            {props.page + 1}
                                        </div>
                                    </div>
                                </div>

                                {pages > props.page + 1 && (
                                    <div className="w-8 h-8 justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <a
                                                href={`/catalogo-de-produtos/${props.page + 2
                                                    }`}
                                                className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal"
                                            >
                                                {props.page + 2}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {pages > props.page + 2 && (
                                    <div className="w-8 h-8 justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <a
                                                href={`/catalogo-de-produtos/${props.page + 3
                                                    }`}
                                                className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal"
                                            >
                                                {props.page + 3}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {pages > props.page + 3 && (
                                    <div className="w-8 h-8 justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <a
                                                href={`/catalogo-de-produtos/${props.page + 4
                                                    }`}
                                                className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal"
                                            >
                                                {props.page + 4}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {pages > props.page + 4 && (
                                    <div className="w-8 h-8 rounded-lg justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <div className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal">
                                                ...
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {pages > props.page + 10 && (
                                    <div className="w-8 h-8 justify-center items-center flex">
                                        <div className="grow shrink basis-0 h-6 justify-center items-center flex">
                                            <a
                                                href={`/catalogo-de-produtos/${props.page + 11
                                                    }`}
                                                className="grow shrink basis-0 text-center text-slate-500 text-lg font-normal font-['Nunito Sans'] leading-normal"
                                            >
                                                {props.page + 11}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
}
