import { useNavigate } from "react-router-dom"
import { Container, BackHomeButton } from "./style"
import { INotFoundProps } from "../../types"
import IBackButtonIcon from "../../assets/icons/i-back-button-icon.svg"
import LogoOlhoPiscando from "../../assets/images/logo-olho-piscando.webp"
import React from "react";

export const NotFound: React.FC<INotFoundProps> = (props) => {
    const navigate = useNavigate()
    return(
        <Container>
            <div>
                <button className="absolute left-0" onClick={() => navigate("/home")}>
                    <img src={IBackButtonIcon} alt="botão voltar" />
                </button>
                <img src={LogoOlhoPiscando} alt="logo da sara com olho piscando" className="mx-auto"/>
            </div>
            <div className="mt-[96px] flex justify-center items-center w-full">
                <img src={props.notFoundIMG} alt="Imagem de produto não encontrado" />
            </div>
            <div className="w-full mb-[24px] mt-[24px] text-center">
                <p className="leading-10 font-medium text-4xl text-white">Sentimos Muito...</p>
                <p className="mt-[16px] text-lg leading-6 font-bold text-white">{props.notFoundText}</p>
            </div>
            <BackHomeButton onClick={() => navigate("/")}>Voltar para a home</BackHomeButton>
        </Container>
    )
}