import React, { useEffect, useRef, useState } from "react"
import { useIsBusy, useLocalStorage } from "../../hooks"
import { MainContainer, MainContent } from "./style"
import { Header } from "./Header"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { ScrollToTop } from "../../components/scroll-to-top"
import { LateralNav } from "./LateralNav"
import { Footer } from "./Footer"
import LogoSara from "../../assets/images/logo-sara.svg"
import "./index.css"

const pageIds = [
    { id: '', event: 'Home' },
    { id: 'home', event: 'Home' },
    { id: 'dashboard', event: 'Home' },
    { id: 'produto', event: 'Produto' },
    { id: 'busca-de-produtos', event: 'Produtos' },
    { id: 'lembretes', event: 'Lembretes' },
    { id: 'favoritos', event: 'Favoritos' },
    { id: 'noticias', event: 'Notícias' },
    { id: 'perfil', event: 'Perfil' },
    { id: 'catalogo-de-produtos', event: 'Catálogo de Produtos' }
]

export const Main = () => {
    const { setIsBusy } = useIsBusy();
    const navigate = useNavigate();
    const location = useLocation();
    const { getData, removeData } = useLocalStorage();
    const headerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [isLoggedIn, setIsloggedIn] = useState(getData('token') ? true : false);
    const [showSideNav, setShowSideNav] = useState(true);
    const [siteUrl, setSiteUrl] = useState('https://www.sara.com.br');
    const [isHomeRoute, setIsHomeRoute] = useState<boolean>();
    
    useEffect(() => {
        setIsBusy(false)
        const header = headerRef.current;
        const menu = menuRef.current;
        setIsHomeRoute(location.pathname === '' || location.pathname === '/' || location.pathname.startsWith('/dashboard') ? true : false)

        if (header && menu) {
            const resizeObserver = new ResizeObserver(() => {
                requestAnimationFrame(() => {
                    menu.style.top = `${188 - header.offsetHeight}px`;
                });
            });

            resizeObserver.observe(header);
            return () => {
                resizeObserver.disconnect();
            };
        }

    }, [location]);

    const logout = () => {
        removeData('token');
        removeData('userData');
        setIsloggedIn(!!getData('token'));
        navigate('/');
    }

    return (
        <>
        <ScrollToTop />
            <MainContainer id="sara-main">
                <Header ref={headerRef} />
                <MainContent id="main-content" className={isHomeRoute ? 'home-route' : ''}>
                    {showSideNav && (
                        <LateralNav ref={menuRef} pageIds={pageIds} logoSara={LogoSara} isLoggedIn={isLoggedIn} logout={logout} />
                    )}
                    <div className="w-full flex">
                        {showSideNav && (
                            <div className="hidden md:flex min-w-normal w-normal dynamic-width">
                            </div>
                        )}
                        <Outlet />
                    </div>
                </MainContent >
            </MainContainer >
            <Footer logoSara={LogoSara} navigate={navigate} siteUrl={siteUrl} showSideNav={showSideNav} />
        </>
    )
}