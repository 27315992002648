
import ICloseGray from "../../../assets/icons/i-close-gray.svg"
import IArrowLeftColorBlue from "../../../assets/icons/i-arrow-left-color-blue.svg"
import "./index.css"

interface IDisableNotificationsModalProps {
    openDisableNotificationsModal: boolean;
    setOpenDisableNotificationModal: (openDisableNotificationModal: boolean) => void;
    handleDisableNotification: (disableNotificatio: boolean) => void;
}
export const DisableNotificationsModal: React.FC<IDisableNotificationsModalProps> = (props) => {
    return (
        <>
            {props.openDisableNotificationsModal && (
                <div className="modal-container-disable-account" >
                    <div className="w-full max-w-[530px] h-auto p-[36px] m-[36px] rounded-3xl bg-white overflow-auto">
                        <button className="block ml-auto mb-[24px]" onClick={() => props.setOpenDisableNotificationModal(false)}>
                            <img
                                src={ICloseGray}
                                alt="ícone de fechar modal"
                            />
                        </button>
                        <div className="flex flex-col justify-start items-center mb-[24px]">
                            <p className="font-['Nunito'] text-2xl font-bold text-[#272727] mb-[24px]">
                                Desativar Notificações
                            </p>

                            <span className="font-['Nunito'] text-base font-normal text-[#272727] mb-[24px]">
                                Você tem certeza que quer desabilitar os lembretes via WhatsApp?
                            </span>

                            <span className="font-['Nunito'] text-base font-normal text-[#272727]">
                                Você não recebera mais lembretes da Sara para tomar seus medicamentos.
                            </span>
                        </div>

                        <div className="flex flex-col gap-3">
                            <div
                                onClick={() => props.setOpenDisableNotificationModal(false)}
                                className="flex gap-[3px] justify-center h-[52px] rounded-[96px] p-3.5 pl-6 pr-6 text-center border-[1px] border-[#56AAB7] font-bold text-base text-[#56AAB7] cursor-pointer"
                            >
                                <img
                                    src={IArrowLeftColorBlue}
                                    alt="ícone de fechar modal"
                                />
                                <span>Manter Notificações</span>
                            </div>

                            <div
                                onClick={() => props.handleDisableNotification(true)}
                                className="h-[52px] rounded-[96px] p-3.5 pl-6 pr-6 text-center bg-[#FFC7C2] font-bold text-base text-[#F24822] cursor-pointer"
                            >
                                <span className="flex justify-center">
                                    Desativar
                                </span>
                            </div >
                        </div >
                    </div >
                </div >
            )}
        </>
    )
}