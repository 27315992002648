export const allowedCompanyIds = new Set([
    "64110813ec575373f3949027",
    "6411089bec5753465e94907d",
    "64110843ec575385fd949047",
    "64b96ec56e41ce46df99e330",
    "6411077fec575394e2948fc2",
    "64b968806e41ce46df99e32a",
    "641107c9ec5753cd8d948ff4",
    "641107feec5753966a949018",
    "64b96a9b6e41ce46df99e32c",
  ]);