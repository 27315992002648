import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { findAll } from '../../services/api/product/product-service';
import ProductPresentation from '../../components/ProductPresentation';
import ILupaGreen from "../../assets/icons/i-lupa-produto.svg";

import './style-busca.css'
import { DocumentTitle } from '../../components';

interface Product {
    id: string;
    productName: string;
    productPresentation?: string;
    companyName?: string;
    ean1: string;
    companyId: {
        uniqueId: string;
    };
}

// //testando ordem dos produtos antes de mexer no backend
// const LAB_PRIORITY = [
//     "EMS S/A", "EMS PHARMA", "EMS SIGMA PHARMA LTDA", "GERMED FARMACEUTICA LTDA",
//     "GERMED PHARMA", "NOVA QUIMICA", "NOVA QUIMICA FARMACÊUTICA S/A", "LEGRAND",
//     "LEGRAND PHARMA INDÚSTRIA FARMACÊUTICA LTDA", "MULTILAB",
//     "MULTILAB INDUSTRIA E COMERCIO DE PRODUTOS FARMACEUTICOS LTDA", "BRACE PHARMA",
//     "USK DERMATOLOGY", "OFTA VISION HEALTH", "ADAPTA PROD OFTAL"
// ];

export const BuscaProdutoPage = () => {
    const [searchParams] = useSearchParams();
    const productQuery = searchParams.get('produto');
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate(); // Aqui adicionamos o uso de useNavigate

    useEffect(() => {
        if (productQuery) {
            fetchData(productQuery);
        }
    }, [productQuery]);

    const fetchData = async (query: string) => {
        setIsLoading(true);
        try {
            const response = await findAll(`?filters={"productName":"${query}"}`);
            if (response.data && response.data.result) {
                setProducts(response.data.result);
            } else {
                console.log('No products received, setting products to empty array');
                setProducts([]);
            }
        } catch (error) {
            console.error('Error while fetching products:', error);
            setProducts([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleProductClick = (product: Product) => {
        if (product.ean1) {
            const formatSlug = (string: string) => {
                return string
                    .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .replace(/ç/g, 'c')
                    .replace(/\//g, '-')
                    .toLowerCase()
                    .replace(/[^a-z0-9 -]/g, "")
                    .replace(/ /g, '-')
                    .replace(/-+/g, '-')
                    .replace(/^-+|-+$/g, "");
            };

            const productNameSlug = formatSlug(product.productName);
            const productPresentationSlug = product.productPresentation ? formatSlug(product.productPresentation) : '';
            console.log('Product name slug:', productNameSlug);
            navigate(`/produto/${productNameSlug}-${productPresentationSlug}-${product.ean1}`);
            console.log('Product clicked:', product);
        } else {
            console.error("EAN is undefined for product:", product);
        }
    };


    return (
        <DocumentTitle title="Busca de Produtos" description="Busque o medicamento que procura, temos um banco com diversos tipos dos mais diferentes remédios. Na Sara é fácil você encontrar o que precisa. Acesse!">
            {isLoading ? (
                <div className="loading-provisorio mt-[24px]">Carregando...</div>
            ) : products.length > 0 ? (
                <div className="pt-[76px] pl-[16px] product-presentation-container-small" >
                    <ProductPresentation  products={products} onProductClick={handleProductClick} width="100%" />
                </div>
            ) : (
                <div className="card-empty text-center">
                    <img src={ILupaGreen} className="mx-auto" alt="icone de lupa busca" />
                    <div className="my-6">
                        <span className="text-4xl">Ainda não houve busca de medicamento</span>
                    </div>
                    <p>
                        Para exibir resultados aqui, busque por medicamentos na barra de pesquisa
                    </p>
                </div>
            )}
        </DocumentTitle>
    );
};
