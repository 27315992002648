import styled from "styled-components";


export const Title = styled.h1`
    font-family: 'Nunito-Bold' !important;
    font-size: 2.2em;
`

export const TitleSecondary = styled.h2`
    font-family: 'Nunito-Bold' !important;
    font-size: 1.6em;
`