import { RegisterForm } from './RegisterForm'
import { BackButton, BackgroundForm, FormContainer } from '../../components'
import { TokenContainer as Container } from '../../styles/styled'
import { DocumentTitle } from '../../components'
import { useEffect, useState } from 'react'

export const Register = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true)
        }, 300)
    }, [])
    return (
        <DocumentTitle title="Cadastro" description='Acesse e faça seu cadastro. Tenha acesso as diversas funcionalidades oferecidas na Sara. Bulas, modo de usar medicamentos e muito mais.'>
            <Container>
                <div className={`w-full min-h-screen flex justify-between min-[1025px]:bg-white ${loading ? '' : 'hidden'}`}>
                    <div className="relative w-full mx-auto px-[24px] min-[1025px]:w-auto min-[1025px]:px-0 min-[1025px]:min-w-[540px] min-[1025px]:pl-[24px]">
                        <BackButton text="Login" />
                        <FormContainer >
                            <RegisterForm />
                        </FormContainer>

                    </div>
                    <BackgroundForm />
                </div>
            </Container>
        </DocumentTitle>
    )
}