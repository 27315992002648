import { Link } from "react-router-dom"
import ITerms from "../../../assets/icons/i-terms.svg"
import {  useEffect } from "react";


const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

  
export const Terms = () => {
    return (
        <div className="cards-actions rounded-[24px] px-2 py-6 bg-white">
            <div className="flex justify-start items-center gap-2 mb-2">
                <img
                    src={ITerms}
                    alt="imagem de homem sorrindo ouvindo música"
                />
                <span className="title-perfil text-2xl font-bold">Termos de Uso</span>
            </div>
            <div className="">
                <p className="text-content-perfil">
                    Clique aqui para acessar os &nbsp;
                    <Link to="/termos"  onClick={ScrollToTop} className="text-[#56AAB7] underline" data-cy="link-termos-perfil"
                    >Termos de Uso e Condições</Link>.
                </p>
            </div>
        </div>
    )
}