import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type MenuContextType = {
    isMinimized: boolean;
    toggleMenu: (force?: boolean) => void;  // Aceita parâmetro opcional para forçar estado
    autoMinimizeEnabled: boolean;          // Controle se o auto-minimize está habilitado
    minimizedByScroll: boolean;            // Estado para indicar se foi minimizado pelo scroll
    setAutoMinimizeEnabled: (value: boolean) => void; // Função para ajustar autoMinimizeEnabled
    setMinimizedByScroll: (value: boolean) => void; // Função para ajustar minimizedByScroll
}


const MenuContext = createContext<MenuContextType | null>(null);

export const useMenu = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};

interface MenuProviderProps {
    children: ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [autoMinimizeEnabled, setAutoMinimizeEnabled] = useState(true);
    const [minimizedByScroll, setMinimizedByScroll] = useState(false);

    const toggleMenu = (force?: boolean) => {
        const newState = force !== undefined ? force : !isMinimized;
        setIsMinimized(newState);
        setMinimizedByScroll(newState && !isMinimized); 
    };

    return (
        <MenuContext.Provider value={{
            isMinimized,
            toggleMenu,
            autoMinimizeEnabled,
            setAutoMinimizeEnabled,
            minimizedByScroll,
            setMinimizedByScroll
        }}>
            {children}
        </MenuContext.Provider>
    );
};