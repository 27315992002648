import { styled } from "styled-components";

export const VoltarOption = styled.a.attrs({
    className: "flex items-center gap-x-6 font-bold font-['Nunito'] cursor-pointer",
})`
   & p{
    font-size: 1.4em;
    font-weight: bold;
    color: #000;
   }
`