import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { RegisterForm } from "./RegisterForm"
import { BackgroundForm } from "../../components"
import { useLocalStorage } from "../../hooks"
import { TokenContainer as Container } from "../../styles/styled"
import { DocumentTitle } from "../../components"

export const MedicalRegister = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { getData, removeData } = useLocalStorage()

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState(queryParams.get('phoneNumber') || '')
    const [email, setEmail] = useState('')
    const [ufCrm, setUfCrm] = useState('')
    const [crm, setCrm] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [representativeId, setRepresentativeId] = useState(queryParams.get('representativeId') || '')
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true)
        }, 300)
    }, [])

    useEffect(() => {
        const data = getData('formRegister')
        if (data) {
            setName(data.name)
            setPhoneNumber(data.phoneNumber)
            setCrm(data.crm)
            setUfCrm(data.ufCrm)
            setEmail(data.email)
            setRepresentativeId(data.representativeId)
            removeData('formRegister')
        }
        if (!representativeId) navigate('/')
    }, [])

    return (
        <DocumentTitle title='Cadastro Médico' description='Acesse e faça seu cadastro. Tenha acesso as diversas funcionalidades oferecidas na Sara. Bulas, modo de usar medicamentos e muito mais.'>
            <Container>
                <div className={`w-full min-h-screen flex justify-between min-[1025px]:bg-white ${loading ? '' : 'hidden'}`}>
                    <div className="relative w-full mx-auto px-[24px] min-[1025px]:w-auto min-[1025px]:flex min-[1025px]:px-0 min-[1025px]:min-w-[540px] min-[1025px]:pl-[24px]">
                        <RegisterForm name={name} setName={setName} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} email={email} setEmail={setEmail} ufCrm={ufCrm} setUfCrm={setUfCrm} crm={crm} setCrm={setCrm} termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} representativeId={representativeId} />
                    </div >
                    <BackgroundForm />
                </div>
            </Container >
        </DocumentTitle>
    )
}