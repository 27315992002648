
export const LocalStorageService = () => {
    const setItem = (key: string, data: any) => {
        localStorage.setItem(key, JSON.stringify(data))
    }

    const getData = (key: string) => {
        try {
            const data = localStorage.getItem(key)
            return data ? JSON.parse(data) : null
        } catch {
            removeData(key)
            return null
        }
    }

    const removeData = (key: string) => {
        localStorage.removeItem(key)
    }

    const clear = () => {
        localStorage.clear()
    }

    return { setItem, getData, removeData, clear }
}
export const useLocalStorage = LocalStorageService;