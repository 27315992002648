import { useLocalStorage } from "../../../hooks";
import iRecallNotify from "../../../assets/icons/i-recall-notify.svg";
import "./style.css";
import { Link } from "react-router-dom";
interface IProductRecallFormProps {
  className?: string;
  ean1: string;
}
export default function ProductRecallForm(props: IProductRecallFormProps) {
  const isLoggedIn = useLocalStorage().getData("token");

  return (
    <div className={props.className}>
      <div className="recall-presentation w-full h-full min-w-[258px]">
        <div className="title-recall justify-center lg:justify-start">
          <img
            src={iRecallNotify}
            alt="icone de notificação de recall"
          />
          <span>Recall de Medicamentos</span>
        </div>
        <p>
          Informe o lote do seu medicamento, e seja avisado caso haja algum
          problema com esse lote que possa trazer riscos à sua saúde e
          segurança.
        </p>
        <Link
          to={!isLoggedIn ? "/login" : `/recall-de-medicamento/${props.ean1}`}
          id="createRecall"
          className="cursor-pointer"
          data-cy="btn-informar-lote"
        >
          Informar Lote
        </Link>
      </div>
    </div>
  );
}
