import { FormBase } from "../style";
import IErrorIcon from "../../../../assets/icons/i-error.svg"
import ISuccessIcon from "../../../../assets/icons/i-success.svg"

interface IInputNameProps {
    name: string;
    setName: (name: string) => void;
    nameValidated: boolean;
    setNameValidated: (nameValidated: boolean) => void;
}

export const InputName: React.FC<IInputNameProps> = (props) => {
    return (
        <FormBase>
            <div className="relative">
                <p>Nome Completo</p>
                <input
                    className="text-content"
                    type="text"
                    required
                    value={props.name}
                    onBlur={() => props.setNameValidated(true)}
                    onKeyDown={(e) => { if (e.key >= '0' && e.key <= '9') e.preventDefault() }}
                    onChange={(e) => props.setName(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pt-6">
                    {props.nameValidated && props.name.length < 2 && (
                        <img src={IErrorIcon} alt="Ícone de erro" />
                    )}
                    {props.name.length > 1 && (
                        <img src={ISuccessIcon} alt="Ícone de acerto" />
                    )}
                </div>
            </div>
            {props.nameValidated && props.name.length < 2 && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IErrorIcon} alt="Ícone de erro" className="float-left" />&nbsp; É necessário preencher o seu nome para continuar.
                </div>
            )}
        </FormBase>
    )
}