import { LoginForm } from "./LoginForm"
import { BackButton, BackgroundForm } from "../../components"
import { FormContainer } from "../../components"
import { TokenContainer as Container } from "../../styles/styled"
import { DocumentTitle } from "../../components"
import { useEffect, useState } from "react"
export const Login = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true)
        }, 300)
    }, [])
    return (
        <DocumentTitle title="Login" description='Descubra os mais diversos medicamentos, salve os seus preferidos, defina alarmes e lembretes, leia a bula, utilização e mais. Faça seu login na Sara!'>
            <Container>
                <div className={`w-full min-h-screen flex justify-between min-[1025px]:bg-white ${loading ? '' : 'hidden'}`}>
                    <div className="relative w-full mx-auto px-[24px] min-[1025px]:w-auto min-[1025px]:px-0 min-[1025px]:min-w-[540px] min-[1025px]:pl-[24px]">
                        <BackButton text="Login" />
                        <FormContainer >
                            <LoginForm />
                        </FormContainer>
                    </div>
                    <BackgroundForm />
                </div>
            </Container >
        </DocumentTitle>
    )
}