import { useNavigate } from "react-router-dom"
import { Container } from "./style"
import { TermsText } from "./TermsText"
import { DocumentTitle } from "../../components"
import TemsBackground from "../../assets/images/tems-background.webp"
import IBackButtonIcon from "../../assets/icons/i-back-button-icon.svg"
import LogoSara from "../../assets/images/logo-sara.svg"
import SaraLogoWhite from "../../assets/images/sara-logo-white.svg"

export const Terms = () => {
    const navigate = useNavigate()
    return (
        <DocumentTitle title='Termos' description='É política da Sara respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar e tratar no site Sara.'>
            <Container>
                <div className="max-w-screen-2xl h-full w-full relative z-[1]">
                    <button onClick={() => navigate(-1)} className="absolute top-[50px] left-5 cursor-pointer">
                        <img src={IBackButtonIcon} alt="Botão de voltar" />
                    </button>

                    <div className="w-full flex flex-col px-[16px] py-[28px] lg:py-[86px] lg:px-[142px] lg:bg-none bg-gradient-to-r from-[#32DAC3] to-[#278EEB]">
                        <div>
                            <img src={LogoSara} alt="logo sara" className="mb-8 w-[365.76px] hidden lg:block" />
                            <img src={SaraLogoWhite} alt="logo sara" className="mb-[60px] w-[177.76px] mx-auto pt-[24px] block lg:hidden sm:w-[365.76px] sm:pt-0" />
                        </div>

                        <TermsText />
                    </div>
                </div>

                <div className="h-screen fixed z-[0] right-0 top-0 hidden lg:block py-[26px]">
                    <img src={TemsBackground} alt="Fundo com arcos coloridos" className="h-full" />
                </div>
            </Container>
        </DocumentTitle>
    )
}