import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TokenForm } from "./TokenForm"
import { BackButton, BackgroundForm, FormContainer } from "../../components"
import { TokenContainer as Container } from "../../styles/styled"
import { ApiException, PasswordlessService } from "../../services"
import { useLocalStorage, useProduct } from "../../hooks"
import { DocumentTitle } from "../../components"

export const Token = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getData, setItem } = useLocalStorage();
    const queryParams = new URLSearchParams(location.search);
    const queryType = queryParams.get('type') || undefined;
    const queryName = queryParams.get('name') || undefined;
    const favourite = queryParams.get('favourite') ? true : false
    const queryPhoneNumber = queryParams.get('phoneNumber') || undefined;
    const storageToken = getData('token') || undefined;
    const [token, setToken] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        if (token != undefined) {
            setItem('token', token)
        }
    }, [token])
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true)
        }, 300)
    }, [])
    useEffect(() => {
        if (queryType && queryType !== 'login') handleGetToken();
        if (queryType && queryType === 'login') setToken(storageToken);
    }, []);

    const handleGetToken = () => {
        if (!queryPhoneNumber || !queryType) return
        PasswordlessService.sendVerification(queryPhoneNumber, queryType)
            .then((res) => {
                if (res instanceof ApiException) {
                    if (res.message === 'User not Found' && queryPhoneNumber) {
                        const params = new URLSearchParams({
                            phoneNumber: queryPhoneNumber
                        });
                        navigate(`/cadastro?${params.toString()}`);
                    }
                } else {
                    setToken(res.data)
                }
            })
    };

    return (
        <DocumentTitle title="Token" description='Descubra os mais diversos medicamentos, salve os seus preferidos, defina alarmes e lembretes, leia a bula, utilização e mais. Insira o token fornecido!'>
            <Container>
                <div className={`w-full min-h-screen flex justify-between min-[1025px]:bg-white ${loading ? '' : 'hidden'}`}>
                    <div className="relative w-full mx-auto px-[24px] min-[1025px]:w-auto min-[1025px]:px-0 min-[1025px]:min-w-[540px] min-[1025px]:pl-[24px]">
                        <BackButton text={queryType && queryType === 'signup' ? queryType : 'login'} />
                        <FormContainer>
                            <TokenForm type={queryType} name={queryName} phoneNumber={queryPhoneNumber} token={token} setToken={setToken} favourite={favourite} />
                        </FormContainer>
                    </div>
                    <BackgroundForm />
                </div>
            </Container>
        </DocumentTitle>
    )
}