import { styled } from "styled-components";

export const ScrollTop = styled.a`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
    position: relative;
    float: right;
    border-radius: 96px;
    padding: 8px 16px;
    background: var(--primria-amarelo, #F9B201);
    box-shadow: 0px 8px 8px 0px rgba(249, 178, 1, 0.25);
    font-weight: 500;
    cursor: pointer;
`