import { Field, FieldArray, Form, Formik } from "formik";
import ILembrete from "../../../assets/icons/i-lembrete.svg"
import IError from "../../../assets/icons/i-error.svg"
import ITrash from "../../../assets/icons/i-trash.svg"
import IAdd from "../../../assets/icons/i-add.svg"
import ICloseGray from "../../../assets/icons/i-close-gray.svg";
import IArrowLeft from "../../../assets/icons/i-arrow-left.svg"
import iReminder from "../../../assets/icons/i-reminder.svg"
import IEditReminder from "../../../assets/icons/i-edit-reminder.svg"
import IWhatsappBlack from "../../../assets/icons/i-whatsapp-black.svg"
import ILembreteActiveSuccess from "../../../assets/icons/i-lembretes-active-success.svg"
import ProductPresentation from "../../../components/ProductPresentation";
import { IProduct, Product } from "../../../types";
import { useNavigate } from "react-router-dom";

interface ICreateReminderProps {
    page: string;
    isLoading: boolean;
    ValidationSchema: any;
    openModalSearchProduct: boolean;
    setOpenModalSearchProduct: (openModalSearchProduct: boolean) => void;
    searchModalListProducts: any;
    selectedProductModal: (item: any) => void;
    deleteTime: (index: number) => void;
    setResume: (data: any) => void;
    openModalExistReminder: boolean;
    setOpenModalExistReminder: (openModalExistReminder: boolean) => void;
    product: any;
    modalExistNextReminder: boolean;
    navigateToReminderEdit: () => void;
    stepsCreateReminder: string;
    dataReminder: any;
    setStepsCreateReminder: (stepsCreateReminder: string) => void;
    reminderFormSubmit: () => void;
    changePage: (page: 'notLogged' | 'logged' | 'createReminder', queryParams?: {}) => void
    setInputValue: (inputValue: string) => void;
    onBlur: () => void;
    productName: string;
}

export const CreateReminder: React.FC<ICreateReminderProps> = (props) => {
    const navigate = useNavigate();
    
    return (
        <>
            {props.page === "createReminder" && !props.isLoading && (
                <div className="general container-create-reminder">
                    {/* step create */}
                    {props.stepsCreateReminder === 'create' && (
                        <div className='w-full bg-white p-6 rounded-[20px]'>
                            <div className="header-reminder flex items-center gap-2 w-full mb-6">
                                <img src={ILembrete} alt="ícone" />
                                <span className="font-[Nunito] font-bold text-2xl text-[#272727]">
                                    Criar Lembrete
                                </span>
                            </div>

                            <div className="2-full md:hidden">
                                {props.product && (
                                    <ProductPresentation products={[props.product]} onProductClick={() => { }} />
                                )}
                            </div>

                            <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6 mt-[24px]">
                                Insira as informações abaixo e personalize o lembrete do seu medicamento
                                de acordo com a sua rotina e deixe o WhatsApp lembrar você na hora certa.
                                Simples, eficaz e sempre ao alcance do seu celular.
                            </p>

                            <Formik
                                initialValues={{ productName: props.productName, startTimestamps: null, finishTimestamp: null, ingestionArray: [''] }}
                                onSubmit={(values) => props.setResume(values)}
                                validationSchema={props.ValidationSchema}
                            >
                                {({ isValid, errors, touched, values, setFieldValue }) => (
                                    <Form id="reminderForm" className='form-base-reminder'>
                                        {/* nome do produto */}
                                        <div className="relative mb-6">
                                            <p>Qual o produto?</p>
                                            <Field
                                                type="text"
                                                className="text-content-reminder"
                                                placeholder="Digite seu produto"
                                                name="productName"
                                                onFocus={() => props.setOpenModalSearchProduct(true)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    props.setInputValue(e.target.value)
                                                    setFieldValue('productName', e.target.value)
                                                }}
                                                onBlur={props.onBlur}
                                                data-cy="search-product"

                                            />

                                            {/* <!-- Modal search list props.product -->  */}
                                            {props.openModalSearchProduct && props.searchModalListProducts.length > 0 && (
                                                <div className="absolute w-full max-h-[360px] overflow-auto z-10 px-6 left-0 rounded-[10px] bg-white shadow-xl flex flex-col items-center">
                                                    {props.searchModalListProducts.map((item: any, index: number) => (
                                                        <div
                                                            className="w-full border-b-2 py-4 border-gray-200 last:border-b-0 cursor-pointer"
                                                            key={index}
                                                            onClick={() => props.selectedProductModal(item)}
                                                            data-cy="select-product"
                                                        >
                                                            <span className="text-left">
                                                                <span className="break-before-auto">
                                                                    {item.productName} {item.productPresentation}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div >

                                        {/* data de inicio */}
                                        <div className="mb-6">
                                            <p>Qual a data de inicio?</p>
                                            <Field
                                                type="date"
                                                className="text-content w-full"
                                                placeholder="DD/MM/YYYY"
                                                name="startTimestamps"
                                                // min="{{ minDateInitial | date : 'yyyy-MM-dd' }}"
                                                // (ngModelChange)="minDateFinal = $event"s
                                                // (blur)="validateDateInitial()"
                                                data-cy="start-date"
                                            />
                                            {errors.startTimestamps && touched.startTimestamps && (
                                                <div className="text-xs font-normal text-[#9E9E9E] mt-2" >
                                                    <img
                                                        src={IError}
                                                        alt="Ícone de erro"
                                                        className="float-left"
                                                    />
                                                    &nbsp; {errors.startTimestamps}
                                                </div>
                                            )}
                                        </div>

                                        {/* data de fim */}
                                        <div className="mb-6">
                                            <p>Qual a data de fim?</p>
                                            <Field
                                                type="date"
                                                className="text-content w-full"
                                                placeholder="DD/MM/YYYY"
                                                name="finishTimestamp"
                                                // min="{{ minDateFinal | date : 'yyyy-MM-dd' }}"
                                                // (blur)="validateDateEnd()"
                                                data-cy="end-date"
                                            />
                                            {/* {errors.finishTimestamp && touched.finishTimestamp && (
                                                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                                                    <img
                                                        src={IError}
                                                        alt="Ícone de erro"
                                                        className="float-left"
                                                    />
                                                    &nbsp; {errors.finishTimestamp}
                                                </div>
                                            )} */}
                                        </div>

                                        {props.product && (
                                            <FieldArray name="ingestionArray">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.ingestionArray.map((item: string, index: number) => (
                                                            <div key={index}>
                                                                <div className="flex justify-between gap-4 mb-6">
                                                                    <div className="w-full">
                                                                        <p>Qual o horário de ingestão?</p>
                                                                        <Field
                                                                            type="time"
                                                                            className="text-content"
                                                                            placeholder="DD/MM/YYYY"
                                                                            name={`ingestionArray[${index}]`}
                                                                            data-cy="time"
                                                                        />
                                                                    </div>
                                                                    {values.ingestionArray.length > 1 && (
                                                                        <button
                                                                            className="pt-[21px]"
                                                                            onClick={() => remove(index)}
                                                                            data-cy="btn-delete-time"
                                                                        >
                                                                            <img
                                                                                src={ITrash}
                                                                                alt="ícone de excluir horário"
                                                                            />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="">
                                                            <button
                                                                onClick={() => push('')}
                                                                className="btn-reminder btn-segundary-reminder max-[520px]:mx-auto max-[767px]:ml-0 max-[820px]:mx-auto min-[820px]:ml-0"
                                                                data-cy="add-time"
                                                            >
                                                                <img src={IAdd} alt="ícone " />
                                                                <span>Adicionar mais horários</span>
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </FieldArray>
                                        )}
                                        <button
                                            className="btn-reminder btn-primary-reminder mt-[24px] max-[520px]:mx-auto max-[767px]:mr-0 max-[820px]:mx-auto min-[820px]:ml-auto"
                                            disabled={!isValid && values.ingestionArray.length > 0}
                                            type="submit"
                                            data-cy="btn-continue"
                                        >
                                            Continuar
                                        </button>
                                    </Form >
                                )}
                            </Formik >
                        </div>
                    )}

                    {/* modal exist reminder  */}
                    {props.openModalExistReminder && (
                        <div className="modal-container-reminder overflow-hidden">
                            <div className="w-full max-w-[492px] m-4 bg-white p-6 rounded-[20px]">
                                <div className="header w-full mb-2">
                                    <button
                                        className="block ml-auto mb-2"
                                        onClick={() => props.setOpenModalExistReminder(false)}
                                        data-cy="btn-close-modal"
                                    >
                                        <img
                                            src={ICloseGray}
                                            alt="ícone de fechar modal"
                                        />
                                    </button>
                                </div>
                                {props.product && (
                                    <ProductPresentation products={[props.product]} onProductClick={() => { }} />
                                )};

                                <div className="header flex items-center gap-2 w-full mb-2 mt-[20px]">
                                    <img src={iReminder} alt="ícone" />
                                    <span
                                        className="font-[Nunito] font-medium text-[18px] text-[#272727] tracking-tighter"
                                    >
                                        Lembrete
                                    </span>
                                </div>
                                <p className="font-[Nunito] font-normal text-sm text-[#6A6F75] mb-2">
                                    Facilite a sua rotina de cuidados com a saúde! Crie um lembrete e receba
                                    avisos sobre seus medicamentos diretamente no seu WhatsApp. É simples e
                                    prático, tudo para garantir que você não perca nenhuma dose.
                                </p>

                                <div className="btn-container-reminder">
                                    {props.modalExistNextReminder && (
                                        <div className="font-[Nunito] text-white w-[220px] h-[48px] px-[18px] rounded-[10px] bg-[#56AAB7]">
                                            <p className="block text-xs">Próximo lembrete</p>
                                            <p className="block text-base">{props.modalExistNextReminder}</p>
                                        </div>
                                    )}

                                    {!props.modalExistNextReminder && (
                                        <div className="flex font-[Nunito] w-[220px] h-[48px] px-[18px] rounded-[10px]">
                                            <p className="m-auto text-base text-[#dc2626]">Expirado</p>
                                        </div >
                                    )}

                                    <button className="btn btn-primary-reminder" onClick={props.navigateToReminderEdit} data-cy="btn-edit-reminder" >
                                        <img
                                            src={IEditReminder}
                                            alt="ícone de edição"
                                        />
                                        <span>Editar Lembrete</span>
                                    </button >
                                </div >
                            </div >
                        </div >
                    )}

                    {/* resume reminder  */}
                    {props.stepsCreateReminder === 'resume' && (
                        <div
                            className="w-full bg-white p-6 rounded-[20px]"
                        >
                            <div className="header flex items-center gap-2 w-full mb-6">
                                <img src={iReminder} alt="ícone" />
                                <span className="font-[Nunito] font-bold text-2xl text-[#272727]">
                                    Criar Lembrete
                                </span>
                            </div>

                            <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                                Confira as informações abaixo antes de criar o lembrete do seu medicamento
                                de acordo com a sua rotina e deixe o WhatsApp lembrar você na hora certa.
                            </p>

                            <div id="reminderResume" className="flex flex-col gap-6">
                                <div className="flex flex-col gap-2">
                                    <p className="font-[Nunito] text-base font-normal text-[#272727]">
                                        Qual o produto?
                                    </p>
                                    <p className="font-[Nunito] text-lg font-bold text-[#272727]">
                                        {props.dataReminder.productName}
                                    </p>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <p className="font-[Nunito] text-base font-normal text-[#272727]">
                                        Qual a duração?
                                    </p>
                                    {props.dataReminder.duration && (
                                        <p
                                            className="font-[Nunito] text-lg font-bold text-[#272727]"
                                        >
                                            {props.dataReminder.duration}
                                        </p>
                                    )}

                                </div>
                                {/* {!props.dataReminder.finishTimestamp && (
                                    <div className="flex flex-col gap-2">
                                        <p className="font-[Nunito] text-base font-normal text-[#272727]">
                                            Qual a data de inicio?
                                        </p>
                                        <p className="font-[Nunito] text-lg font-bold text-[#272727]">
                                            {}
                                        </p>
                                    </div >
                                )} */}


                                {/* {props.dataReminder.finishTimestamp && (
                                    <div className="flex flex-col gap-2" >
                                        <p className="font-[Nunito] text-base font-normal text-[#272727]">
                                            Qual a data de fim?
                                        </p>
                                        <p className="font-[Nunito] text-lg font-bold text-[#272727]">
                                            {props.dataReminder.finishTimestamp}
                                        </p>
                                    </div >
                                )} */}


                                <div className="flex flex-col gap-2">
                                    <p className="font-[Nunito] text-base font-normal text-[#272727]">
                                        Horários de Ingestão:
                                    </p>
                                    {props.dataReminder.ingestionArray.map((items: any, index: number) => (
                                        <p className="font-[Nunito] text-lg font-bold text-[#272727]">
                                            {items}
                                        </p>
                                    ))}
                                </div >

                                <div className="btn-container-reminder">
                                    <button
                                        onClick={() => props.setStepsCreateReminder("create")}
                                        className="btn-reminder btn-segundary-reminder"
                                        data-cy="btn-voltar-criar-lembrete"
                                    >
                                        <img
                                            src={IArrowLeft}
                                            alt="ícone de voltar"
                                        />
                                        <span>Voltar</span>
                                    </button>

                                    <button className="btn-reminder btn-primary-reminder" onClick={props.reminderFormSubmit} data-cy="btn-criar-lembrete" >
                                        <img
                                            src={IWhatsappBlack}
                                            alt="ícone de voltar"
                                        />
                                        <span>Criar Lembrete</span>
                                    </button >
                                </div >
                            </div >
                        </div >

                    )}

                    {/* Success create reminder  */}
                    {props.stepsCreateReminder === 'success' && (
                        <div
                            className="w-full bg-white p-6 rounded-[20px]"
                        >
                            <div
                                className="header-reminder flex flex-col justify-center items-center gap-2 w-full mb-6"
                            >
                                <img
                                    src={ILembreteActiveSuccess}
                                    alt="ícone"
                                />
                                <span className="font-[Nunito] font-bold text-2xl text-[#56AAB7]">
                                    Lembrete criado com Sucesso!
                                </span>
                            </div>

                            <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                                Ótima notícia! Seu lembrete para <strong>{props.product.productName}</strong> foi criado com sucesso! Agora
                                você não perderá nenhuma dose importante.
                            </p>

                            <p className="font-[Nunito] font-normal text-base text-[#272727] mb-6">
                                Será enviado para o seu WhatsApp um aviso quando chegar a hora de tomar o
                                seu medicamento.
                            </p>

                            <div className="btn-container-reminder">
                                <button
                                    onClick={() => props.changePage('createReminder', { action: 'createReminder' })}
                                    className="btn-reminder btn-segundary-reminder" data-cy="btn-criar-novo-lembrete"
                                >
                                    <span>Criar um novo lembrete</span>
                                </button>

                                <button onClick={() => {
                                    props.changePage('logged')
                                }} className="btn-reminder btn-primary-reminder" data-cy="btn-ver-lembretes">
                                    <span>Ver lembretes</span>
                                </button >
                            </div >
                        </div >
                    )}
                    {props.product && (
                        <div className='cards-app-apart-reminder'>
                            <ProductPresentation onProductClick={() => { }} variant='card' products={[props.product]} />
                        </div>
                    )}
                </div >
            )}
        </>
    )
}