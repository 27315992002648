
export const RegisterSuccess: React.FC = () => {
    return (
        <div className="p-[36px] bg-white rounded-3xl flex-col justify-center items-center inline-flex gap-y-6 w-[350px] mb-8">
            <div className="self-stretch text-center text-neutral-800 text-2xl font-bold">
                Cadastro Realizado com Sucesso!
            </div>

            <div className="self-stretch text-[#272727] text-base leading-tight">
                Parabéns! Você realizou o cadastro do médico participante com
                sucesso!
            </div>
            <div className="self-stretch text-[#272727] text-base leading-tight">
                Continue divulgando a campanha e realize novos cadastros para ser
                o representante campeão!
            </div>

            <a
                id="btn-back"
                href="https://api.conectapx.sara.com.br/success "
                className="min-w-[220px] h-12 px-6 py-3.5 bg-[#F9B201] rounded-[96px] justify-center items-center gap-2 inline-flex"
            >
                <span className="text-sm font-bold color-[#272727] leading-tight">
                    Sair
                </span>
            </a>
        </div>
    );
}