import { createContext, useEffect } from "react";

interface IScreenContextData {
  isMobile: boolean;
}

export const ScreenContext = createContext<IScreenContextData>(
  {} as IScreenContextData
);

export const ScreenContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const valueScreen: IScreenContextData = { isMobile: window.innerWidth < 720 };

  const handleResize = () => {
    valueScreen.isMobile = window.innerWidth < 720
    console.warn("screen")
  }
  
  
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  
  
  return (
    <ScreenContext.Provider value={valueScreen}>
      {children}
    </ScreenContext.Provider>
  );
};
