import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProductService, IProduct } from "../../../../services/api/product/ProductService";
import GenericBox from '../../../../assets/images/generic-box.webp'
import './autocomplete.css'

interface IAutocompleteProps {
    className?: string;
}

const CDN_URL = "https://cdn.sara.com.br";

const formatSlug = (string: string) => {
    return string.toLowerCase()
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // Normaliza e remove acentos
        .replace(/ç/g, 'c') // Substitui 'ç' por 'c'
        .replace(/\//g, '-')
        .toLowerCase()
        .replace(/[^a-z0-9 -]/gim, "") // Remove caracteres especiais, mantém letras, números, espaços e hífens
        .replace(/ /g, '-') // Troca espaços por hífens
        .replace(/-+/g, '-') // Condensa múltiplos hífens em um único
        .replace(/^-+|-+$/g, ""); // 
};

const Autocomplete: React.FC<IAutocompleteProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [products, setProducts] = useState<IProduct[]>([]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setSearchQuery(params.get('produto') || '');
    }, [location.search]);

    const handleProductClick = (product: IProduct) => {
        if (product.ean1) {
            const productNameSlug = formatSlug(product.productName);
            const productPresentationSlug = product.productPresentation ? formatSlug(product.productPresentation) : '';
            navigate(`/produto/${productNameSlug}-${productPresentationSlug}-${product.ean1}`);
        } else {
            console.error("EAN1 is undefined for product:", product);
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchQuery.length >= 1) {
                fetchProducts(searchQuery);
            }
        }, 300);
        return () => clearTimeout(handler);
    }, [searchQuery]);

    useEffect(() => {
        const fetchProductByEAN = async () => {
            try {
                const product = await ProductService.findOneByEan(searchQuery);
                if (product) {
                    setProducts([product]); 
                } else {
                    setProducts([]); 
                }
            } catch (error) {
                console.error('Erro ao buscar produto:');
                setProducts([]); 
            }
        };
        

        if (searchQuery.length === 13) {
            fetchProductByEAN();
        }
    }, [searchQuery]);

    const fetchProducts = async (query: string) => {
        try {
            const response = await ProductService.findeAll(`?filters={"productName":"${query}"}&limit=3`); // limitado a 3 cards

            if (response) {
                setProducts(response);
            } else {
                setProducts([]);
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };


    const handleEnterSearch = () => {
        if (searchQuery.trim() !== '') { // Verifica se o searchQuery não está vazio
            navigate(`/busca-de-produtos?produto=${searchQuery}`);
        }
        setIsFocused(false);
    };

    const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleEnterSearch();
        } else {
            setIsFocused(true);
        }
    };
    
    const getProductImage = (uniqueId?: string): string => {
        const cleanedUniqueId = uniqueId ? uniqueId.replace(/\D/g, '') : '';
        return cleanedUniqueId
            ? `${CDN_URL}/package/companies/${cleanedUniqueId}.webp`
            : 'fallback-company-image-url';
    };

    const getBrandImage = (uniqueId: string | undefined) => {
        return uniqueId ? `${CDN_URL}/companyLogo/logo-${uniqueId.replace(/\D/g, '')}.webp` : 'fallback-company-logo-url';
    };

    return (
        <div className="relative z-10 w-[600px] container-input">
            <div className="input-button-container">
                <input
                    className={`w-full h-[50px] pl-5 pr-[16px] max-w-[735px] bg-white rounded-lg shadow-xl justify-between items-center inline-flex text-[#272727] text-lg font-medium leading-7 outline-none md:mt-[0px] md:mb-[0px] max-w-[735px] font-['Nunito'] placeholder:text-[#272727] ${isFocused ? 'input-focused' : 'input-not-focused'}`}
                    placeholder="Buscar produto"
                    value={searchQuery}
                    onKeyDown={handleSearchKeyDown}
                    onChange={handleInputChange}
                    onFocus={() => {
                        console.log('Input focused');
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        console.log('Input blurred');
                        setTimeout(() => setIsFocused(false), 200);
                    }}
                    spellCheck="false"
                />

                <div className={`flex items-center cursor-pointer ${isFocused ? 'flex' : 'hidden'}`} onClick={handleEnterSearch}>
                    <span className="search-button">Buscar</span>
                </div>
            </div>

            {isFocused && !!products.length && (
                <div className="autocomplete-container mt-[2px] max-w-[735px]  absolute w-full  flex flex-col shadow">
                    {products.map((product, index) => (
                        <div
                            key={product._id || index}
                            className={`product-item ${index === 0 ? "first-product-item" : ""} ${index === products.length - 1 ? "last-product-item" : ""}`}
                            onClick={() => handleProductClick(product)}
                        >
                            <div className="product-cont">
                                <img
                                    src={getProductImage(product.companyId?.uniqueId)}
                                    alt={`Imagem da companhia de ${product.productName}`}
                                    className="product-company-logo"
                                    onError={(e) => {
                                        e.currentTarget.src = GenericBox
                                    }}
                                />
                                <div className="flex-column">
                                    <span className="product-name">{product.productName || 'Nome indisponível'}</span>
                                    {product.productPresentation && (
                                        <p>{product.productPresentation}</p>
                                    )}
                                </div>
                            </div>
                            <img src={getBrandImage(product.companyId?.uniqueId)} alt={`Logo de ${product.productName}`} className="product-logo" />
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
};

export default Autocomplete;
