import { useNavigate } from "react-router-dom";

export const useProductClick = () => {
    const navigate = useNavigate();
    const handleProductClick = (product: any) => {
        if (product.ean1) {
            const formatSlug = (string: string) => {
                return string
                    .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    .replace(/ç/g, 'c')
                    .replace(/\//g, '-')
                    .toLowerCase()
                    .replace(/[^a-z0-9 -]/g, "")
                    .replace(/ /g, '-')
                    .replace(/-+/g, '-')
                    .replace(/^-+|-+$/g, "");
            };
            const productNameSlug = formatSlug(product.productName);
            const productPresentationSlug = product.productPresentation ? formatSlug(product.productPresentation) : '';
            console.log('Product name slug:', productNameSlug);
            navigate(`/produto/${productNameSlug}-${productPresentationSlug}-${product.ean1}`);
            console.log('Product clicked:', product);
        } else {
            console.error("EAN is undefined for product:", product);
        }
    };
    return { handleProductClick }
}

