import { Link, useLoaderData } from "react-router-dom";
import { LoaderParams } from "../../types/LoaderParams";
import {
    ProductService,
    IProduct,
    GAService,
    ApiException,
} from "../../services";
import "./index.css";
import ProductPresentation from "../../components/ProductPresentation";
import ProductReminderForm from "../product/ProductReminderForm";
import iFundoCaixinhaRemedio from "../../assets/images/img-fundo-caixinha-remedio.webp";
import iNotificationRecall from "../../assets/icons/i-notification-recall.svg";
import iCheckRecallNotificationWarning from "../../assets/icons/i-check-recall-notification-warning.svg";
import iCheckRecallNotificationSuccess from "../../assets/icons/i-check-recall-notification-success.svg";
import iSacHeadset from "../../assets/icons/i-sac-headset.svg";
import { useFormik } from "formik";
import { RecallService } from "../../services/api/recall/RecallService";
import { useState } from "react";
import { useLocalStorage } from "../../hooks";
import { DocumentTitle } from "../../components";

interface IRecallParams {
    product: IProduct | undefined;
    productImageUrl: string;
    brandImageUrl: string;
}

async function getProduct(productEan: string) {
    let result = {} as IRecallParams;
    result.product = await ProductService.findOneByEan(productEan);

    // Verifique se os dados do produto estão disponíveis
    if (result.product) {
        result.productImageUrl = ProductService.getProductImageUrl(
            result.product.companyId?.uniqueId
        );
        result.brandImageUrl = ProductService.getBrandImageUrl(
            result.product.companyId?.uniqueId
        );
    }
    return result;
}

export async function LoaderRecall(props: LoaderParams): Promise<any> {
    return getProduct(props.params.id);
}

export const Recall = () => {
    const loadedParams = useLoaderData() as IRecallParams;
    const isLoggedIn = useLocalStorage().getData('token');
    const [isCheckedRecall, setIsCheckedRecall] = useState(false);
    const [isWarningRecall, setIsWarningRecall] = useState(false);
    const [allotmentRecall, setAllotmentRecall] = useState('');
    const [productNameRecall, setProductNameRecall] = useState('');
    

    const checkAllotmentSubmit =async (allotment: string, productId: string)=>{
        try {
            const FILTERS = `{"allotment":"${allotment}", "productId":"${productId}"}`;
            const res =  await RecallService.checkAllotment(FILTERS);
            const newData = res.data.data.result?.length > 0 ? res.data.data.result[0] : null;
           
            if (res.status === 200){
                setIsCheckedRecall(true);
                setIsWarningRecall(!!newData?._id);
            
      
            if (newData?._id) {
              setProductNameRecall(newData.productId.productName);
              setAllotmentRecall(newData.allotment);
            }
        }
          } catch (error: any) {
            console.error('error', error);
          }
    }

    const formik = useFormik({
        initialValues: {
            allotment: "",
        },
        onSubmit: (values) => {
            try {
                GAService.sendEvent("cadastrar_lote");

                RecallService.registerAllotment({
                    allotment: values.allotment,
                    productId: loadedParams.product!._id,
                }).then((res) => {
                    if (res instanceof ApiException) {
                        console.log(res.message);
                    } else {
                        if (res.status === 201)
                            checkAllotmentSubmit(values.allotment, loadedParams.product!._id);
                    }
                });
            } catch (error: any) {
                console.error("error", error);
            }
        },
    });

    return (
        <DocumentTitle
            title="Recall de Medicamento"
            description="A página de informar lote de recall da plataforma Sara é projetada para permitir que os usuários informem a plataforma sobre lotes específicos de medicamentos ou produtos de saúde que podem estar sujeitos a um recall. A página é projetada para ser fácil de usar e permite que os usuários forneçam informações importantes sobre o lote em questão. A página de informar lote de recall da plataforma Sara é projetada para permitir que os usuários informem a plataforma sobre lotes específicos de medicamentos ou produtos de saúde que podem estar sujeitos a um recall. A página é projetada para ser fácil de usar e oferece um formulário simples e instruções claras para que os usuários possam relatar lotes de recall com rapidez e facilidade."
        >
            <div className="general-container-form container-recall  flex justify-start items-start gap-6  pt-2">
                <div className="general-container-form flex justify-start items-start gap-6 pl-1 ">
                    {isLoggedIn && !isCheckedRecall && (
                        <div className="w-full bg-white p-4 flex flex-col justify-center rounded-[24px] max-w-[728px]">
                            <div className="flex flex-col justify-center items-center mb-2">
                                <div className="w-full flex justify-start items-center gap-2 mb-6">
                                    <img
                                        src={iNotificationRecall}
                                        alt="icone de notificação"
                                    />
                                    <span className="font-[Nunito] text-2xl font-bold text-[#272727]">
                                        Verifique o Recall do seu Medicamento
                                    </span>
                                </div>
                                <span className="w-full font-[Nunito] text-[18px] font-bold block text-[#272727] mb-6">
                                    Acompanhe informações sobre seu medicamento.
                                </span>
                                <p className="font-[Nunito] text-base text-[#272727] mb-6">
                                    Com a Sara, você pode verificar se o seu
                                    medicamento teve algum recall informando o
                                    número do lote. Caso o lote do seu medicamento
                                    precisar ser recolhido, conseguimos avisar você
                                    através do WhatsApp.
                                </p>
                            </div>

                            <div className="card-recall flex justify-center">
                                <img
                                    className="ml-8"
                                    src={iFundoCaixinhaRemedio}
                                    alt="imagem do fundo da caixinha de remédio"
                                />
                                <form
                                    onSubmit={formik.handleSubmit}
                                    className="form-base w-full"
                                >
                                    <div className="mb-6">
                                        <p>Qual o lote do medicamento?</p>
                                        <input
                                            id="allotment"
                                            name="allotment"
                                            autoFocus
                                            type="text"
                                            required
                                            placeholder="2N3580"
                                            className="font-[Nunito] font-base font-normal leading-5"
                                            maxLength={20}
                                            onChange={formik.handleChange}
                                            value={formik.values.allotment}
                                        />
                                    </div>

                                    <div className="mt-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-register-allotment"
                                            disabled={!formik.isValid}>
                                            Cadastrar Lote
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {isLoggedIn && isCheckedRecall && (
                        <div className="card-check-recall w-full bg-white flex flex-col justify-center rounded-[24px] max-w-[728px]">
                            {!isWarningRecall && (
                                <div>
                                    <div className="flex flex-col justify-center items-center mb-2">
                                        <div className="w-full flex flex-col justify-start items-center gap-2 mb-6">
                                            <img
                                                src={
                                                    iCheckRecallNotificationSuccess
                                                }
                                                alt="icone de notificação com sucesso ao encontrar recall"
                                            />
                                            <span className="font-[Nunito] text-2xl font-bold text-[#56AAB7] text-center">
                                                Lote Cadastrado com Sucesso!
                                            </span>
                                        </div>
                                        <span className="w-full font-[Nunito] text-[18px] font-bold text-[#272727] text-center leading-7 mb-6">
                                            Não foi encontrada nenhuma determinação
                                            de recolhimento para esse medicamento.
                                        </span>
                                        <p className="w-full font-[Nunito] text-[18px] text-medium text-[#272727] leading-7 mb-6">
                                            Para ser avisado imediatamente sobre
                                            qualquer inconformidade com o lote deste
                                            medicamento, crie um lembrete e
                                            informaremos diretamente em seu
                                            WhatsApp.
                                        </p>
                                        <p className="w-full font-[Nunito] text-[18px] text-medium text-[#272727] leading-7 mb-6">
                                            Aproveite a oportunidade para criar uma
                                            conta na Sara, tenha acesso a esses e
                                            muitos outros benefícios à disposição da
                                            sua saúde.
                                        </p>
                                    </div>

                                    <div className="btn-container w-full flex mb-6">
                                        <Link
                                            to="/lembretes"
                                            className="btn btn-primary"
                                        >
                                            Criar lembrete
                                        </Link>
                                        <Link to="/" className="btn btn-segundary">
                                            Voltar para a Home
                                        </Link>
                                    </div>

                                    <p className="w-full font-[Nunito] text-[18px] text-medium text-[#272727] leading-7 mb-6">
                                        Caso tenha alguma dúvida entre em contato
                                        com o SAC do laboratório pela:
                                    </p>

                                    <div className="flex justify-center items-start gap-2 mb-6">
                                        <img
                                            src={iSacHeadset}
                                            alt="icone de falar com SAC"
                                        />
                                        <div className="flex flex-col gap-6">
                                            <span className="font-[Nunito] text-[18px] font-bold leading-6 text-[#242424]">
                                                Central de Atendimento
                                            </span>
                                            <span className="font-[Nunito] text-base font-normal leading-5 text-[#242424]">
                                                0800 019 1914
                                            </span>
                                            <span className="font-[Nunito] text-base font-normal leading-5 text-[#242424]">
                                                Segunda à sexta das 09:00 às 17:00
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isWarningRecall && (
                                <div>
                                    <div className="flex flex-col justify-center items-center mb-2">
                                        <div className="w-full flex flex-col justify-start items-center gap-2 mb-6">
                                            <img
                                                src={iCheckRecallNotificationWarning}
                                                alt="icone de notificação ao encontrar recall"
                                            />
                                            <span className="font-[Nunito] text-2xl font-bold text-[#F9B201] text-center">
                                                Importante Aviso de Recall de
                                                Medicamento
                                            </span>
                                        </div>
                                        <p className="w-full font-[Nunito] text-[18px] text-medium text-[#272727] leading-7 mb-6">
                                            Queremos informar que foi identificado
                                            um recall para o medicamento{" "}
                                            {productNameRecall} com o número de lote{" "}
                                            {allotmentRecall}. Sua segurança e
                                            bem-estar são nossa maior preocupação.
                                        </p>
                                        <span className="w-full font-[Nunito] text-[18px] font-bold text-[#272727] leading-7 mb-6">
                                            Ações Recomendadas:
                                        </span>
                                        <p className="w-full font-[Nunito] text-[18px] text-medium text-[#272727] leading-7 mb-6">
                                            Pedimos que suspenda imediatamente o uso
                                            deste medicamento. É fundamental entrar
                                            em contato com o laboratório fabricante
                                            do medicamento ou com seu profissional
                                            de saúde para obter orientações
                                            específicas sobre como proceder.
                                        </p>
                                    </div>

                                    <div className="flex justify-center items-start gap-2 mb-6">
                                        <img
                                            src={iSacHeadset}
                                            alt="icone de falar com SAC"
                                        />
                                        <div className="flex flex-col gap-6">
                                            <span className="font-[Nunito] text-[18px] font-bold leading-6 text-[#242424]">
                                                Central de Atendimento
                                            </span>
                                            <span className="font-[Nunito] text-base font-normal leading-5 text-[#242424]">
                                                0800 019 1914
                                            </span>
                                            <span className="font-[Nunito] text-base font-normal leading-5 text-[#242424]">
                                                Segunda à sexta das 09:00 às 17:00
                                            </span>
                                        </div>
                                    </div>

                                    <div className="w-full flex justify-center mb-2 md:hidden">
                                        <Link to="/" className="btn btn-primary">
                                            Voltar para a Home
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="cards-app">
                    {loadedParams.product && (
                        <div>
                            <ProductPresentation
                                products={[
                                    {
                                        ...loadedParams.product!,
                                        id: loadedParams.product!._id,
                                    },
                                ]}
                                onProductClick={() => null}
                            />

                            <ProductReminderForm 
                                productId={loadedParams.product!._id}
                            />
                        </div>
                    )}
                </div>
            </div>
        </DocumentTitle>
    );
};
