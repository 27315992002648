import { useEffect, useState } from "react"
import { IReminder } from "../../../types"
import { ApiException, DailyReminderService } from "../../../services"
import IDailyReminder from "../../../assets/icons/i-daily-reminder.svg"
import IArrowMoreReminders from "../../../assets/icons/i-arrow-more-reminders.svg"
import IEditReminder from "../../../assets/icons/i-edit-reminder.svg"
import './index.css'
import { useGA } from "../../../hooks"
import { useNavigate } from "react-router-dom"
import { time } from "console"
import { Link } from "react-router-dom"
export const DailyReminder = () => {
    const { sendGAEvent } = useGA();
    const navigate = useNavigate();
    const [reminders, setReminders] = useState<IReminder[]>([])
    const [today, setToday] = useState<number>(new Date().setHours(23, 59, 59, 0))
    const options: any = { day: 'numeric', month: 'long', timeZone: 'America/Sao_Paulo' }

    const formatDate = (timestamp: number) : string => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('pt-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    }
    useEffect(() => {
        DailyReminderService.findAll()
            .then((res) => {
                if (res instanceof ApiException || res.data.result.length === 0) return

                setReminders((res.data.result ?? [])
                    .map((reminder: any) => {
                        let startTimestamp = new Date(
                            reminder.startTimestamps.sort()[0] * 1000
                        ).setHours(0, 0, 0, 0);

                        if (startTimestamp > today) {
                            return null
                        }

                        startTimestamp = today;
                        
                        return {
                            _id: reminder._id,
                            taken: false,
                            forgotten: false,
                            forgottenTime: null,
                            name: reminder.productId?.productName,
                            dates: reminder.startTimestamps.sort(),
                            dose: null,
                        };
                    })
                    .filter((reminder: any) => reminder)
                    .sort((a: any, b: any) => a.startTimestamp - b.startTimestamp)
                    .slice(0, 2));
            })
    }, [])

    const handleShowAllReminders = () => {

    }
    const navigateToReminder = (reminderId?: any) => {
        if (reminderId) {
            sendGAEvent('lembrete_dia');
            navigate(`/lembretes?action=edit&id=${reminderId}`);            
        }
    }
    return (
        <>
            {reminders.length > 0 && (
                <div
                    className="w-full px-[16px] reminders-home mt-[36px]"
                >
                    <div className="header-daily-reminder mb-[0px]">
                        <div className="label-reminder">
                            <img
                                src={IDailyReminder}
                                alt="icone lembres do dia"
                            />
                            Lembretes do dia:
                        </div>
                        <div
                            className="more-reminders cursor-pointer min-w-[101px] flex pl-[9px] pr-[5px]"
                            onClick={handleShowAllReminders} data-cy="ver-todos-reminders"
                        >
                            <Link to="/lembretes">Ver todos &nbsp;</Link>
                            <img src={IArrowMoreReminders} alt="Seta" />
                        </div>
                    </div >

                    <div className="cards-reminders">
                        {reminders.map((reminder) => (
                            <div className="card-reminder" >
                                <div className="details-reminder w-full">
                                    <div className="flex justify-between gap-[16px]">
                                        <strong>{reminder.name}</strong>
                                        <div
                                            className="actions-btns cursor-pointer"
                                            onClick={() => navigateToReminder(reminder._id)} data-cy="botao-editar-reminders"
                                        >
                                            <img
                                                src={IEditReminder}
                                                alt="Ícone de edição"
                                                className="min-w-[24px]"
                                            />
                                        </div>
                                    </div>

                                    <p className="w-full">
                                        {new Date(today).toLocaleString('pt-BR', options)} -
                                        {reminder.dates.map((date, i) => (
                                            <span key={i}> &nbsp;
                                                {new Date(date * 1000).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', timeZone: 'America/Sao_Paulo' })}h
                                               &nbsp; {i !== reminder.dates.length - 1 && <span>|</span>} 
                                            </span>
                                        ))}
                                    </p>

                                </div >
                            </div >
                        ))}

                    </div >
                </div >
            )}

        </>
    )
}