import { Form, Field, Formik, FieldArray } from "formik"
import * as Yup from "yup"
import ICloseGray from "../../../assets/icons/i-close-gray.svg"
import IError from "../../../assets/icons/i-error.svg";
import ITrash from "../../../assets/icons/i-trash.svg"
import IAddColorBlues from "../../../assets/icons/i-add-color-blues.svg"
import IEditReminderColorBlack from "../../../assets/icons/i-edit-reminder-color-black.svg"
import ITrashColorGray from "../../../assets/icons/i-trash-color-gray.svg"
import IArrowLeft from "../../../assets/icons/i-arrow-left.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ValidationSchema = Yup.object().shape({
    startTimestamps: Yup.date().nonNullable().required('Campo obrigatório'),
    finishTimestamp: Yup.date().nullable(),
    ingestionArray: Yup.array().of(Yup.string().required('Hora é obrigatória')).required('pelo menos um horário obrigatório')
})


interface IModalEditReminder {
    ValidationSchema: any;
    openModalEdit: boolean;
    isLoading: boolean;
    openModalDeleteReminder: boolean;
    ingestionArray: string[];
    closeModalEdit: () => void;
    minDateInitial: string;
    editReminderFormSubmit: (values: any) => void;
    setOpenModalDeleteReminder: (openModalDeleteReminder: boolean) => void;
    deleteReminder: () => void;
    reminderId: any;
    defaultEditForm: any;
}



export const ModalEditReminder: React.FC<IModalEditReminder> = (props) => {
    return (
        <>
            {props.openModalEdit && !props.isLoading && (
                <div className="modal-container-reminder">
                    {!props.openModalDeleteReminder && (
                        <div
                            className={`${props.defaultEditForm.ingestion.length > 3 ? 'max-h-[680]' : ''} w-full max-w-[680px] h-auto min-h-[445px] p-[36px] rounded-[16px] bg-white overflow-auto scroll-editor`}
                        >
                            <div className="header w-full mb-8">
                                <button className="block ml-auto mb-2" onClick={props.closeModalEdit} data-cy="close-modal-edit">
                                    <img
                                        src={ICloseGray}
                                        alt="ícone de fechar modal"
                                    />
                                </button>
                                <span className="font-[Nunito] font-bold text-2xl text-[#272727]">Detalhes</span>
                                <p className="font-[Nunito] font-normal text-base text-[#272727]">
                                    Personalize o lembrete do seu medicamento de acordo com a sua rotina e
                                    deixe o WhatsApp lembrar você na hora certa. Simples, eficaz e sempre ao
                                    alcance do seu celular.
                                </p>
                            </div >

                            <Formik
                                initialValues={{ startTimestamps: props.defaultEditForm.startTimestamps || null, finishTimestamp: props.defaultEditForm.finishTimestamp || null, ingestionArray: props.defaultEditForm.ingestion || [{}] }}
                                // id="reminderForm"
                                validationSchema={ValidationSchema}
                                onSubmit={(values) => {
                                    props.editReminderFormSubmit(values)
                                    // console.log(JSON.stringify(values))
                                }}
                            >
                                {({ errors, touched, isValid, setFieldValue, values }) => (
                                    <Form id='reminderForm'>
                                        <div className="form flex flex-col">
                                            <div className="flex gap-5 w-full h-full">
                                                <div className="mb-2 w-full">
                                                    <p className="font-[Nunito] text-sm text-[#272727]">Data de início:</p>
                                                    <Field
                                                        className="text-content w-full p-2 rounded-[20px] bg-[#F0F5F6] outline-[#2ED9C2]"
                                                        name="startTimestamps"
                                                        type="date"
                                                        // (ngModelChange)="minDateFinal = $event"
                                                        // (blur)="validateDateInitial()"
                                                        data-cy="start-date"
                                                    />
                                                    {errors.startTimestamps && touched.startTimestamps && (
                                                        <div className="text-xs font-normal text-[#9E9E9E] mt-2"
                                                        >
                                                            <img
                                                                src={IError}
                                                                alt="Ícone de erro"
                                                                className="float-left"
                                                            />
                                                            {/* &nbsp; {errors.startTimestamp}. */}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="w-full mb-2">
                                                    <p className="font-[Nunito] text-sm text-[#272727]">Data de término:</p>
                                                    <Field
                                                        className="text-content w-full p-2 rounded-[20px] bg-[#F0F5F6] outline-[#2ED9C2]"
                                                        type="date"
                                                        name="finishTimestamp"
                                                        // min="{{ minDateFinal | date : 'yyyy-MM-dd' }}"
                                                        // (blur)="validateDateEnd()"
                                                        data-cy="end-date"
                                                    />
                                                    {errors.finishTimestamp && touched.finishTimestamp && (
                                                        <div
                                                            className="text-xs font-normal text-[#9E9E9E] mt-2"
                                                        >
                                                            <img
                                                                src={IError}
                                                                alt="Ícone de erro"
                                                                className="float-left"
                                                            />
                                                            {/* &nbsp; {errors.finishTimestamp}. */}
                                                        </div>
                                                    )}

                                                </div>

                                            </div>


                                            {/* ingestionArrayControl  */}
                                            <FieldArray name="ingestionArray">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.ingestionArray.map((time: string, index: number) => (
                                                            <div key={index}>
                                                                <div className="flex justify-between gap-4 mt-4 mb-2 w-[50%]">
                                                                    <div className="w-full">
                                                                        <p className="font-[Nunito] text-sm text-[#272727]">
                                                                            Qual o horário de ingestão?
                                                                        </p>
                                                                        <Field
                                                                            type="time"
                                                                            name={`ingestionArray[${index}]`}
                                                                            className="text-content w-full p-2 rounded-[20px] bg-[#F0F5F6] outline-[#2ED9C2]"
                                                                            data-cy="time"
                                                                        />
                                                                    </div>
                                                                    {values.ingestionArray.length > 1 && (
                                                                        <button
                                                                            className="pt-[20px]"
                                                                            onClick={() => remove(index)}
                                                                            data-cy="delete-time"
                                                                        >
                                                                            <img
                                                                                src={ITrash}
                                                                                className="w-full"
                                                                                alt="ícone de excluir horário"
                                                                            />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                        <button
                                                            onClick={() => push('')}
                                                            className="flex gap-2 justify-center items-center pb-[8px] pt-[16px] mb-4 mr-auto font-[Nunito] text-sm font-bold text-[#56AAB7]"
                                                            data-cy="add-time">
                                                            <img src={IAddColorBlues} alt="ícone de adição" />
                                                            <span>Adicionar horário</span>
                                                        </button>
                                                    </>
                                                )}
                                            </FieldArray>


                                            <div className="btn-container-reminder mt-auto !flex-row justify-between">
                                            <div className="btn-container-reminder mt-auto !flex-row justify-between">
                                            <button
                                                className="btn-reminder btn-primary-reminder"
                                                disabled={!isValid}
                                                type="submit"
                                                data-cy="save-reminder"
                                            >
                                                <img src={IEditReminderColorBlack} alt="ícone" />
                                                <span>Salvar Alterações</span>
                                            </button>
                                            {/* Outros botões */}
                                        </div>

                                                <button
                                                    onClick={() => props.setOpenModalDeleteReminder(true)}
                                                    className="btn-reminder btn-segundary-reminder gap-2"
                                                    data-cy="delete-reminder"
                                                >
                                                    <img
                                                        src={ITrashColorGray}
                                                        alt="ícone "
                                                    />
                                                    <span>Excluir</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik >
                        </div >
                    )}

                    {props.openModalDeleteReminder && (
                        <div className="w-full max-w-[680px] mx-[16px] h-auto min-h-[445px] p-[36px] rounded-[16px] bg-white overflow-auto">
                            <button className="block ml-auto mb-2" onClick={props.closeModalEdit} data-cy="close-modal-edit">
                                <img
                                    src={ICloseGray}
                                    alt="ícone de fechar modal"
                                />
                            </button>
                            <div className="header w-full pb-[32px] sm:px-[80px]">
                                <span
                                    className="font-[Nunito] text-center font-bold text-2xl mb-3 text-[#272727]"
                                >
                                    Tem certeza de que deseja excluir este lembrete? Esta ação não pode ser
                                    desfeita.
                                </span>
                                <p
                                    className="font-[Nunito] font-normal text-center text-base text-[#272727] mb-3"
                                >
                                    Lembre-se de que, ao excluir este lembrete, você perderá todas as
                                    informações associadas a ele. Certifique-se de que esta é a ação que
                                    deseja tomar.
                                </p>

                                <div className="btn-container-reminder mb-3">
                                    <button className="btn-reminder btn-segundary-reminder" onClick={props.closeModalEdit} data-cy="decline-delete">
                                        <img src={IArrowLeft} alt="ícone de seta para a esquerda " />
                                        <span>Voltar</span>
                                    </button>

                                    <button
                                        className="btn-reminder btn-primary-reminder"
                                        disabled={!props.reminderId}
                                        onClick={props.deleteReminder}
                                        data-cy="confirm-delete"
                                    >
                                        <img
                                            src={ITrashColorGray}
                                            alt="ícone "
                                        />
                                        <span>Excluir</span>
                                    </button>
                                </div>

                                <p className="font-[Nunito] font-normal text-center text-sm text-[#272727]">
                                    Se você tiver alguma dúvida ou preocupação, sinta-se à vontade para
                                    entrar em contato conosco para obter assistência.
                                </p>
                            </div>
                        </div >
                    )}
                </div >
            )}
        </>
    )
}