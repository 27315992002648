import React, { useEffect, useState } from 'react';
import './favorito.css';
import { getFavourites, deleteFavourite } from '../../services/api/favourite/FavoriteService';
import ImgFavorite from '../../assets/images/img-mulher-sentada.webp';
import ImgPerfil from '../../assets/icons/i-perfil.svg';
import IFavoritosActive from '../../assets/icons/i-favoritos-active.svg'
import INotification from '../../assets/icons/i-notification.svg'
import IPrescriptionBgBlue from '../../assets/icons/i-prescription-bg-blue.svg'
import ITrashBgRed from '../../assets/icons/i-trash-bg-red.svg'
import ILupaProduto from '../../assets/icons/i-lupa-produto.svg'
import { useIsBusy, useLocalStorage, useProduct } from '../../hooks';
import { ApiException } from '../../services';
import ProductPresentation from '../../components/ProductPresentation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IProduct } from '../../types';
import { useProductClick } from '../../hooks/UseProductClick';
import { DocumentTitle } from '../../components';

interface Favourite {
    _id: string;
    productId: IProduct;
}

const Favorito = () => {
    const { setIsBusy } = useIsBusy();
    const { handleProductClick } = useProductClick();
    const productHook = useProduct();
    const navigate = useNavigate();
    const { getData } = useLocalStorage();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(getData('token'));
    const [favourites, setFavourites] = useState<Favourite[]>([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const actions = [
        {
            type: 'link',
            title: 'Lembrete',
            icon: INotification,
            action: (product: IProduct) =>
                navigateToReminder(product.reminderId)
        },
        {
            type: 'link',
            title: 'Ver produto',
            icon: IPrescriptionBgBlue,
            action: (product: any) => navigateToProduct(product),
        },
        {
            type: 'delete',
            title: 'Excluir favorito',
            icon: ITrashBgRed,
            action: (product: IProduct) =>
                handleDelete(product._id)
        },
    ]
    const navigateToProduct = (product?: IProduct) => {
        if (product) navigate(productHook.getProductURL(product))
    }
    const navigateToReminder = (id?: string) => {
        if (id) {
            navigate(`/lembretes?id=${id}&action=edit`)
        }
    }

    useEffect(() => {
        if (getData('token')) {
            updateFavourites()
        }
        
    }, []);

    const updateFavourites = () => {
        setIsBusy(true)
        getFavourites()
            .then((res) => {
                if (res instanceof ApiException) {
                    setIsBusy(false)
                    console.log(res)
                    setIsLoggedIn(false)
                    setIsLoading(false)
                } else {
                    setIsBusy(false)
                    setFavourites(res.data.result)
                    setProducts(res.data.result.map((fav: { productId: any }) => fav.productId))

                    setIsLoading(false)
                }
            })
    }

    const handleDelete = (id: string) => {
        setIsBusy(true)
        deleteFavourite(id)
            .then((res) => {
                if (res instanceof ApiException) {
                    setIsBusy(false)
                    console.log(res)
                    return
                }
                updateFavourites();
            });
    };


    // Logged in user's UI
    return (
        <DocumentTitle
            title="Favoritos"
            description="Salve aqui os seus medicamentos favoritos para não esquecer mais como tomar, quando usar, indicações e muito mais. Facilite a sua rotina!"
        >
            {/* {isLoading && (
                <div className="flex justify-center items-center h-[560px]">
                    <div className=" w-[50px] h-[50px] border-[3px] rounded-full border-gray-300 border-l-gray-400"></div>
                </div>
            )} */}
            {!isLoggedIn && (
                <div className="flex justify-center padding-fix w-full mt-[24px] mb-[24px]">
                    <div className="general-container-favorite ">
                        <img className="-mb-[54px]" src={ImgFavorite} alt="Mulher sentada usando um notebook" />
                        <div className="card-content">
                            <p className="title">Tenha seus Medicamentos Favoritos Sempre à Mão!</p>
                            <div className="text-container">
                                <span className="text-content">Queremos tornar sua experiência de cuidados com a saúde ainda melhor! Com uma conta, você pode salvar seus medicamentos favoritos para fácil acesso posterior.</span>
                                <span className="text-content">Além disso, acompanhe recalls importantes e mantenha um histórico de seus medicamentos. Crie sua conta agora para aproveitar ao máximo nossos recursos e manter seus cuidados com a saúde sob controle.</span>
                            </div>
                            <div className="button-container">
                                <Link className="btn-create-account" to="/cadastro">Criar uma Conta</Link>
                                <Link id="btn-login" className="cursor-pointer" to="/login">
                                    <img src={ImgPerfil} alt="icone perfil" className="h-[24px] w-[24px] mr-[8px]" />
                                    Entrar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && isLoggedIn && (
                <div className="flex justify-center w-full mt-[24px] mb-[24px]">
                    <div className="general-list-container flex flex-col justify-center items-center mb-6">
                        <div className="w-full flex justify-center items-center gap-4 md:justify-start md:pl-[16px]">
                            <div className="justify-start items-center gap-2 flex mb-[22px]">
                                <div className="w-10 h-10 p-1.5 bg-cyan-50 rounded-lg justify-center items-center flex">
                                    <div className="w-7 h-7 relative flex-col justify-center items-center flex">
                                        <img src={IFavoritosActive} alt="Icone de coracao" />
                                    </div>
                                </div>
                                <div className="page-main-title px-[16px] text-neutral-800 text-3xl font-bold font-['Nunito-Medium'] leading-9">
                                    Favoritos
                                </div>
                            </div>

                        </div>

                        {products.length > 0 && (
                            <div className="w-full">
                                <ProductPresentation products={products} actions={actions} variant='list' onProductClick={handleProductClick} />
                            </div>
                        )}
                        {products.length <= 0 && (
                            <div className="max-w-[600px] flex flex-col justify-center items-center gap-6 pl-5 py-3 pr-2 rounded-[10px] mb-4">
                                <img src={ILupaProduto} alt="ícone de vários medicamentos" className="w-[96px]" />
                                <span className="font-[Nunito] text-[32px] font-bold text-center tracking-tighter">
                                    Ainda não temos favoritos para exibir
                                </span>
                                <span className="font-[Nunito] text-[20px] font-medium text-center">
                                    Para exibir resultados aqui, busque por medicamentos na guia de produtos, confira a bula e marque-a como favorito
                                </span>
                                <Link to="/busca-de-produtos" className="btn btn-primary cursor-pointer">
                                    Adicionar Favorito
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </DocumentTitle>
    );
};

export default Favorito;
