import { styled } from "styled-components";

interface IButtonProps{
  isDisabled: boolean
}

export const Button = styled.button.attrs<IButtonProps>({
  className: "w-[220px] h-12 px-6 py-3.5 rounded-[96px] border-[1px] border-[#56AAB7] justify-center items-center gap-2 inline-flex"
})`
  background-color: ${props => props.isDisabled ? '#9E9E9E' : 'white'}
`

export const DivButton = styled.div.attrs<IButtonProps>({
  className:"text-sm font-bold font-['Nunito'] leading-tight"
})`
  color: ${props => props.isDisabled ? '#000' : '#4fd1c5'}
`