import styled from 'styled-components';

export const AsideContainer = styled.aside`
    width: 268px;
    background-color: #ffffff;
    color: #727272;
    position: fixed;
    top: 16px !important;

    left: 24px;
    padding: 36px 21px 21px 21px;  // Use transient prop for padding
    border-radius: 1.4rem;
    min-height: 580px;  // Adjusted for consistency
    box-shadow: 2px 4px 8px 0px rgba(73, 73, 73, 0.16);
    z-index: 16;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 768px) {
        display: none;
    }
            
    nav ul {
        display: flex;
        flex-direction: column;
        padding: 0;

        @media screen and (max-height: 720px) {
            gap: 0 !important;
        }

        a {
            color: #727272;
            display: flex;
            gap: 5%;
            align-items: center;
            text-decoration: none;
            font-family: "Nunito-Regular";
            margin-bottom: 3%;
            border-radius: 0.6rem;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            line-height: 11.077px;
            padding: 9px 10px;

            p {
                margin-bottom: 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
`

export const LogoSaraImg = styled.img`
    margin-bottom: 50px;
`

export const Navbar = styled.nav.attrs({})`
    .active{
        background: linear-gradient(
                        92deg,
                        rgba(50, 218, 195, 0.2) -3.4%,
                        rgba(39, 142, 235, 0.2) 101.42%
                    );
    }
`

export const ButtonCreateAccount = styled.a.attrs({
    className: "mt-[41px] cursor-pointer"
})`
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: 2% 4%;
    border-radius: 2rem;
    background: #f9b201;
    box-shadow: 0px 8px 8px 0px rgba(249, 178, 1, 0.25);
    color: #000;
    text-decoration: none;
    font-family: "Nunito-Bold";
`

export const ButtonLogin = styled.a.attrs({
    className: "cursor-pointer"
})`
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    padding: 2% 4%;
    border-radius: 2rem;
    background: #fff;
    border: 2px solid #000;
    color: #000;
    text-decoration: none;
    font-family: "Nunito-Bold";
`

export const ButtonLogout = styled.a.attrs({
    className: "cursor-pointer"
})`
    color: #727272;
    display: flex;
    gap: 5%;
    align-items: center;
    text-decoration: none;
    font-family: "Nunito-Regular";
    margin-top: 40px;
    margin-bottom: 3%;
    border-radius: 0.6rem;
    padding: 5% !important;
    color: #727272;
    font-family: "Nunito-Regular";
    font-weight: 600;
`