import React, { useState } from 'react';
import { Dialog, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '../../../../assets/icons/i-share-green.svg'; // Caminho hipotético
import CloseModalIcon from '../../../../assets/icons/i-close-gray.svg'; // Caminho hipotético
import { IProduct } from '../../../../services';
import "./share-modal.css";

interface ShareProductModalProps {
  open: boolean;
  handleClose: () => void;
  product: IProduct;
}

function ShareProductModal({ open, handleClose, product }: ShareProductModalProps) {
  const [linkCopied, setLinkCopied] = useState(false);
  const productLink = `www.sara.com.br/produto/${product?.ean1}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(productLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
        handleClose();
      }, 2000);
    });
  };

  const shareViaWhatsapp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(productLink)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="share-dialog-title">
      <div className="modal-container">
        {!linkCopied ? (
          <div className="w-[541px] h-auto p-6 ronded-fix bg-white overflow-auto">
            <div className="flex mb-2 items-center gap-3">
              <img src={ShareIcon} alt="ícone de compartilhar" />
              <Typography className="ml-2 text-lg font-medium">Compartilhar Bula</Typography>
              <IconButton className="block close ml-auto position-absolute" onClick={handleClose}>
                <img src={CloseModalIcon} alt="ícone de fechar modal" />
              </IconButton>
            </div>
            <Typography className="font-[Nunito] font-normal text-sm text-[#6A6F75] mb-2">
              Compartilhe a bula do {product?.productName} via:
            </Typography>
            <div className="mb-2 flex">
              <IconButton onClick={shareViaWhatsapp}>
                <WhatsAppIcon style={{ color: '#25D366' }} />
              </IconButton>
            </div>
            <Typography className="font-[Nunito] font-normal text-sm text-[#6A6F75] mb-2">
              Ou copie o link:
            </Typography>
            <div className="flex mb-2">
              <Typography variant="body2" style={{ flexGrow: 1, backgroundColor: '#f0f0f0', padding: 8 }}>
                {productLink}
              </Typography>
              <Button variant="contained" style={{ marginLeft: 8 }} onClick={copyToClipboard}>
                Copiar
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-[541px] h-auto p-6 rounded-[20px] bg-white overflow-auto">
            <div className="flex mb-2">
              <Typography className="text-lg w-full font-medium truncate py-2">Link copiado!</Typography>
              <Button variant="contained" style={{ backgroundColor: '#56AAB7', color: 'white' }} onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default ShareProductModal;
