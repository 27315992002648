import styled from "styled-components";

export const Container = styled.div.attrs({
    className: "flex flex-col min-h-screen w-full p-[16px]"
})`
    background:linear-gradient(180deg, #32dac3 0%, #278eeb 100%);
`

export const BackHomeButton = styled.button.attrs({

})`
width: 220px;
  height: 48px;
  background-color: #f9b201;
  box-shadow: 0px 8px 8px 0px #f9b20140;
  border-radius: 96px;
  margin: 0 auto 36px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`