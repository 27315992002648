import styled from "styled-components"

export const FormBase = styled.div.attrs({
  className: "w-full"
})`
  margin-top: -20px;

  & p {
    display: inline;
    position: relative;
    bottom: -10px;
    margin-left: 20px;
    padding: 0 11px;
    background-color: white;

    font-family: "Nunito-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6a6f75;
  }

  & input,
  select {
    width: 100%;
    height: 60px;
    display: block;
    padding: 16px 8px;
    border-radius: 8px;
    border: 2px solid #f0f5f6;
    color: #272727;
    background-color: white;
  }
`