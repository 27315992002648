import React, { useCallback, useEffect, useState } from "react";
import { ScrollTopOption } from "./style";
import IArrowTop from "../../assets/icons/i-arrow-top.svg";

export const ScrollToTopButton = () => {
    const [showScrollTop, setShowScrollTop] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.scrollY > 600) {
            setShowScrollTop(true);
        } else {
            setShowScrollTop(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    const scroll = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <ScrollTopOption
            onClick={scroll}
            id="scrollTop"
            className={showScrollTop ? 'visible' : ''}
        >
            <img src={IArrowTop} alt="Ícone de seta para cima" />
            <span>Voltar ao topo</span>
        </ScrollTopOption>
    );
};
