type ProductDescritionProps = {
  description?: string;
};
export const ProductDescription = (props: ProductDescritionProps) => {
  return (
    <>
      {props?.description && (
        <div className="card card-description">
          <div className="title-head justify-center lg:justify-start mb-5">
            <span>Descrição do Produto</span>
          </div>
          <p>{props?.description}</p>
        </div>
      )}
    </>
  );
};
