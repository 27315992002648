import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { AsideContainer, LogoSaraImg, Navbar, ButtonCreateAccount, ButtonLogin, ButtonLogout } from "./style";
import { useGA } from "../../../hooks";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import IHome from "../../../assets/icons/i-home.svg";
import IHomeActive from "../../../assets/icons/i-home-active.svg";
import IMedicamentos from "../../../assets/icons/i-medicamentos.svg";
import IMedicamentosActive from "../../../assets/icons/i-medicamentos-active.svg";
import ILembrete from "../../../assets/icons/i-lembretes.svg";
import ILembreteActive from "../../../assets/icons/i-lembretes-active.svg";
import IFavoritos from "../../../assets/icons/i-favoritos.svg";
import IFavoritosActive from "../../../assets/icons/i-favoritos-active.svg";
import INewsMode from "../../../assets/icons/i-newsmode.svg";
import INewsModeActive from "../../../assets/icons/i-newsmode-active.svg";
import IPerfil from "../../../assets/icons/i-perfil.svg";
import IPerfilActive from "../../../assets/icons/i-perfil-active.svg";
import ILogout from "../../../assets/icons/i-logout.svg";
import './sidenav.css';

interface INavProps {
    logoSara: string;
    isLoggedIn: boolean;
    pageIds: { id: string, event: string }[];
    logout: () => void;
}

export const LateralNav = React.forwardRef<HTMLDivElement, INavProps>((props, ref) => {
    const { sendGAEvent } = useGA();
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<string>();
    const location = useLocation();

    const handleRegister = () => {
        sendGAEvent('clique_criar_conta');
        navigate('/cadastro');
    };

    useEffect(() => { setActive(true); }, [location]);

    const setActive = (noTracking = false) => {

        const idPage = props.pageIds.find(page => page.id === location.pathname.split('/')[1]) || null;

        if ((idPage && !idPage?.id) || (location.pathname.split('/')[1] === "dashboard")) {
            setActiveItem('home');
            return;
        }
        if (!noTracking) return;
        //envio de evento ao google

        setActiveItem(idPage?.id || '');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <AsideContainer ref={ref}>
            <div className="cursor-pointer">
                <Link to='/dashboard' onClick={scrollToTop}>
                    <LogoSaraImg src={props.logoSara} alt="Logo Sara" />
                </Link>
            </div>
            <Navbar>
                <ul className="sidenav-links gap-[22px]">
                    {[
                        { id: 'home', icon: IHome, iconActive: IHomeActive, text: 'Home' },
                        { id: 'busca-de-produtos', icon: IMedicamentos, iconActive: IMedicamentosActive, text: 'Produtos' },
                        { id: 'lembretes', icon: ILembrete, iconActive: ILembreteActive, text: 'Lembretes' },
                        { id: 'favoritos', icon: IFavoritos, iconActive: IFavoritosActive, text: 'Favoritos' },
                        { id: 'noticias', icon: INewsMode, iconActive: INewsModeActive, text: 'Blog de Notícias' },
                        { id: 'perfil', icon: IPerfil, iconActive: IPerfilActive, text: 'Perfil' }
                    ].map(link => (
                        <Tippy content={link.text} key={link.id}>
                            <Link to={`/${link.id}`} id={link.id} onClick={scrollToTop} className={`menu-item ${activeItem === link.id ? 'active' : ''}`}>
                                <img src={activeItem === link.id ? link.iconActive : link.icon} alt={`ícone ${link.text}`} />
                                <span className="menu-item-text">{link.text}</span>
                            </Link>
                        </Tippy>
                    ))}
                </ul>
            </Navbar>
            {!props.isLoggedIn && (
                <>
                    <ButtonCreateAccount onClick={handleRegister}>
                        Criar conta gratuita
                    </ButtonCreateAccount>
                    <br />
                    <ButtonLogin onClick={() => navigate('/login')}>
                        Entrar
                    </ButtonLogin>
                </>
            )}
            {props.isLoggedIn && (
                <ButtonLogout onClick={props.logout}>
                    <img src={ILogout} alt="icone sair" className="mr-[5px]" />
                    Sair
                </ButtonLogout>
            )}
        </AsideContainer>
    );
});
