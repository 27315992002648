import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException'

const api = 'news'

export interface News {
    _id: string;
    title: string;
    brief: string;
    content: string;
    image: string;
    imageAlt: string;
    _updatedAt: string;
    indexaUrl: string;
  }

const findAll = async (filter: string = '') => {
    try{
        const { data } = await Api().get(`/${api}${filter}`)
        return data
    } catch(error: any) {
        return new ApiException(error.message || 'Erro ao consultar a API')
    }
}
const findById = async (id: string = '') => {
    try{
        const { data } = await Api().get(`/${api}/${id}`)
        return data
    } catch(error: any) {
        return ApiException.fromAxiosError(error)
    }
}

export const NewsService = {
    findById,
    findAll
}