import { createContext, useState } from 'react';
import { Product, News, IProduct, Favourite } from '../types'
import { kebabCase } from '../utils'

export interface IProductContextData {
  product: Product | null;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  getProductURL: (product?: Product) => string;
  getNewsURL: (news?: News) => string;
}

export const ProductContext = createContext({} as IProductContextData)

interface IProductProviderProps {
  children: React.ReactNode;
}

export const ProductProvider: React.FC<IProductProviderProps> = ( props ) => {
  const [product, setProduct] = useState<Product | null>(null);

  const getProductURL = (product?: Product | IProduct | Favourite['productId']) => {
    if (!product) return '/';

    return `/produto/${kebabCase(product?.productName ?? '')}-${
      product?.productPresentation
        ? `${kebabCase(product?.productPresentation)}-`
        : ''
    }${product.ean1}`;
  };

  const getNewsURL = (news?: News) => {
    if (!news) return '/';

    return `/noticias/${kebabCase(news?.title ?? '')}-${news._id}`;
  };

  return (
    <ProductContext.Provider value={{ product, setProduct, getProductURL, getNewsURL }}>
      {props.children}
    </ProductContext.Provider>
  );
}
