import { ToastOptions, toast } from "react-toastify"

const success = (message: string, options: ToastOptions<unknown> = {}) => {
    toast.success(message, options);
}

const error = (message: string, options: ToastOptions<unknown> = {}) => {
    toast.error(message, options)
}

export const ToastUtil = { success, error }