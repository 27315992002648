import React, { useEffect, useState } from "react";
import "./style.css";
import { GAService, ProductService } from "../../../services";

interface ProductMarketingFormProps {
  ean1: string;
  hasBanner: boolean;
  hasAdVideo: boolean;
}
export const ProductMarketingForm: React.FC<ProductMarketingFormProps> = (
  props
) => {
  const [showProductMarketing, setShowProductMarketing] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (props.hasAdVideo) setUrl(ProductService.getVideoAdUrl(props.ean1));
    else setUrl(ProductService.getBannerUrl(props.ean1));

    setShowProductMarketing(true);
  }, [props.ean1, props.hasAdVideo, props.hasBanner]);

  if (!props.hasBanner && !props.hasAdVideo) return <div></div>;

  return (
    <div className="align-center mx-auto">
      <div className="col product-banner">
        {props.hasAdVideo && showProductMarketing && (
          <video
            key={url}
            width="100%"
            onPlay={() =>
              GAService.sendEvent("clique_video", {
                ean_medicamento: props.ean1,
              })
            }
            controls
          >
            <source src={url} type="video/mp4" />
          </video>
        )}
        {!props.hasAdVideo && showProductMarketing && (
          <img
            src={url}
            alt=""
            onClick={() =>
              GAService.sendEvent("clique_banner", {
                codigo_banner: props.ean1,
              })
            }
          />
        )}
      </div>
    </div>
  );
};
