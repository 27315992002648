import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException'

const api = 'reminders'

const findAll = async (filter: string) => {
    try {
        const { data } = await Api().get(`/${api}${filter}`)
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const findByProductId = async (productId: string) => {
    try {
        const { data } = await Api().get(`/${api}?filters={"productId":"${productId}"}`)
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const createReminder = async (body: any) => {
    try {
        const { data } = await Api().post(`/${api}`, body)
        return data
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const deleteReminder = async (id: string) => {
    try {
        const { data } = await Api().delete(`/${api}/${id}`)
        return
    } catch (error: any) {
        return ApiException.fromAxiosError(error)
    }
}

const editReminder = async (body: any, id:string) => {
    try{
        const { data } = await Api().patch(`${api}/${id}`, body);
        return
    } catch(error: any) {
        return ApiException.fromAxiosError(error)
    }
}
export const ReminderService = {
    findAll,
    findByProductId,
    createReminder,
    deleteReminder,
    editReminder
}
