import { createTheme } from '@mui/material';

export const SaraTheme = createTheme({
    palette: {
        primary: {
            main: '#807979', // Corresponde ao valor 500 do $mat-sara-main
            dark: '#5a5555', // Corresponde ao valor 600 do $mat-sara-main
            light: '#e6e4e4', // Corresponde ao valor 100 do $mat-sara-main
            contrastText: '#ffffff', // Corresponde ao $light-primary-text-main
        },
        secondary: {
            main: '#f4cd93', // Corresponde ao valor 200 do $mat-sara-alert
            dark: '#a26c1c', // Corresponde ao valor 600 do $mat-sara-alert
            light: '#faebd4', // Corresponde ao valor 100 do $mat-sara-alert
            contrastText: '#ffffff', // Corresponde ao $light-primary-text-alert
        },
        error: {
            main: '#d43f35', // Corresponde ao valor 500 do $mat-sara-danger
            dark: '#942c25', // Corresponde ao valor 600 do $mat-sara-danger
            light: '#f6d9d7', // Corresponde ao valor 100 do $mat-sara-danger
            contrastText: '#f6d9d7', // Corresponde ao $light-primary-text-danger
        },
        background: {
            default: '#f7f6f3', // Cor de fundo padrão
        },
    },
});
