import { Solution, News, DocumentTitle } from "../../components"

export const Home = () => {    
    return (
        <DocumentTitle description='Olá, Sou a Sara! Fui desenvolvida para facilitar o acesso democrático à informações confiáveis de saúde, medicamentos e bulas. Acesse e saiba mais!'>
            <div className="general-container align-center">
                <Solution />
                <News dashboardTemplate={true} />
            </div>
        </DocumentTitle>
    )
}