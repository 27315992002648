import { Link } from "react-router-dom";
import { useGA } from "../../../hooks"
import ImgPessoaSegurandoCelular from "../../../assets/images/img-pessoa-segurando-celular.webp";
import IPerfil from "../../../assets/icons/i-perfil.svg"

interface INotLoggedProps {
    page: string;
    isLoading: boolean;
}

export const NotLogged: React.FC<INotLoggedProps> = (props) => {
    const { sendGAEvent } = useGA()
    return (
        <>
            {props.page === 'notLogged' && !props.isLoading && (
                <div className="general-reminder container-not-logged-reminder">
                    <div className="flex justify-center items-center max-w-[320px] -mb-5">
                        <img src={ImgPessoaSegurandoCelular} alt="imagem de homem segurando celular" />
                    </div>
                    <div className="card-text-container-reminder w-full p-6 rounded-[20px] bg-white">
                        <span className="text-4xl font-[Nunito] tracking-tighter mb-2">
                            Lembretes para tomar seus remédios
                        </span>
                        <div className="flex flex-col justify-center gap-6 mb-6">
                            <p className="font-[Nunito] text-base text-[#7a7a7a]">
                                Com a correria do dia-a-dia, são tantos compromissos que as vezes fica difícil lembrar de tomar remédio, né?
                            </p>
                            <p className="font-[Nunito] text-base text-[#7a7a7a]">
                                <span className="block">Deixa com a gente!</span>
                                Te ajudamos nessa missão de lembrar a hora certa de tomar o remédio.
                            </p>
                        </div>
                        <div className="btn-container-reminder flex">
                            <Link
                                to="/cadastro"
                                className="btn-reminder btn-primary-reminder cursor-pointer"
                                onClick={() => sendGAEvent('clique_criar_conta')}
                            >Criar uma conta</Link>
                            <Link
                                to="/login"
                                className="btn-reminder btn-segundary-reminder cursor-pointer"
                                data-cy="btn-entrar-lembrete"
                            >
                                <img
                                    src={IPerfil}
                                    alt="imagem de homem segurando celular"
                                />
                                Entrar
                            </Link>
                        </div>
                    </div>
                </div>

            )}
        </>
    )
}
