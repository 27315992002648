import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useIsAuthProtected } from "../hooks"
interface IProtectedRoute {
    children: React.ReactNode
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ( props ) => {
    const local = useLocation()
    const navigate = useNavigate()

    const isAuth = useIsAuthProtected()
    
    useEffect(() => {
        if (!isAuth && local.pathname === '/') navigate('/dashboard')
        if (!isAuth && local.pathname === '/dashboard') navigate('/')
    }, [navigate, isAuth])

    return (
        <>
        {isAuth &&(
            <>
            {props.children}
            </>
        )}
        </>
    )
}