import { Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import { ToastUtil, cepMask, cpfCnpjMask, phoneMask } from "../../../utils"
import { IFormularioPerfil } from '../../../types';
import { ApiException, PeopleService } from '../../../services';
import { useLocalStorage } from '../../../hooks';
import { ToastContainer, toast } from 'react-toastify';
import IError from "../../../assets/icons/i-error.svg"
import { useState } from 'react';

const genderSelectObject = [
    { label: 'Masculino', value: 'Masculino' },
    { label: 'Feminino', value: 'Feminino' },
    { label: 'Outro', value: 'Outro' },
];

const validateCPF = (cpf: string) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if ((remainder === 10) || (remainder === 11)) remainder = 0;
    return remainder === parseInt(cpf.substring(10, 11));
}

const validateCNPJ = (cnpj: string) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;
    const validators = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;
    for (let i = 0; i < 12; i++) sum += parseInt(cnpj.charAt(i)) * validators[i + 1];
    let result = (sum % 11 < 2) ? 0 : 11 - (sum % 11);
    if (result !== parseInt(cnpj.charAt(12))) return false;
    sum = 0;
    for (let i = 0; i <= 12; i++) sum += parseInt(cnpj.charAt(i)) * validators[i];
    result = (sum % 11 < 2) ? 0 : 11 - (sum % 11);
    return result === parseInt(cnpj.charAt(13));
}

const validateCEP = (cep: string) => {
    return /^\d{5}-\d{3}$/.test(cep);
}


const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório').min(2, 'Campo obrigatório com no mínimo 2 caracteres'),
    phoneNumber: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('Informe um email válido').required('Campo obrigatório'),
    birthday: Yup.date().nullable().required('Campo obrigatório'),
    gender: Yup.string().required('Campo obrigatório'),
    uniqueId: Yup.string().required('Campo obrigatório').test('valid-doc', 'Documento inválido', value => validateCPF(value) || validateCNPJ(value)),
    zipcode: Yup.string().required('Campo obrigatório').test('valid-cep', 'CEP inválido', validateCEP),
});


interface IFormularioPerfilProps{
    perfilData: IFormularioPerfil;
    id: string;
}

export const FormularioPerfil: React.FC<IFormularioPerfilProps> = (props) => {
    const { setItem } = useLocalStorage()
    const [newBirthday, setNewBirthday] = useState(props.perfilData.birthday ? new Date(Number(props.perfilData.birthday) * 1000).toISOString().split('T')[0] : undefined);
    const sendForm = (values: any) =>{
        const birthday = Number(new Date(values.birthday).getTime() / 1000)
        const data = {
            _id: props.perfilData._id,
            name: values.name,
            birthday: birthday,
            userId: props.perfilData.userId._id,
            email: values.email,
            phoneNumber: values.phoneNumber.replace(/\D/g,''),
            zipcode: values.zipcode.replace(/\D/g,''),
            uniqueId: values.uniqueId.replace(/\D/g,''),
            gender: values.gender
        }
        PeopleService.save(data)
            .then((res) => {
                if (res instanceof ApiException){
                    console.log('Error', res)
                    return
                } else {
                    setItem('userData', res.data);
                    ToastUtil.success('Alterações salvas com sucesso !');
                }
            })
    }
    return (
        <Formik
            initialValues={{ name: props.perfilData.name, phoneNumber: phoneMask(props.perfilData.phoneNumber), email: props.perfilData.email, birthday: newBirthday, gender: props.perfilData.gender, uniqueId: props.perfilData.uniqueId ? cpfCnpjMask(props.perfilData.uniqueId) : undefined, zipcode: props.perfilData.zipcode ? cepMask(props.perfilData.zipcode) : undefined}}
            validationSchema={ValidationSchema}
            onSubmit={(values) => sendForm(values)}
        >
            {({ errors, touched, isValid, setFieldValue }) => (
                <Form className="form-base-perfil">
                    <div className="mb2">
                        <p>Nome completo</p>
                        <Field
                            className={`text-content-perfil ${touched.name && errors.name ? 'text-error' : ''}`}
                            type="text"
                            name="name"
                            placeholder="John Doe"
                            data-cy="input-nome-perfil"
                        />
                        {touched.name && errors.name && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.name}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>Telefone cadastrado</p>
                        <Field
                            className={`text-content-perfil ${touched.phoneNumber && errors.phoneNumber ? 'text-error' : ''}`}
                            type="text"
                            name="phoneNumber"
                            maxLength={15}
                            placeholder="(99) 99999-9999"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('phoneNumber', phoneMask(e.target.value))}
                            data-cy="input-telefone-perfil"
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.phoneNumber}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>Email</p>
                        <Field
                            type="text"
                            name="email"
                            className={`text-content ${touched.email && errors.email ? 'text-error' : ''}`}
                            placeholder="JohnDoe@email.com.br"
                            data-cy="input-email-perfil"
                        />
                        {touched.email && errors.email && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.email}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>Data de nascimento</p>
                        <Field
                            type="date"
                            name="birthday"
                            className={`text-content-perfil ${touched.birthday && errors.birthday ? 'text-error' : ''}`}
                            placeholder="DD/MM/AAAA"
                            data-cy="input-nascimento-perfil"
                        />
                        {touched.birthday && errors.birthday && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.birthday}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>Gênero</p>
                        <Field
                            as="select"
                            name="gender"
                            className={`text-content-perfil ${touched.gender && errors.gender ? 'text-error' : ''}`}
                            data-cy="input-genero-perfil"
                        >
                            <option value="">Selecione...</option>
                            {genderSelectObject.map((genderItem, index) => (
                                <option key={index} value={genderItem.value}>
                                    {genderItem.label}
                                </option>
                            ))}
                        </Field>
                        {touched.gender && errors.gender && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.gender}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>CPF / CNPJ</p>
                        <Field
                            type="text"
                            name="uniqueId"
                            maxLength={18}
                            className={`text-content-perfil ${touched.uniqueId && errors.uniqueId ? 'text-error' : ''}`}
                            placeholder="000.000.000-00"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value != undefined){
                                    setFieldValue('uniqueId', cpfCnpjMask(e.target.value) )}}
                                }
                            data-cy="input-cpf-perfil"
                        />
                        {touched.uniqueId && errors.uniqueId && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.uniqueId}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <p>CEP</p>
                        <Field
                            type="text"
                            name="zipcode"
                            maxLength={9}
                            className={`text-content-perfil ${touched.zipcode && errors.zipcode ? 'text-error' : ''}`}
                            placeholder="00000-000"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value != undefined){
                                    setFieldValue('zipcode', cepMask(e.target.value))
                                }
                            }}
                            data-cy="input-cep-perfil"
                        />
                        {touched.zipcode && errors.zipcode && (
                            <div className="flex gap-2 mt-2">
                                <img src={IError} alt="Ícone de erro" />
                                <span className="text-xs text-[#e44178]">
                                    {errors.zipcode}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="flex justify-end">
                        <button
                            className="btn-save-changes-perfil"
                            data-cy="btn-salvar-alteracoes-perfil"
                            type="submit"
                        >
                            Salvar Alterações
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}