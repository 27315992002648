import React from "react";

interface BrandImageProps {
    id: string,
    companyName: string,
    objectFit?: 'cover' | 'contain';
}

export const BrandImage: React.FC<BrandImageProps> = (
    props
  ) => {

    return (
        <img
            src={`https://cdn.sara.com.br/companyLogo/logo-${props.id}.webp`}
            style={{ width: '55px', height: '55px', objectFit: props.objectFit || 'cover' }}
            alt={`${props.companyName} logo`}
            onError={(e) => {
                e.currentTarget.style.display = 'none';
            }}
        />
    );
}