import { createContext, useState } from 'react';
import '../styles/css/isBusy.css'
import { useLocation } from 'react-router-dom';

export interface IIsBusyContextData {
    isBusy: boolean;
    setIsBusy: (isBusy: boolean) => void
}

export const IsBusyContext = createContext({} as IIsBusyContextData)

interface IIsBusyProps {
    children: React.ReactNode;
}

export const IsBusyProvider: React.FC<IIsBusyProps> = ({children}) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);

    return (
        <IsBusyContext.Provider value={{ isBusy, setIsBusy }}>
            {children}
            {isBusy && (
                <div className="overlay">
                    {/* Coloque aqui o seu indicador de carregamento */}
                    <div className="animate-spin w-[50px] h-[50px] border-[3px] rounded-full border-gray-300 border-l-gray-400"></div>
                </div>
            )}
        </IsBusyContext.Provider>
    );
}
