import { styled } from "styled-components";


export const MainContainer = styled.div.attrs({})`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    background-color: #f0f5f6;

    @media screen and (min-width: 768px) {
    min-height: 954px;
    }
`

export const MainContent = styled.div.attrs({})`
    width: 100%;
    max-width: 1440px;
    margin: auto;
    position: relative;
`

