import { Api } from '../ApiConfig'
import { ApiException } from '../ErrorException'

const api = 'reminders'

const findAll = async (filter: string = '') => {
    try{
        const { data } = (await Api().get(`/${api}${filter}`))
        return data
    } catch(error: any) {
        return ApiException.fromAxiosError(error)
    }
}

export const DailyReminderService = {
    findAll
}

