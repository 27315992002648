

export const phoneMask = (phoneNumber: string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{0,5})(\d{0,4})$/);

    if (match) {
        const formatted = '(' + match[1] + (match[2] ? ') ' + match[2] : '') + (match[3] ? '-' + match[3] : '');
        return formatted;
    }

    return phoneNumber;
}

export const cpfCnpjMask = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length <= 11) {
        // CPF
        const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
        return match ? `${match[1]}.${match[2]}.${match[3]}-${match[4]}` : value;
    } else {
        // CNPJ
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
        return match ? `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}` : value;
    }
}



export const cepMask = (cep: string) => {
    const cleaned = ('' + cep).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{5})(\d{0,3})$/)

    if (match) {
        const formatted = match[1] + (match[2] ? '-' + match[2] : '')
        return formatted
    }

    return cep
}