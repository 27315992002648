
import Background from "../../assets/images/login-background.webp"
import BackgroundLarge from "../../assets/images/login-background-large.webp"

export const BackgroundForm = () => {
    return (
        <div className="h-full my-auto hidden min-[1025px]:block min-[1920px]:ml-[-424px]">
            <img src={Background}
                className="h-full ml-auto min-[1920px]:hidden max-[1920px]:block" 
                alt="Fundo com exemplo de um medicamento na SARA" />
            <img
                src={BackgroundLarge}
                className="h-[95vh] ml-auto max-[1920px]:hidden min-[1920px]:block"
                alt="Fundo com exemplo de um medicamento na SARA"
            />
        </div>
    )
}
