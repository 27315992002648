import { IAction, IReminderPage, Product } from "../../../types"
import IListaMedicamentos from "../../../assets/icons/i-lista-medicamentos.svg"
import ILembrete from "../../../assets/icons/i-lembrete.svg"
import ILupaProduto from "../../../assets/icons/i-lupa-produto.svg"
import IAdd from "../../../assets/icons/i-add.svg"
import ProductPresentation from "../../../components/ProductPresentation"
import { useNavigate } from "react-router-dom"
import { useProductClick } from "../../../hooks/UseProductClick"
// import '../index.css'

interface ILoggedProps {
    page: string,
    isLoading: boolean,
    listProducts: any[],
    listReminders: IReminderPage[],
    changePage: (page: 'notLogged' | 'logged' | 'createReminder', queryParams?: {}) => void
    actions: IAction[];
}

export const Logged: React.FC<ILoggedProps> = (props) => {
    const { handleProductClick} = useProductClick();

    return (
        <>
            {props.page === 'logged' && !props.isLoading && (
                <div className="general-reminder container-logged-reminder  gap-9">
                    {/* Card Reminders Today */}
                    <div className="w-full bg-white  p-6 rounded-[20px]">
                        <div className="w-full flex justify-start items-center gap-2 mb-6 px-[16px]">
                            <img src={props.listReminders.length > 0 ? IListaMedicamentos : ILembrete} alt="ícone de vários medicamentos ou ícone de calendário" />
                            <p className="page-main-title font-[Nunito] text-[28px] font-bold leading-6 text-[#272727]">Lembretes</p>

                            {/* btn create new reminder */}
                            {props.listReminders.length > 0 && (
                                <div className="flex justify-end w-full  md" >
                                    <button className="btn-reminder btn-create-reminder btn-primary-reminder" onClick={() => props.changePage('createReminder')} data-cy="btn-criar-lembrete-mobile-reminder">
                                        <img src={IAdd} alt="Ícone de adição" />
                                        <span>Criar Novo Lembrete</span>
                                    </button>
                                </div>
                            )}

                        </div>
                        {/* has reminder in list */}
                        {props.listProducts && props.listProducts.length > 0 ? (
                            <div className="w-full">
                                <ProductPresentation
                                    products={props.listProducts}
                                    variant="list"
                                    actions={props.actions}
                                    onProductClick={handleProductClick}
                                    data-cy="open-card-reminders"
                                />
                            </div>
                        ) : (
                            // not reminder in list
                            <div className="flex flex-col justify-center items-center gap-6 px-5 py-4 rounded-[10px] mb-6">
                                <img src={ILupaProduto} alt="ícone de vários medicamentos" className="w-[96px]" />
                                <span className="font-[Nunito] text-[32px] font-bold text-center tracking-tighter">
                                    Você não possui nenhum lembrete ativo no momento!
                                </span>
                                <span className="font-[Nunito] text-[20px] font-medium text-center">
                                    Para exibir resultados aqui, busque por medicamentos na guia de produtos, confira a bula e crie um lembrete.
                                </span>
                                <button onClick={() => props.changePage('createReminder')} className="btn-reminder btn-primary-reminder cursor-pointer" data-cy="btn-criar-lembrete">
                                    <img src={IAdd} alt="Ícone de adição" />
                                    <span>Criar Novo Lembrete</span>
                                </button>
                            </div>
                        )}
                    </div>


                </div>
            )}
        </>
    )
}