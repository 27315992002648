import { FormBase } from "../../../../styles/styled";
import IError from "../../../../assets/icons/i-error.svg"
import React from "react"

interface IInputCrmInfos{
    ufCrm: string,
    crm: string,
    validatedForm: boolean,
    crmNotRelation: boolean,
    crmHasRegister: boolean,
    setUfCrm: (ufCrm: string) => void,
    setCrm: (crm: string) => void
}
export const InputCrmInfos: React.FC<IInputCrmInfos> = (props) => {
    return (
        <FormBase>
            <div className="w-full flex items-end justify-between">
                <div className="relative w-[30%]">
                    <p>CRM</p>
                    <select
                        className={`text-content ${props.validatedForm && !props.ufCrm ? 'invalid' : ''}`}
                        value={props.ufCrm}
                        onChange={(e) => props.setUfCrm(e.target.value)}
                    >
                        <option value="" selected disabled>UF</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                    </select>
                </div>

                <div className="w-[60%]">
                    <input
                        type="text"
                        className={`text-content${(props.validatedForm && !props.crm) || props.crmNotRelation || props.crmHasRegister ? 'invalid' : ''}`}
                        required
                        placeholder="Número"
                        value={props.crm}
                        onChange={(e) => props.setCrm(e.target.value.replace(/\D/g, ''))}
                    />
                </div>
            </div>
            {props.validatedForm && (!props.ufCrm || !props.crm) && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IError} alt="Ícone de erro" className="float-left" />&nbsp; É necessário preencher o estado e CRM para continuar.
                </div>
            )}
            {props.crmNotRelation && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IError} alt="Ícone de erro" className="float-left" />&nbsp; Este CRM não está relacionado com o representante.
                </div>
            )}
            {props.crmHasRegister && (
                <div className="text-xs font-normal text-[#9E9E9E] mt-2">
                    <img src={IError} alt="Ícone de erro" className="float-left" />&nbsp; Este CRM já foi cadastrado.
                </div>
            )}
        </FormBase>
    )
}