import ProductNotFoundImg from "../../assets/images/notFound.webp"
import { NotFound } from "../../components"
import { DocumentTitle } from "../../components"

export const ProductNotFound = () => {
    return (
        <DocumentTitle title='Produto Não encontrado' description='Acesse e faça seu cadastro. Tenha acesso as diversas funcionalidades oferecidas na Sara. Bulas, modo de usar medicamentos e muito mais.'>
            <NotFound notFoundIMG={ProductNotFoundImg} notFoundText={"Não foi possível encontrar informações deste produto."} />
        </DocumentTitle>
    )
}