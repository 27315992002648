import "./style.css";
import iReminder from "../../../assets/icons/i-reminder.svg";
import iWhatsappBlack from "../../../assets/icons/i-whatsapp-black.svg";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../../hooks";
import { ReminderService } from "../../../services/api/reminder/ReminderService";
import { useLocation, useNavigate } from "react-router-dom";
import { GAService } from "../../../services";

interface IProductReminderFormProps {
  productId: string;
}
export default function ProductReminderForm(props: IProductReminderFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useLocalStorage().getData("token");
  const [lastReminderId, setLastReminderId] = useState(null);

  const isRecallPage = location.pathname.startsWith("/recall-de-medicamento");
  async function getReminders() {
    const response = await ReminderService.findByProductId(props.productId);
    const reminders = response?.data?.result;
    if (reminders?.length) {
      var id = reminders[reminders.length - 1]._id;
      setLastReminderId(id);
    }
  }
  function reminderClick() {
    if (lastReminderId) {
      //edit reminder
      // navigate(`/lembretes/action=edit&id=${lastReminderId}`);
      navigate(`/lembretres`)
    } else {
      //create reminder
      GAService.sendEvent("criar_lembrete");
      navigate(`/lembretes?id=${props.productId}`);
    }
  }

  useEffect(() => {
    if (token) {
      getReminders();
    }
  }, [props.productId]);

  return (
    <div className={`reminder-presentation ${isRecallPage ? 'recall-page-reminder' : ''} lg:h-full min-w-[180px]`}>
      <div className="title-reminder justify-center lg:justify-start">
        <img src={iReminder} alt="icone de lembretes de remédio" />
        <span>Lembretes</span>
      </div>
      <p>
      Otimize sua rotina de saúde! Crie lembretes e receba alertas dos seus medicamentos pelo WhatsApp
      </p>
      <div
        onClick={reminderClick}
        id="createReminder"
        className="cursor-pointer"
        data-cy="btn-criar-ver-reminder"
      >
        <img src={iWhatsappBlack} alt="icone do whatsapp" />
        {lastReminderId ? "Ver" : "Criar"} Lembrete
      </div>
    </div>
  );
}
