import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { IHeaderSettings } from "../../../types"
import { useProduct, useLocalStorage } from "../../../hooks"
import Autocomplete from "./Autocomplete"
import './index.css'
import SaraLogoWhite from "../../../assets/images/sara-logo-white.svg"
import IBackButtonIcon from "../../../assets/icons/i-back-button-icon.svg"
import IMenu from "../../../assets/icons/i-menu.svg"
import ICompartilhar from "../../../assets/icons/i-compartilhar.svg"
import IBookMark from "../../../assets/icons/i-bookmark.svg"
import IBookMarkWhite from "../../../assets/icons/i-bookmark-white.svg"
import IAdd from "../../../assets/icons/i-add.svg"
import ILupaBlack from "../../../assets/icons/i-lupa-black.svg"
import WomanPhone from "../../../assets/images/woman-phone.webp"
import IClose from "../../../assets/icons/i-close.svg"
import IHomeWhite from "../../../assets/icons/i-home-white.svg"
import ICapsuleWhite from "../../../assets/icons/i-capsule-white.svg"
import ICalendarWhite from "../../../assets/icons/i-calendar-white.svg"
import IFavouriteWhite from "../../../assets/icons/i-favorite-white.svg"
import INewsModeWhite from "../../../assets/icons/i-newsmode-white.svg"
import IProfileWhite from "../../../assets/icons/i-profile-white.svg"
import ShareProductModal from "./ShareProductModal/ShareProductModal"
import { ApiException, FavoriteService } from "../../../services"

export const Header = React.forwardRef<HTMLDivElement>((props, ref) => {
    const [inputValue, setInputValue] = useState('');
    // const handleInputChange = (e) => {
    //     setInputValue(e.target.value);

    // };

    const { getData, removeData } = useLocalStorage()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [headerSettings, setHeaderSettings] = useState<IHeaderSettings>({} as IHeaderSettings)
    const [userName, setUserName] = useState(getData('userData') ? getData('userData').name : '')
    const [currentUrl, setCurrentUrl] = useState('')
    const [greetings, setGreetings] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [actionQuery, setActionQuery] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [useAutocomplete, setUseAutocomplete] = useState(false)
    const [hasH1Header, setHasH1Header] = useState(true)
    const [headerColor, setHeaderColor] = useState<string>()
    const [product, setProduct] = useState<any>()
    const [isHomeRoute, setIsHomeRoute] = useState<boolean>()
    const [originRouter, setOriginRouter] = useState('')
    const [prevLocation, setPrevLocation] = useState('')
    const { product: hookProduct, setProduct: setHookProduct } = useProduct()
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const openShareModal = () => {
        setShareModalOpen(true);
    };

    const closeShareModal = () => {
        setShareModalOpen(false);
    };

    useEffect(() => {
        setPrevLocation(location.pathname)
    }, [location])

    useEffect(() => {
        setHeaderSettings({} as IHeaderSettings);
        setIsHomeRoute(location.pathname === '' || location.pathname === '/' || location.pathname.startsWith('/dashboard') ? true : false)

        if (location.pathname.startsWith('/noticias/')) {
            setHeaderSettings({
                title: 'Blog de Notícias',
                route: 'noticia-detalhe'
            })
        }

        setCurrentUrl(location.pathname.split('?')[0])

        if (queryParams.get('action') === 'logout') logout()

        setSearchQuery(queryParams.get('produto') || '');
        setActionQuery(queryParams.get('action') || '')
        setOriginRouter(queryParams.get('origin') || '');

        setIsLoggedIn(getData('token') ? !!getData('token') : false)

        setUserName(getData('userData') ? getData('userData').name : '');

        checkUrl();

        setHasH1Header(!(location.pathname.includes('/produto/') || location.pathname.startsWith('/noticias/')))

        setUseAutocomplete(['/busca-de-produtos', '/dashboard'].some((route) => location.pathname.includes(route)) || location.pathname === '/')

        setProduct(hookProduct || undefined)
        // setProduct({
        //     ...hookProduct,
        //     isFavourite: false
        // })
        // setHeaderColor(product?.companyId?.basePrimaryColorHex || '')
        setHeaderColor('')
        if (!location.pathname.startsWith('/produto')) {
            setHookProduct(null);
        }
    }, [location])

    useEffect(() => {
        setProduct(hookProduct)
    }, [hookProduct])

    const checkUrl = () => {
        switch (true) {
            case /\/busca-de-produtos/.test(location.pathname):
                setHeaderSettings({
                    title: 'Busca de Produtos',
                    route: 'busca-de-produtos',
                });
                break;

            case /\/catalogo-de-produtos/.test(location.pathname):
                setHeaderSettings({
                    title: 'Catálogo de Produtos',
                    route: 'catalogo-de-produtos',
                });
                break;

            case /\/produto\//.test(location.pathname):
                setHeaderSettings({
                    title: 'Bula',
                    route: 'produto',
                });
                break;

            case /\/lembretes/.test(location.pathname):
                setHeaderSettings({
                    title: 'Lembretes',
                    route: 'lembrete',
                    productSearch: !actionQuery
                        ? {
                            title: 'Criar novo lembrete',
                            path: isLoggedIn ? 'lembretes' : 'login',
                            queryParams: isLoggedIn
                                ? { action: 'createReminder' }
                                : {},
                            icon: IAdd,
                        }
                        : undefined,
                });
                break;

            case /\/favoritos/.test(location.pathname):
                setHeaderSettings({
                    title: 'Favoritos',
                    route: 'favorito',
                    productSearch: {
                        title: 'Buscar produto',
                        path: 'busca-de-produtos',
                        queryParams: {},
                        icon: ILupaBlack,
                    },
                });
                break;

            case /\/recall-de-medicamento/.test(location.pathname):
                setHeaderSettings({
                    title: 'Recall de Medicamento',
                    route: 'recall',
                });
                break;

            case /\/noticias\//.test(location.pathname):
                setHeaderSettings({
                    title: 'Blog de Notícias',
                    route: 'noticia-detalhe',
                });
                break;

            case /\/noticias/.test(location.pathname):
                setHeaderSettings({
                    title: 'Blog de Notícias',
                    route: 'noticia',
                    subtitle: `
                  <span class="hidden md:block ">Aqui, você encontra o ambiente ideal para se manter informado sobre tudo relacionado à saúde, bem-estar e muito mais. Nossa equipe está empenhada em trazer as informações mais relevantes e atualizadas para ajudar você a tomar decisões informadas sobre sua saúde.</span>
                  <span class="block md:hidden ">Aqui, você encontra o ambiente ideal para se manter informado sobre tudo relacionado à saúde, bem-estar e muito mais.</span>
                `,
                });
                break;

            case /\/perfil/.test(location.pathname):
                setHeaderSettings({
                    title: 'Perfil',
                    route: 'perfil',
                    productSearch: {
                        title: 'Buscar produto',
                        path: 'busca-de-produtos',
                        queryParams: {},
                        icon: ILupaBlack,
                    },
                });
                break;

            case /\/dashboard/.test(location.pathname):
                setHeaderSettings({
                    title: `Olá, ${userName ?? ''}!`,
                    subtitle: `É ótimo te ver por aqui. Estamos prontos para ajudá-lo em sua jornada de saúde.`,
                    route: 'dashboard',
                    state: 'lg',
                });
                break;


            case /\//.test(location.pathname):
                setHeaderSettings({
                    title:
                        'Sara! Sua referência em Bulas Digitais',
                    route: '/',

                });
                break;

            default:
                break;
        }
    }

    const logout = () => {
        setIsLoggedIn(false)
        removeData('token')
        removeData('userData')
        setUserName('')
        setModalOpen(false)
        navigate('/')
    }

    const isPreviousPageInsideOfSara = () => {
        return prevLocation !== '' ? true : false
    }

    const backTo = () => {
        if (originRouter === 'qrcode' || isPreviousPageInsideOfSara()) {
            navigate('')
            return
        }
        navigate(-1)
    }

    const openMobileModal = () => {
        //envio de evento ao google
        setModalOpen(true)
    }

    const greetUser = () => {
        const date = new Date(Date.now());
        const brazilDate = date.toLocaleString('pt-BR', {
            timeZoneName: 'longOffset',
            timeZone: 'America/Sao_Paulo',
        });
        const brazilTime = brazilDate.split(',')[1].split(':')[0];
        const hour = Number(brazilTime);

        setGreetings(() => {
            if (hour >= 5 && hour < 12) {
                return (userName
                    ? `Bom dia, ${userName}!`
                    : `Bom dia!`);
            } else if (hour >= 12 && hour < 18) {
                return (userName
                    ? `Boa tarde, ${userName}!`
                    : `Boa tarde!`);
            } else {
                return (userName
                    ? `Boa noite, ${userName}!`
                    : `Boa noite!`);
            }
        })
    }

    const handleFavourite = () => {
        console.log(product)
        if (!isLoggedIn) navigate('/login?favourite=true')

        if (!product) return

        if (!product.isFavourite) {
            setProduct({
                ...product,
                isFavourite: true
            })
            FavoriteService.createFavourite({ productId: product._id })
                .then((res) => {
                    if (res instanceof ApiException) {
                        console.log(res);
                        handleFavourite();
                    }
                })
            return
        }

        setProduct({ ...product, isFavourite: !product.isFavourite })
        FavoriteService.deleteOne(product._id)
            .then((res) => {
                if (res instanceof ApiException) {
                    console.log(res)
                }
            })
    }

    const goToProductPage = () => {
        //deve ser implementado com base no projeto angular
    }
    // const headerHeight = location.pathname.match(/\/noticias/) ? '20vh' : location.pathname.match(/\/(lembretes|favorito|perfil)/) ? '13vh' : '24vh';

    const headerHeight = location.pathname.match(/\/(noticias|lembretes|favorito|perfil)/) ? '13vh' : '170px';  // Escolha uma altura menor, como 15vh, para estas rotas
    return (
        <>
            {/* // Mobile logged and not logged */}
            <div id="mobile-header" className={`container-mobile md:hidden ${isHomeRoute ? 'header-extended rounded-b-[30px]' : ''}`} >
                <div
                    className={`rounded-b-[30px] default-mob shadow-xl p-[16px] ${headerColor ? '' : 'default-color'} ${useAutocomplete ? 'h-[94px]' : ''}`}
                    style={{ background: headerColor }}
                >
                    <div className="flex justify-between items-center header-mob">
                        {isHomeRoute && (
                            <img
                                data-testid="link-home-logo"
                                src={SaraLogoWhite}
                                alt="logo sara"
                                className="min-w-[94px] w-[94px] h-[44px] cursor-pointer"
                                onClick={() => navigate('/dashboard')}
                            />
                        )}
                        {currentUrl !== '/' && currentUrl !== '/dashboard' && !isHomeRoute && (
                            <>
                                <div className="flex items-center">
                                    <button
                                        data-testid="btn-back"
                                        className="top-[50px] mr-[30px] cursor-pointer"
                                        onClick={backTo}
                                    >
                                        <img
                                            src={IBackButtonIcon}
                                            alt="Botão de voltar"
                                        />
                                    </button>

                                    <div>
                                        {hasH1Header && (
                                            <h1 className="text-white  text-[30px] sm:text-[20px] !sm:text-[20px] font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]">
                                                {headerSettings?.title}
                                            </h1>
                                        )}

                                        {!hasH1Header && (
                                            <span
                                                className="text-white  text-[30px]  font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]"
                                            >
                                                {headerSettings?.title}
                                            </span>
                                        )}

                                    </div>
                                </div >
                            </>
                        )}

                        {/* < !--menu icon-- > */}
                        {currentUrl && !currentUrl.startsWith('/produto') && (
                            <>
                                <div className="h-[44px] w-[44px]">
                                    <button
                                        data-testid="btn-menu"
                                        className="ml-auto"
                                        onClick={() => {
                                            openMobileModal()
                                            greetUser()
                                        }}
                                    >
                                        <img
                                            src={IMenu}
                                            alt="Botão de menu"
                                            className="flex h-[44px] w-[44px]"
                                        />
                                    </button>
                                </div >
                            </>
                        )}

                        {/* < !--share and favorite-- > */}
                        {currentUrl.startsWith('/produto') && (
                            <>
                                <div className="flex items-center gap-4 gap-6">
                                    <div
                                        data-testid="action-share"
                                        className="cursor-pointer"
                                        onClick={openShareModal}
                                    >
                                        <img
                                            className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]"
                                            src={ICompartilhar}
                                            alt="Botão para compartilhar bula"
                                        />
                                    </div>
                                    <div
                                        data-testid="action-favourite"
                                        onClick={handleFavourite}
                                        className="cursor-pointer"
                                    >
                                        <img
                                            className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]"
                                            src={!product?.isFavourite ? IBookMark : IBookMarkWhite}
                                            alt="Ícone de coração"
                                        />
                                    </div >
                                </div >
                            </>
                        )}
                    </div>

                    {/* {currentUrl.startsWith('/noticias') && (
                        <div
                            className="w-full text-white text-lg font-medium font-['Nunito'] tracking-[.36px]"
                            dangerouslySetInnerHTML={{ __html: headerSettings?.subtitle ?? '' }}
                        ></div >
                    )} */}
                </div >

                {/* //   < !--autocomplete-- > */}
                {currentUrl.startsWith('/busca-de-produtos') && (
                    <>
                        <div className="px-[16px] -mt-[14px]">
                            <Autocomplete />
                        </div>
                    </>
                )}
                {/* < !--header extended logged-- > */}
                {isLoggedIn && isHomeRoute && (
                    <>
                        <div className="px-[16px] pb-[24px]" >
                            <div className="flex items-center py-[16px]">
                                {/* <img
                                    src={WomanPhone}
                                    alt="Mulher sentada usando um celular"
                                    className="min-w-[150px] mr-[16px]"
                                /> */}
                                <div
                                    className="islogged text-white  text-xl font-bold font-['Nunito'] leading-normal pt-[16px]"
                                >
                                    {headerSettings.title}

                                    <div
                                        className="islogged text-white text-base font-medium font-['Nunito'] leading-tight mt-[8px]"
                                    >
                                        {headerSettings.subtitle}
                                    </div>
                                </div>
                            </div>
                            <Autocomplete />
                        </div >
                    </>
                )}


                {/*  < !--header - extended not logged-- > */}
                {!isLoggedIn && isHomeRoute && (
                    <>
                        <div className="px-[16px] pb-[24px]" >
                            <div className="flex items-center flex-col py-[16px]">
                                <div className="flex items-center">
                                    <div
                                        className="text-white  sara-title text-xl font-bold font-['Nunito'] leading-normal pt-[16px]"
                                    >
                                        {headerSettings.title}
                                    </div>
                                    {/* <img
                                        src={WomanPhone}
                                        alt="Mulher sentada usando um celular"
                                        className="min-w-[150px] ml-[16px]"
                                    /> */}
                                </div>

                                <div
                                    className="w-full text-white text-base font-medium font-['Nunito'] leading-tight mt-[8px]"
                                >
                                    {headerSettings.subtitle}
                                </div>
                            </div>

                            <Autocomplete />
                        </div>
                    </>
                )}

            </div >

            {/* < !--End mobile logged and not logged-- >*/}






            {/* // ---- | -----  */}

            {/* // Desktop */}
            <div ref={ref} id="desktop-header" className={`container-desktop rounded-b-[30px] hidden md:flex ${headerColor ? '' : 'default-color'}`} style={{ background: headerColor, height: headerHeight, minHeight: '80px', transition: 'height 0.5s ease-in-out' }}>
                <div className="header-dynamic max-w-[1440px] flex items-start m-auto px-[20px]" style={{width: '100%'}}>
                    {/* <!-- sidebar spacing --> */}
                    <div className="hidden md:flex min-w-normal w-normal dynamic-width h-[100px]"></div>

                    {/* <!-- btn back and title --> */}
                    {currentUrl && currentUrl !== '/' && currentUrl !== '/dashboard' && !isHomeRoute && (
                        <>
                            <div className="w-full header-news flex flex-col justify-between px-[16px] pb-[24px]">
                                {/* <!-- btn back, title and btn product search--> */}
                                <div className="w-full flex flex-col">
                                    {/* <button
                                        data-testid="btn-back"
                                        className="top-[50px] mr-[30px] cursor-pointer"
                                        onClick={backTo}
                                    >
                                        <img
                                            src={IBackButtonIcon}
                                            alt="Botão de voltar"
                                            className="w-[40px] min-w-[40px]"
                                        />
                                    </button> */}

                                    <div>
                                        {currentUrl.startsWith('/produto') ? (
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    {hasH1Header && (
                                                        <h1 className="text-white text-[30px] pt-[6px] pb-[16px] font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]">
                                                            {headerSettings.title}
                                                        </h1>
                                                    )}
                                                    {!hasH1Header && (
                                                        <span className="text-white text-[30px] pt-[6px] pb-[16px] font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]">
                                                            {headerSettings.title}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="flex items-center action-icons">
                                                    <div data-testid="action-share" className="cursor-pointer">
                                                        <img className="min-w-[22px] w-[22px] min-h-[24px] h-[24px]" onClick={openShareModal} src={ICompartilhar} alt="Botão para compartilhar bula" />
                                                    </div><ShareProductModal open={shareModalOpen} handleClose={closeShareModal} product={product} />

                                                    <div data-testid="action-favourite" onClick={handleFavourite} className="cursor-pointer">
                                                        <img className="min-w-[22px] w-[22px] min-h-[24px] h-[24px]" src={!product?.isFavourite ? IBookMark : IBookMarkWhite} alt="Ícone de coração" />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {hasH1Header && (
                                                    <h1 className={`text-white text-[30px] ${isLoggedIn ? 'pb-[26px]' : 'pb-[8px]'} font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]`}>
                                                        {headerSettings.title}
                                                    </h1>
                                                )}

                                                {!hasH1Header && (
                                                    <span className="text-white text-[30px] pb-[16px] font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]">
                                                        {headerSettings.title}
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        {currentUrl.startsWith('/produto') && (
                                            <div className="w-full pt-[16px]">
                                                <Autocomplete />
                                            </div>
                                        )}
                                    </div>

                                    {/* < !--btn product search-- > */}
                                    {!!headerSettings.productSearch?.title && (
                                        <>
                                            <a
                                                data-testid="btn-product-search"
                                                onClick={() => goToProductPage()}
                                                className="visibility-inputs w-56 h-12 bg-yellow-500 ml-auto rounded-full shadow-xl justify-center items-center gap-2 inline-flex"
                                            >
                                                <img
                                                    src={headerSettings.productSearch?.icon}
                                                    alt="Ícone de lupa"
                                                />
                                                <div
                                                    className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight"
                                                >
                                                    {headerSettings.productSearch?.title}
                                                </div>
                                            </a >
                                        </>
                                    )}
                                </div >

                                {currentUrl.startsWith('/busca-de-produtos') && (
                                    <>
                                        <Autocomplete className="w-full" />
                                    </>
                                )}
                                {currentUrl.startsWith('/lembretes') && (
                                    <div className="visibility-inputs">
                                        <>
                                            <Autocomplete />
                                        </>
                                    </div>
                                )}
                                {currentUrl.startsWith('/favoritos') && (
                                    <div className="visibility-inputs">
                                        <>
                                            <Autocomplete />
                                        </>
                                    </div>
                                )}
                                {currentUrl.startsWith('/perfil') && (
                                    <div className="visibility-inputs">
                                        <>
                                            <Autocomplete />
                                        </>
                                    </div>
                                )}

                            </div >
                        </>
                    )}


                    {/* < !--header - extended logged and not logged in dashboard / home-- > */}
                    {isHomeRoute && (
                        <div
                            className="w-full flex items-center justify-between gap-[80px]  pr-[16px]"
                        >
                            <div className="h-full flex items-start justify-center flex-col">
                                <div>
                                    <div
                                        className="text-white text-[30px]  font-semibold font-['Nunito'] leading-[44px] tracking-[.75px]"
                                    >
                                        {headerSettings.title}
                                    </div>
                                    <div
                                        className="w-full text-white text-sm font-medium font-['Nunito'] mb-[8px] tracking-[.36px]"
                                    >
                                        {headerSettings.subtitle}
                                    </div>
                                </div>
                                <Autocomplete className="w-full" />
                            </div>

                            {/* <img
                                src={WomanPhone}
                                alt="Mulher sentada usando um celular"
                                className="m-auto"
                            /> */}
                        </div >
                    )}


                </div >
            </div >
            {/* <!-- End desktop --> */}

            {/* <!-- ------------- --> */}

            {/* <!-- Menu dialog - hamburger --> */}
            {modalOpen && (
                <div className="w-full h-full fixed top-0 z-50">
                    <div className="nav-mob overflow-y-auto px-4 pt-9 pb-12 bg-cyan-700 flex-col justify-start items-start gap-6 inline-flex w-full h-full">
                        <div className="self-stretch justify-between items-center gap-9 inline-flex">
                            <div className="flex-col justify-start items-start inline-flex">
                                <Link to="/"
                                    data-testid="link-home-logo"
                                    onClick={() => setModalOpen(false)}
                                    className="cursor-pointer"
                                >

                                    <img
                                        className="w-[94px] h-10"
                                        src={SaraLogoWhite}
                                    />
                                </Link>
                                <div
                                    className="text-white text-base font-normal font-['Nunito'] leading-normal"
                                >
                                    {greetings}
                                </div>
                            </div>

                            <button data-testid="btn-close-modal" onClick={() => setModalOpen(false)}>
                                <img
                                    className="w-[24px]"
                                    src={IClose}
                                    alt="Icone de fechar modal"
                                />
                            </button>
                        </div>
                        <div
                            className="aside-links w-full h-auto pt-6 flex-col justify-start items-start gap-6 flex"
                        >
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-home"
                                    to="/"
                                    onClick={() => setModalOpen(false)}
                                    className="w-full text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={IHomeWhite}
                                    />
                                    <span>Home</span>
                                </Link>
                            </div>
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-busca-de-produtos"
                                    to="/busca-de-produtos"
                                    onClick={() => setModalOpen(false)}
                                    className="w-full text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={ICapsuleWhite}
                                    />
                                    <span>Produtos</span>
                                </Link>
                            </div >
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-lembretes"
                                    to="/lembretes"
                                    onClick={() => setModalOpen(false)}
                                    className="w-full text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={ICalendarWhite}
                                    />
                                    <span className="">Lembretes</span>
                                </Link>
                            </div >
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-favoritos"
                                    onClick={() => setModalOpen(false)}
                                    to="/favoritos"
                                    className="w-full text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={IFavouriteWhite}
                                    />
                                    <span>Favoritos</span>
                                </Link>
                            </div >
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-noticias"
                                    onClick={() => setModalOpen(false)}
                                    to="/noticias"
                                    className="w-full   text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={INewsModeWhite}
                                    />
                                    <span className="">Blog de Notícias</span>
                                </Link>
                            </div >
                            <div className="self-stretch py-2 inline-flex">
                                <Link
                                    data-testid="link-perfil"
                                    onClick={() => setModalOpen(false)}
                                    to="/perfil"
                                    className="w-full text-white text-lg font-normal font-['Nunito'] leading-7 items-center gap-2 inline-flex"
                                >
                                    <img
                                        className="w-[21.74px] h-[18.63px]"
                                        src={IProfileWhite}
                                    />
                                    <span>Perfil</span>
                                </Link>
                            </div >
                        </div >

                        <div
                            className="self-stretch h-[68px] flex-col justify-start items-start gap-6 flex"
                        >
                            <div className="self-stretch h-[0px] border border-white"></div>
                        </div>

                        <div
                            className="self-stretch h-28 flex-col justify-start items-center gap-4 flex"
                        >
                            {!userName && (
                                <>                            <Link
                                    data-testid="link-cadastro"
                                    onClick={() => setModalOpen(false)}
                                    to="/cadastro"
                                    className="w-[220px] h-12 px-6 py-3.5 bg-yellow-500 rounded-[96px] shadow-xl justify-center items-center gap-2 inline-flex">
                                    <span
                                        className="text-neutral-800 text-sm font-bold font-['Nunito'] leading-tight"
                                    >
                                        Criar uma Conta
                                    </span>
                                </Link>
                                </>
                            )}
                            <div
                                className="text-black text-sm font-bold font-['Nunito'] leading-tight bg-white rounded-[96px] inline-flex"
                            >
                                {!userName && (
                                    <Link
                                        data-testid="link-login"
                                        onClick={() => setModalOpen(false)}
                                        to="/login"
                                        className="w-[220px] h-12 px-6 py-3.5 text-center"
                                    >
                                        Entrar
                                    </Link>
                                )}
                                {userName && (
                                    <button
                                        data-testid="btn-logout"
                                        onClick={logout}
                                        className="w-[220px] h-12 px-6 py-3.5">
                                        Sair da conta
                                    </button >
                                )}
                            </div >
                        </div >
                    </div >
                </div >
            )}
            {/* app-share-product-modal */}
        </>
    )

})