import { useEffect, useRef, useState } from "react";
import { NewsService, ApiException, GAService } from "../../services";
import IArrowWhiteMore from "../../assets/icons/i-arrow-white-more.svg";
import ICalendar from "../../assets/icons/i-calendar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./index.css";

interface INewsProps {
    dashboardTemplate: boolean;
    threeGroupedNewsQtd?: number[];
    setNewsListLength?: (length: number) => void;
}
export const News = (props: INewsProps) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1350);
    const className = isLargeScreen ? "cards-news" : "cards-news-md";

    const location = useLocation();
    const navigate = useNavigate();
    const cardsNews = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>();
    const threeGroupedNewsQtd = props.threeGroupedNewsQtd || [1, 2, 3];
    const [newsList, setNewsList] = useState<any[]>();

    useEffect(() => {
        getNewsList();

        const query = new URLSearchParams(location.search);
        const search = query.get("search");
        if (search) {
            getNewsList(search);
        }
    }, [location]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1350);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getNewsList = async (term?: string) => {
        setIsLoading(true);

        const res = await NewsService.findAll(
            term && `?filters={"title":"${term}"}`
        );

        if (res instanceof ApiException) {
            console.log(res.message);
        } else {
            setNewsList(res.data.result);
        }

        if (props.setNewsListLength != null) {
            props.setNewsListLength(res.data.result?.length ?? 0);
        }

        addScrollToCards();
        setIsLoading(false);
    };

    const addScrollToCards = () => {
        const cardNews = cardsNews.current;

        if (cardNews) {
            let isDown = false;
            let startX: number;
            let scrollLeft: number;

            const handleMouseDown = (e: MouseEvent) => {
                isDown = true;
                startX = e.pageX - cardNews.offsetLeft;
                scrollLeft = cardNews.scrollLeft;
            };

            const handleMouseLeave = () => {
                isDown = false;
            };

            const handleMouseUp = () => {
                isDown = false;
            };

            const handleMouseMove = (e: MouseEvent) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - cardNews.offsetLeft;
                const walk = (x - startX) * 3;
                cardNews.scrollLeft = scrollLeft - walk;
            };

            cardNews.addEventListener("mousedown", handleMouseDown);
            cardNews.addEventListener("mouseleave", handleMouseLeave);
            cardNews.addEventListener("mouseup", handleMouseUp);
            cardNews.addEventListener("mousemove", handleMouseMove);

            // Limpeza na desmontagem
            return () => {
                cardNews.removeEventListener("mousedown", handleMouseDown);
                cardNews.removeEventListener("mouseleave", handleMouseLeave);
                cardNews.removeEventListener("mouseup", handleMouseUp);
                cardNews.removeEventListener("mousemove", handleMouseMove);
            };
        }
    };

    const sendEvent = (newsId: string) => {
        GAService.sendEvent("noticias", { selected_notice: newsId });
    };

    const truncateString = (str: string, num: number) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    };

    return (
        <>
            {!props.dashboardTemplate && isLoading && (
                <div className="general h-[560px]">
                    <div className="animate-spin w-[50px] h-[50px] border-[3px] rounded-full border-gray-300 border-l-gray-400"></div>
                </div>
            )}

            <div className="w-full px-5 mx-auto mt-[16px] ml-[8px]">
                {props.dashboardTemplate && (
                    <div className="header-news">
                        <div className="label-news">
                            {/* <img src={INews} alt="ícone noticias" /> */}
                            <h2>Blog de Notícias</h2>
                        </div>
                        <div
                            className="more-news-news min-w-[101px] pl-[9px] pr-[5px] flex rounded-[60px] bg-[#EAEAEA] cursor-pointer"
                            onClick={() => navigate("/noticias")}
                            data-cy="ver-todas-noticias"
                        >
                            Ver todas &nbsp;
                            <img src={IArrowWhiteMore} alt="" />
                        </div>
                    </div>
                )}
                {threeGroupedNewsQtd.map((newsIndex, index) => (
                    <div ref={cardsNews} className={className} key={index}>
                        <div className="card-wrapper">
                            {newsList
                                ?.slice((newsIndex - 1) * 3, newsIndex * 3)
                                .map((news) => (
                                    <div
                                        key={news._id}
                                        className="card-new mb-6"
                                    >
                                        <a
                                            className="cursor-pointer"
                                            // href={`https://i4c-sara.simplex.live${news.indexaUrl}`}
                                            href={`https://www.sara.com.br/noticias${news.indexaUrl}`}
                                            onClick={() => sendEvent(news._id)}
                                        >
                                            <div className=" h-[200px]">
                                                <img
                                                    src={news.image}
                                                    alt={news.imageAlt}
                                                    loading="lazy"
                                                    className="w-full h-full object-cover"
                                                />
                                            </div>

                                            <div className="content-card">
                                                <p>
                                                    {truncateString(
                                                        news.title,
                                                        50
                                                    )}
                                                </p>
                                                <p>
                                                    {truncateString(
                                                        news.brief,
                                                        250
                                                    )}
                                                </p>

                                                <div className="label-date">
                                                    <img
                                                        src={ICalendar}
                                                        alt="icone calendario"
                                                    />
                                                    &nbsp;
                                                    <span id="date">
                                                        {new Date(
                                                            news._updatedAt
                                                        ).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
